import React from "react";
import {Route,Routes,useLocation} from "react-router-dom";
import LayoutFront from "../hoc/LayoutFront/LayoutFront";
import News from "../components/front/Blog/News";
import ParserPath from "./parsepath";
import classes from "../hoc/LayoutFront/LayoutFront.module.css";

const RoutesHome=()=>{
    let path=useLocation();

    return(
        <div className={classes.underbody}>
            <Routes>
                <Route path="/" element={<LayoutFront/>}>
                    <Route index element={<News path={path} param={{type: 'news', arrPut: ['news'], art: 0}}/>}/>
                    {/*<Route path="*" element={<Blog path={path} />} />*/}
                    {/*<Route path="/search" element={<ParserPath path={path}/>} param={{type:'search',art:0}}/>}/>*/}

                    <Route path="*" element={<ParserPath path={path}/>}/>
                </Route>
            </Routes>
        </div>
    );
};
export default RoutesHome;

