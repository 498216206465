import { createSlice } from "@reduxjs/toolkit";
const initialState={
    rasdelQuery:{type:'',arrPut:[]},
    asideup:[],
    asidedown:[],
    repeatQuery:1,
    repeatQueryLink:0,
    pageArticle:0,
    visualArt:0,
    articles:[],
    searchpanel:{},
    indexCount:[],//index query and all records
    urlSearch:0,
    error404:0,
    idcurrentarticle:0,
    comment:[],
    meta:{},
    header:'',
    headertype:'',
     scrollArt:'',
    overlay:0,
    overlayImg:{},
    mainlist:1, // 1- list 0-article
    loading:1,
    currentarticle:{},
    headermenu:[],
    count:0, scrollBand:0//no need
};

const frontSlice = createSlice({
    name: 'posts',
    initialState: initialState,
    reducers: {
        addValue(state, action) {
            state.count=state.count+action.payload.adder;
        },
        removeValue(state, action) {
            state.count=state.count-action.payload.diver;
        },
        setMenu(state, action) {
            state.headermenu=action.payload.header;
        },
        setListHeader(state,action){
            //console.log('action.payload',action.payload);
            state.header=action.payload.header;
            state.headertype=action.payload.type;
        },
        setQueryList(state,action){
            state.rasdelQuery=action.payload.query;
        },
        setScrollBand(state,action){ //no need
            state.scrollBand=action.payload.scrollBand;
        },
        setScrollArt(state,action){
            state.scrollArt=action.payload.scrollArt;
        },
        setVisualArt(state,action){
            state.visualArt=action.payload.visualArt;
        },
        setRepeatQuery(state,action){
            state.repeatQuery=action.payload.repeatQuery;
        },
        setRepeatQueryLink(state,action){
            state.repeatQueryLink=action.payload.repeatQueryLink;
        },
        setListArticles(state, action) {
            state.articles=action.payload.articles;
        },
        addListArticles(state, action) {
            state.articles=state.articles.concat(action.payload.articles);
            //uniqArray = a.filter( (item, pos, arr) => !pos || item !== arr[pos - 1] );
        },
        setPageArticle(state, action) {
            state.pageArticle=action.payload.pageArticle;
        },
        setIndexCount(state, action) {
            state.indexCount=action.payload.indexCount;
        },
        changeStatusArticle(state,action){
            const art=state.articles.find(elem=>elem.id===action.payload.id);
            if(art && art.view===0) {art.view=1; art.text=action.payload.text;}
            //state.articles=[...state.articles]console.log('xhanger')
        },
        setCurrentArticle(state,action){
            state.currentarticle=action.payload.article;
            state.idcurrentarticle=action.payload.id;
            },
        loadArticleAll(state,action){
            state.articleCurrent=action.payload.articleCurrent;
        },
        switchLoading(state,action){
            state.loading=action.payload.loading;
        },
        setErrorPage(state,action){
            state.error404=action.payload.error404;
        },
        setOverlay(state,action){
            state.overlay=action.payload.overlay;
        },
        setOverlayImg(state,action){
            state.overlayImg=action.payload.overlayImg;
        },
        setMainList(state,action){
            state.mainlist=action.payload.mainlist;
        },
        setSideList(state,action){
            state[action.payload.side]=action.payload.arr;
        },
        setCommentList(state,action){
            state.comment=action.payload.comment;
        },
        setSearchPanel(state,action){
            state.searchpanel=action.payload.searchpanel;
        },
        setUrlSearch(state,action){
            state.urlSearch=action.payload.urlSearch;
        },
        setMeta(state,action){
            state.meta=action.payload.meta;
        }

    },
});
const { actions, reducer } = frontSlice;
export const {addValue, setMenu,setListArticles,addListArticles, changeStatusArticle,setListHeader, setQueryList, setScrollBand,
    setRepeatQuery, setScrollArt,switchLoading, setVisualArt, loadArticleAll,setCurrentArticle,setPageArticle,
    setRepeatQueryLink,setIndexCount,setErrorPage,setOverlay,setOverlayImg,setMainList,setSideList,setCommentList,
    setSearchPanel,setUrlSearch,setMeta} = actions;
export default reducer;