import React  from "react";
import {NavLink} from 'react-router-dom';
import classes from './RAsdelrow.module.css';
import {getPropertiesFortName, abbrevation} from '../../Sets/utils'
import Sets from '../../Sets/sets';
import {DivSelect,DivDef,DivCheckbox,Checkbox,DivCount,DivProperties,DivOptions,DivIcons,DivColor,DivIconOne} from '../../../UI/Inputable/Inputable';

const adm='/'+Sets.adm;
let Handle,  buttHandle, fullpath;

const rasdelRow=props=>{
    const fields=props.fields;
    const heads=props.heads;
    Handle=props.checkerHandler;  buttHandle=props.button; fullpath=adm+'/'+props.fullpath;
    return  heads.map((obj,index)=>{
        return designElementTD(obj,fields[obj],index,props)
    });
};
const designElementTD=(head,field,key,props)=>{
    const type=getPropertiesFortName(head);
    const note=madeDesign(type,field,head,props);
    const cls=classes[head];
    const result=type.type!=='none'?(<td className={cls} name={head} key={key} >{note}</td>):null;
    return  result;
};

const madeDesign=(type,field,head,props)=>{
    let out='';let cls,clsActive, options;
    const sp=props.statuspicture;
    switch(type.type){
        case 'textlock':
        case 'elementname':
        case 'text': out=(<DivDef field={field}/>); break;
        case 'url': out=(<NavLink to={fullpath}>{field}</NavLink>); break;
        case 'textcolor': out=(<DivColor field={field}/>); break;
        case 'urlarticle':
             cls=type.type; clsActive='';
            out=<DivCount field={field} cls={cls} clsActive={clsActive} handler={(e)=>checkHandler(e,props,'redactarticle')}/>;break;
        case 'checkboxmain':
            out=(<Checkbox field={field} handler={(e)=>checkHandler(e,props,'selectrow')}/>);break;
        case 'checkbox':
            out=(<DivCheckbox field={field}/>);
            break;
        case 'select':
            out=(<DivSelect field={field} value={type.list[field]}/>); break;
        case 'button':
            out=buttHandle;break;
        case 'countelem':
             cls=type.type;  clsActive='';
            if(sp.state && parseInt(sp.selectpicture.id)===props.fields.id && sp.selectpicture.table===props.tbl
                && sp.selectype==='countelem') clsActive='active';
            out=<DivCount field={field} cls={cls} clsActive={clsActive} handler={(e)=>checkHandler(e,props,'countelem')}/>;break;
        case 'commelem':
            cls=type.type;  clsActive='';
            if(sp.state && parseInt(sp.selectpicture.id)===props.fields.id && sp.selectpicture.table===props.tbl
                && sp.selectype==='commelem') clsActive='active';
            const commField=field.length>1 && field[1]>0? field[1]+' / '+field[0]:field[0];
            out=<DivCount field={commField} cls={cls} clsActive={clsActive} handler={(e)=>checkHandler(e,props,'commelem')}/>;break;
        case 'password':
        case 'options':
            cls=type.type;  clsActive='';
            options=abbrevation(props.tbl,props.fields);
            if(sp.state && parseInt(sp.selectpicture.id)===props.fields.id && sp.selectpicture.table===props.tbl
                && (sp.selectype==='options' || sp.selectype==='password')) clsActive='active';
            out=<DivOptions field={options} cls={cls} clsActive={clsActive} handler={(e)=>checkHandler(e,props,type.type)}/>;break;
        case 'urlelem':
            cls=type.type; clsActive='';
            if(sp.state && parseInt(sp.selectpicture.id)===props.fields.id && sp.selectpicture.table===props.tbl) clsActive='active';
            out=(<DivProperties field={field} cls={cls} clsActive={clsActive} handler={(e)=>checkHandler(e,props,'propelem')}/>);break;
        case 'iconelem':
            cls=type.type; clsActive='';
            if(sp.state && parseInt(sp.selectpicture.id)===props.fields.id && sp.selectpicture.table===props.tbl) clsActive='active';
            out=(<DivIcons field={field} cls={cls} clsActive={clsActive} handler={(e)=>checkHandler(e,props,'iconelem')}/>);break;
        case 'iconvisual':
            cls=type.type; clsActive='';
            if(sp.state && parseInt(sp.selectpicture.id)===props.fields.id && sp.selectpicture.table===props.tbl &&
                sp.selectpicture.namefield==='icon') clsActive='active';
            out=(<DivIconOne field={field} cls={cls} clsActive={clsActive} handler={(e)=>checkHandler(e,props,'iconvisual')}/>);break;

        case 'none':out=null;break;
        default:out=(<DivDef field={`${field}-ERR`}/>); break;
    }
    return out;
};
const  checkHandler=(e,props,status)=>{
    const { target } = e;
    const id=target.closest('tr').getAttribute('id');
    let name='';
    if(target.closest('td')){name=target.closest('td').getAttribute('name')};
    //console.log({table:this.props.tbl,id:id,name:name});
    Handle({table:props.tbl,id:id,name:name,status});
};




export default rasdelRow;


