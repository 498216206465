import React from "react";
import classes from "./SideElemForm.module.css";
import Button from "../../UI/Buttonpict/Buttonpict";
import {makeTime} from '../../adm/Sets/utils'
import Buttonpict from "../Buttonpict/Buttonpict";
import IconSelect from '../Iconselect/Iconselect';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import Sets from "../../adm/Sets/sets";


const SideElemForm=props=>{
    const oldColor=props.body && props.body.color && props.body.color!=='none'?props.body.color:"#121212";
    const [curColor, setColor] = useColor("hex", oldColor);

     //console.log('color',curColor)
    const resultColor=(res)=>{
        setColor(res);
        props.action(res.hex,'colorchange','color');
    };
    const fieldsForm=getFieldsElement(props.type);
    if(!fieldsForm) { return (<div className={classes.formelement}>table's fields not found</div>);}
     console.log('fieldsForm',fieldsForm,props)
    const fieldkeys=Object.keys(fieldsForm);
    const bodyKeys=Object.keys(props.body);
    let bodyKeysParam=Object.keys(props.body.parametres);

    if(props.type==='password') {bodyKeysParam=bodyKeysParam.concat(fieldkeys)}
    const resArray=[];
    const arrTitles={mp3:'Добавить поля mp3',info:'Получить тех данные',save:'Обновить пароль', email:'Отправить ссылку на восстановление'};
   // console.log(bodyKeys,fieldkeys)
    fieldkeys.forEach(elem=>{
        if(bodyKeys.includes(elem) ){
            if(elem==='color'){
                resArray.push(selectField(elem, fieldsForm[elem],curColor, resultColor));}
            else{resArray.push(selectField(elem,fieldsForm[elem], props.body[elem], props.action));}
        }
        if(bodyKeysParam.includes(elem)){
            resArray.push(selectField(elem,fieldsForm[elem], props.body.parametres[elem], props.action));
        }
        if(!bodyKeys.includes(elem) && !bodyKeysParam.includes(elem)){
            resArray.push(selectField(elem,fieldsForm[elem], 0,props.action));
        }
    });
    const buttons=props.buttons.map(elem=>{

        return (<button className={classes.buttonaddfield} onClick={()=>addFieldsParam(elem,props.action)}
                        title={arrTitles[elem]} key={elem+'button'}>{elem}</button>)
    });
   // const clsbutton=classes.buttonel;
    //console.log('IconSelect',props.type)
    /*const buttonSaver=props.type!=='password'?<Button onClick={(event)=>{props.action(event,'saveInfo',null)}} size={clsbutton} cls={'save'}
                              key={'saveInfo'} title={'Save Record'}/>
                              :null;*/
    const buttonSaver=selectSaveAction(props,classes.buttonel);
    const result=!fieldsForm?'Not assign fields for '+props.type:resArray;
    return( <div className={classes.formelement}>
        <div className={classes.buttonfield}><div>{buttonSaver}</div><div>{buttons}</div></div>
        {result}
    </div>);

};
export default SideElemForm;



const selectField=(name,namefield, value, action)=>{
    const val=value || '';
    const timer=name==='time'?makeTime(value):null;
    const sizer=name==='size'?makeSize(value):null;
    const clsbutton=classes.buttonel;
    switch(name){
        case 'elementname':
        case 'namearticle':
        case 'note':
            return (<div key={namefield}><div className={classes.labelform}>{namefield}</div>
                <input className={[classes.textform,classes[name]].join(' ')} type='text' value={val} onChange={(event)=>{action(event,'change',name)}}/></div>);
        case 'warning':
            const addImage= (<div className={classes.fonbutton}><form onSubmit={(e)=>e.preventDefault()}>
                <label className={classes.labelfile} title={'Выберите иконку'}>
                    <input className={classes.fileinput} type="file" onChange={(e)=>action(e,'getfileicon',name)} />
                </label>
            </form></div>);
            return (<div key={namefield} className={classes.warning}><div >Не выбирайте для иконки файл большого разрешения.
                                  Либо 128х128, либо SVG:</div>{addImage}</div>);
            case 'anons':
            return (<div key={namefield}><div className={classes.labelform}>{namefield}</div>
                <textarea  className={[classes.textform,classes[name]].join(' ')}
                           value={val} onChange={(event)=>{action(event,'change',name)}}/>
            </div>);
        case 'color':  return (<div key={namefield+'--'} className={classes[name]}>
            <ColorPicker width={350} height={60} color={value} onChange={action} hideHSV hideRGB dark /></div>);
        case 'iconurl':
            return (<div key={namefield}><div className={classes.labelform}>{namefield}</div>
                <div className={classes.flexbox}><div className={classes.textform}>{val}</div>
                    <div><Buttonpict cls={'close'} onClick={(e)=>action(e,'deleteicon',val)} title={'delete icon'} /></div>
                </div>
            </div>);
        case 'icon':  const icon=Array.isArray(value) && value.length>0?
            value.map(elem=><div className={classes.iconelement} key={elem.url}><img src={Sets.remsite+'/'+elem.url} alt={elem.id}/>
                <div onClick={()=>action(null,'delicontag',elem.id)}>Delete</div></div>)
            :'no icons';
        return (<div key={namefield} className={classes.icongroup}>{icon}</div>);
        case 'listicon': return (<IconSelect key={namefield} action={action}/>);
        case 'elementurl':
            return (<div key={namefield}><div className={classes.labelform}>{namefield}</div>
                <textarea  className={[classes.textform,classes[name]].join(' ')}
                            value={val} onChange={(event)=>{action(event,'change',name)}}/>
               <div className={classes.buttonelement}>
                   <Button onClick={(event)=>{action(event,'getUrl',name)}} size={clsbutton} cls={'addlink'} key={name+'getUrlbutt'} title={'Insert Link'}/>
                <Button onClick={(event)=>{action(event,'getFile',name)}} size={clsbutton} cls={'import'} key={name+'getFilebutt'} title={'Link file'}/>
               </div>
            </div>);
        case 'song':
        case 'artist':
            return (<div key={namefield}><div className={classes.labelform}>{namefield}</div>
                <input  className={classes.textform} type='text' value={val} onChange={(event)=>{action(event,'changeparam',name)}}/></div>);
        case 'time':
            return (<div key={namefield}><div className={classes.labelform}>{namefield}</div>
                <input  className={classes.textforminfo} type='text' value={val} onChange={(event)=>{action(event,'changeparam',name)}}/>
                <span className={classes.infoformat}>{timer}</span></div>);
        case 'size':
            return (<div className={classes.labelform} key={namefield}><div>{namefield}</div>
                <input className={classes.textforminfo} type='text' value={val} onChange={(event)=>{action(event,'changeparam',name)}}/>
                <span className={classes.infoformat}>{sizer}</span></div>);
        case 'rightpanel':
        case 'leftpanel': return (<div className={classes.labelselect} key={namefield}><div className={classes.label}>{namefield}</div>
            <select className={classes.select} value={val} onChange={(event)=>{action(event,'change',name)}}>
                {createList(name,val)}
            </select></div>);
        case 'newpassword':
        case 'repeatpassword':
            return (<div key={namefield}><div className={classes.labelform}>{namefield}</div>
                <input  className={classes.textforminfo} type='password' value={val} onChange={(event)=>{action(event,'changeparam',name)}}/>
                </div>);
        default: return false;
    }
};

const addFieldsParam=(type,action)=>{
     switch(type){
         case 'mp3':  action(this,'addfields',getSetsForElement(type)); break;
         case 'options':  action(this,'addfields',getSetsForElement(type)); break;
         case 'info':action(this,'getInfoFile','fileurl'); break;
         case 'save':action(this,'password','saveNewPass'); break;
         case 'email':action(this,'password','sendLinkForPass'); break;
         case 'load icon':action(this,'iconelem','loadicon'); break;
       //  case 'save color':action(this,'iconelem','loadicon'); break;
         default: break
     }
};

function getSetsForElement(elem){
    switch (elem) {
        case 'mp3': return ['artist', 'song','time','size'];
        case 'common': return ['size'];
       // case 'options': return ['rightpanel','leftpanel','anons'];
        default: return [];
    }
}
 function getFieldsElement(type) {
    const fields= {
        propelem: {elementname: 'Название',  artist:'Исполнитель', song:'Name song',time:'Время, сек',
            note:'Примечание', size:'Размер в байт', elementurl: 'Ссылка'},
        options: {namearticle: 'Название',leftpanel: 'Left-Block', rightpanel: 'Right-Block',anons:'Анонс'},
        password: {newpassword:'Новый пароль', repeatpassword:'Повтор пароля'},
        iconelem: {color:'color',warning:"",iconurl: 'Ссылка'},
        iconvisual:{name:'Название',icon:'icon',listicon:'listicon'}
    };
    return fields[type] ;
}

function createList(type,value){
    const fields={ rightpanel:['no','yes'], leftpanel:['no','yes'] };
    const current=fields[type];
    return current.map((elem,i)=>(<option value={i} key={i+'type'} >{elem}</option>))
}
function selectSaveAction(props,clsbutton){
    let action='';
    //console.log('selectSaveAction', props.type)
    switch(props.type){
        case 'password':
        case 'iconvisual': return null;
        case 'iconelem': action='changeColorIcon'; break;
        default: action='saveInfo'; break;
    }
    return <Button onClick={(event)=>{props.action(event,action,null)}} size={clsbutton} cls={'save'}
                   key={action} title={'Save Record'}/>;
}

function makeSize(value){
    const sec=parseInt(value) || 0;
    return sec?Math.round(sec/10000)/100 +'Mb':null;
}