import React from "react";
import classes from './MyFrame.module.css';

const MyIframe=(props)=>{
    const str = '<iframe id="'+props.id+'" sandbox="allow-same-origin allow-scripts" src="'+props.src+'" class="'+classes.frame+'"></iframe>';
    const iframe=()=>{return{ __html: str}};

    return	 <div className={classes.container} dangerouslySetInnerHTML={ iframe() }  />


};
export default MyIframe;