import React from "react";
import classes from './ElementWButton.module.css';
const ElementWButton= props =>{
    const cls=[
        classes.element,
        classes[props.cls]
    ];
    return(
        <div
            className={cls.join(' ')}
        ><span> {props.children}</span><button title={props.title} onClick={props.onClick} className={classes[props.cls]}>{null}</button>

        </div>
    )
};

export default ElementWButton;