import { convertFromHTML } from 'draft-convert';

const LIST_STYLES=['UNDERLINE', 'STRIKETHROUGH', 'CODE', 'REDFONT', 'GREENFONT','BLUEFONT','BLUEBACK','YELLOWBACK','REDBACK'];

export const htmlToStyle= (nodeName, node, currentStyle) => {
    switch(nodeName)    {
        case 'strong':
            currentStyle = currentStyle.add('BOLD').toOrderedSet();
            break;
        case 'b':
            currentStyle.add('BOLD'); break;
        case 'em':
            currentStyle.add('ITALIC'); break;
        case 'span':
            LIST_STYLES.forEach(elem=>{
                node.classList.forEach( style=>{ if(style.indexOf(elem.toLowerCase()) + 1) {
                    currentStyle = currentStyle.add(elem).toOrderedSet();
                   // console.log('elem currentStyle ==> ', elem,currentStyle);
                     }
                })

            }); break;
        default: break;
    }
    //console.log('currentStyle ==> ', currentStyle);
      return currentStyle;
};

export const htmlToEntity= (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
        let innerLink=0;
        node.classList.forEach( style=>{ if(style.indexOf('linkinn') + 1) {innerLink=1;} });
        if(innerLink){
            return createEntity('LINKINN','MUTABLE', {url: node.href})
        }else {
            return createEntity('LINK','MUTABLE', {url: node.href})
        }
    }
    if (nodeName === 'hr') {
        return createEntity('HORIZONTAL_RULE','IMMUTABLE',{})
    }
};

export const htmlToBlock= (nodeName, node) => {
    const obj={};
    if (nodeName === "hr") {
        // "atomic" blocks is how Draft.js structures block-level entities.
        return "atomic";
    }
    let otstupAble=0;
    node.classList.forEach(style=>{ if(style.indexOf('postotstup') + 1) {otstupAble=1;}});
    if (nodeName === 'h1' ) { obj.type= 'header-one'; }
    if (nodeName === 'h2' ) { obj.type= 'header-two'; }
    if (nodeName === 'h3' ) { obj.type= 'header-three'; }
    if (nodeName === 'blockquote' ) { obj.type= 'blockquote'; }
    if (nodeName === 'pre' ) { obj.type= 'code-block'; }
    if (nodeName === 'div' && otstupAble) { obj.type= 'OtstupBlock'; }

    if (node.style.textAlign ) {  obj.data= {textAlignment:node.style.textAlign.toUpperCase()};
    }
    return obj;
};

export const options = { // <-- [1]
    htmlToStyle,
    htmlToEntity,
    htmlToBlock,
};


let converterFunc;
converterFunc = convertFromHTML(options); // <-- [2]

export default converterFunc;

