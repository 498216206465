import React, {Component} from "react";
import classes from './AddImage.module.css';
import Button from "../Buttonpict/Buttonpict";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from "react-image-crop";

class AddImage extends Component{
    state={file:null, src:null, filename:[],status:'new', note:'',sizePreview:[0,0],sizeImage:[1000,700], align:'left',
        crop: { unit: '%', width: 100, height:100 }, croppedImageUrl:null, blob:null};

    clearPictureState=()=>{this.setState({file:null, src:null, filename:null, croppedImageUrl:null, blob:null,})};
    stateAlign=(align)=>{ this.setState({align:align.replace('align-','')})};
    selectAction=async (status,e)=>{
        switch(status) {
            case 'save': const res=await this._handleSubmit(); this.props.action(status,res);
                this.clearPictureState();
            break;
            case 'savenote': const target=e.target.value; this.props.action(status,target); break;
            case 'closenote':  this.props.action('closenote'); break;
            case 'cancel':  this.clearPictureState(); break;
            case 'align-right':
            case 'align-center':
            case 'align-left':  this.stateAlign(status); break;
            default:break;
        }
    };

    generateButton=()=>{ // button for action
        const buttonVisual=this.props.sidearticle.editblock && this.props.sidearticle.editblock.type==='image'?
            this.props.buttons.map((elem,index)=>{
                return <Button onClick={()=>{this.selectAction(elem)}} cls={elem} key={index+elem} title={elem}/>})
            :this.state.src?
                this.props.buttons ?
                    this.props.buttons.map((elem,index)=>{
                        return <Button onClick={()=>{this.selectAction(elem)}} cls={elem} key={index+elem} title={elem}/>})
                    :null
                :null;

        const addImage= (<div className={classes.fonbutton}><form onSubmit={(e)=>e.preventDefault()}>
            <label className={classes.label} title={'Выберите изображение'}>
                <input className={classes.fileinput} type="file" onChange={(e)=>this._handleImageChange(e)} />
            </label>
            {buttonVisual   }
        </form></div>);
       // console.log('cropsed',this.state)
       return  addImage;
    };

    _handleSubmit=async ()=> {
        if(!this.state.blob) {console.log('file dont select');return;}
        const dat=Date.now();
        const data = new FormData();
        data.append('filename', this.state.filename[0]);
        data.append('folder',this.props.folder);
        data.append('nomer',this.props.sidearticle.id); // nomer rasdel
        data.append('note', this.state.note);
        data.append('status',this.state.status);
        data.append('align',this.state.align);
        data.append('file', this.state.blob, dat + '-' + this.state.filename[0]);

        if(Array.isArray(this.state.sizeImage) && this.state.sizeImage.length>1) {
            for(let i=1;i<this.state.sizeImage.length;i++){
                if(parseInt(this.state.sizeImage[i])>0) {
                    const { blob} = await this.getCroppedImg(this.imageRef,
                        this.state.crop, 'newFile.jpeg', parseInt(this.state.sizeImage[i]));
                    data.append('file', blob, dat + '-small-'+i+'-' + this.state.filename[0]);
                }
            }

            console.log ('aadImage 2 picture')
        }
        //
        //const res=await this.props.handler({status: 'savepicture', data});
       /* const newregim=this.state.status==='new'?this.state.regim:0;
        if (res) {this.setState({
            file:null, src:null, filename:null, croppedImageUrl:null, blob:null,regim:newregim,
        });}
        // TODO: do something with -> this.state.file*/
        return data;
    };

    async _handleImageChange(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            const arrImg=['image/jpeg','image/png'];
            if (!arrImg.includes(e.target.files[0].type)) {alert('Неподдерживаемый тип'); return;}
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = async () => {
                const cyrillicToTranslit = new CyrillicToTranslit();
                const newFileName=cyrillicToTranslit.transform(file.name,'_').toLowerCase();
                this.setState({
                    file: file, src: reader.result,status:'new',filename:[newFileName.replace(/\s/g,'_')],
                    crop:{ unit: '%', width: 100, height:100 }
                });
            };
            reader.readAsDataURL(file)
        }
        this.selectAction('closenote');
    };
    onImageLoaded =  image => { this.imageRef = image;     };
    onCropComplete = crop => {  this.makeClientCrop(crop); };
    onCropChange = (crop, percentCrop) => {this.setState({ crop });}; //// You could also use percentCrop: this.setState({ crop: percentCrop });
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const {croppedImageUrl,blob} = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg', parseInt(this.state.sizeImage[0]) );
            this.setState({ croppedImageUrl,blob });
        }
    }
    getCroppedImg(image, crop, fileName, sizeImageMax) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width*scaleX;
        canvas.height = crop.height*scaleY;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width*scaleX,
            crop.height* scaleY
        );
        // проверить размер канваса и от него искать размер нового изображения
        const arrNewSize=(this.state.sizePreview[0] && this.state.sizePreview[1])? this.state.sizePreview
            :sizeImageMax>=(crop.width*scaleX)?[crop.width*scaleX,crop.height*scaleY]
                :[sizeImageMax,(sizeImageMax/(crop.width*scaleX))*(crop.height*scaleY)];
        const oc = document.createElement('canvas');
        oc.width = arrNewSize[0];
        oc.height = arrNewSize[1];
        const octx = oc.getContext('2d');
        octx.drawImage(canvas, 0,0,  arrNewSize[0], arrNewSize[1]);

        return new Promise((resolve, reject) => {
            // canvas.toBlob(blob => {
            oc.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve({croppedImageUrl:this.fileUrl,blob:blob});
            }, 'image/jpeg');
        });
    }

    changeInput=(e,field)=>{
        const controls={...this.state};
        controls[field]=e.target.value;
        this.setState((prevState, props)=>{return {...controls}});
    };
    parametrSets= (parametr)=>{
        const {crop}=this.state; let sizePreview=[0,0];
        if(parametr[2] && parametr[1]){crop.aspect= parseInt(parametr[2]||200) /parseInt(parametr[1]||200);
            sizePreview=[parametr[2],parametr[1]];}
        const newrasmer=parametr[0].map((elem,i)=>{if(i===0) {return elem||1000}else{ return elem||0} });
        return {sizeImage:newrasmer, sizePreview,crop:crop,changeMainPictur:parseInt(parametr[3])}
    };

    componentDidMount() {
        const parametr=this.props.settings;
        if(parametr){ this.setState({ ...this.parametrSets(parametr)})}
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sidearticle !== prevProps.sidearticle) {
            if(this.props.sidearticle.editblock && this.props.sidearticle.editblock.type==='image') {
                this.clearPictureState()
            }
        }
        if (this.props.settings.toString() !== prevProps.settings.toString()) {
            if(this.props.settings){ this.setState({ ...this.parametrSets(this.props.settings)})}
        }
    }

    render(){
        const style=this.props.cls==='hidden'?{display:'none'}:{};
        let {src,crop, note, align } = this.state;
        const buttons=this.generateButton();
        const noteEdit=this.props.noteEdit;
        const alignStyle=align ? {justifyContent: align}:'';
        return (
        <div className={classes.blockaddpicture} style={style}>
            {buttons}
            <div className={classes.imagealign} style={alignStyle}>
                {src
                    ?<div className={classes.imgpreview}>
                    <ReactCrop src={src} crop={crop} ruleOfThirds onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}   onChange={this.onCropChange} />
                    <input className={classes.note} onChange={e=>this.changeInput(e,'note')}
                    value={note || ''} placeholder={'Введи подпись под картинкой'}/>
                    </div >
                    : noteEdit?
                        <textarea className={classes.noteinput} type={'text'}
                               onChange={(event)=>this.selectAction('savenote',event)} value={noteEdit}/>
                        :null
                }
            </div>
            </div>
        );
    }

}

export default AddImage;