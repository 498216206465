import React from "react";
import classes from '../Drafteditor.module.css';
import Button from "../../Buttonpict/Buttonpict";
import {Checkbox} from '../../Inputable/Inputable';
import { EditorState,  Modifier,SelectionState,AtomicBlockUtils  } from 'draft-js';
//import classcom from '../../Styles/Stylecommon.module.css';

export const LinkCreator=props=>{
   // console.log('props.valueTip',props.valueTip);
    //const closer=(status)=>{ props.onChange('','linkvalue'); props.closeTip(status)    };

        return (
            <div>
            <div className={classes.linkblock}>
                <input name='linkvalue' type='text' value={props.valueTip.linkvalue } className={classes.linkvalue}
                                                 onChange={(e)=>{props.onChange(e.target.value,'linkvalue');}}/>
                            <Button onClick={()=>props.closeTip('save')} cls='save' />
                            <Button onClick={()=>props.closeTip('clear')} cls='close' />
        </div>
                <div className={classes.linkblock}>
                <Checkbox field={props.valueTip.linkouter} handler={()=>props.onChange(!props.valueTip.linkouter,'linkouter')}/>
                <span>новое окно</span>

                 </div>
            </div>
    )
};

export const  getInlineStyle =(editorState)=> {

    const selectionState = editorState.getSelection();
    const offSet=selectionState.getStartOffset()?selectionState.getStartOffset()-1:0;
    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
//const endKey = selectionState.getEndKey();

    const blockMap =contentState.getBlockForKey(startKey);
    const inline=blockMap.getInlineStyleAt(offSet);

    const arrStyle=inline.toList(); // toList & get() позволяют достичь вложенных объектов
    const arrReturn=[];
     arrStyle.forEach( (elem)=>arrReturn.push(elem));
     return arrReturn;
        //console.log('charStyle',elem);
};
export const  colorStyleMap ={
    REDFONT: {
        color: "rgba(255, 0, 0, 1.0)"
    },
    GREENFONT: {
        color: "rgba(21, 125, 21, 1.0)"
    },
    BLUEFONT: {
        color: "rgba(0, 0, 200, 1.0)"
    },
    BLUEBACK: {
        color: "rgba(255, 255, 255, 1.0)",
        backgroundColor: "rgba(0, 0, 255, 1.0)",
    },
    YELLOWBACK: {
        color: "rgba(0, 0, 0, 1.0)",
        backgroundColor: "rgba(255, 239, 0, 1.0)",
    },
    REDBACK: {
        color: "rgba(255, 255, 255, 1.0)",
        backgroundColor: "rgba(255, 0, 0, 1.0)",
    },
};
const defStyle={width:'20px', height:'20px'};
const defStyleMin={width:'12px', height:'12px'};
export const styleColorButton=[
    {name:'clear',style:{backgroundColor:'black',...defStyle}, title:'',action:'clear'},
    {name:'REDFONT',style:{backgroundColor:'red',...defStyle}, title:'',action:'REDFONT'},
    {name:'GREENFONT',style:{backgroundColor:'green',...defStyle}, title:'',action:'GREENFONT'},
    {name:'BLUEFONT',style:{backgroundColor:'blue',...defStyle}, title:'',action:'BLUEFONT'},
    {name:'BLUEBACK',style:{border:'3px solid blue',...defStyleMin}, title:'',action:'BLUEBACK'},
    {name:'YELLOWBACK',style:{border:'3px solid rgba(255,239,0,1.0)',...defStyleMin}, title:'',action:'YELLOWBACK'},
    {name:'REDBACK',style:{border:'3px solid rgba(255,0,0,1.0)',...defStyleMin}, title:'',action:'REDBACK'},
];

class OtstupBlocker extends React.Component {
    render() {
        return (  <div className={this.props.cls}>{this.props.children}</div>
        );
    }
}
export {OtstupBlocker};

export const DividerBlock= () => {     return (<hr/>) };

export const addHorizontalRule = (editorState) => { /// remove content

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
        "HORIZONTAL_RULE",
        'IMMUTABLE',
        {},
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
};

export const  getBlockAll =(editorState)=> {// получение массива всего по блокам с координатами
    const blockMap = editorState.getCurrentContent().getBlocksAsArray(); // получение массива блоков всего контента
    const arrBlocks= blockMap.map((contentBlock,i) => ({key:contentBlock.getKey(), length:contentBlock.getLength()})); //получение имени/ключа блока и его длины
    return arrBlocks.map((obj,i)=>{
        return {name:obj.key, start:0,end:obj.length};
    });
};

export const  getBlockSelection =(editorState)=> { // получение массива выделения по блокам с координатами
    const selection = editorState.getSelection(); // выбор выделения в стейте
    const beginSel={name:selection.getStartKey(),sdvig:selection.getStartOffset()}; // получение имени/ключа начального блока и отступ от начала (с нуля)
    const endSel={name:selection.getEndKey(),sdvig:selection.getEndOffset()}; // получение имени/ключа последнего блока и отступ от начала (последний — не выбранный)
    console.log('beginSel endSel',beginSel, endSel);
    console.log('selection',selection.isCollapsed()); //если не выделено то true

    const blockMap = editorState.getCurrentContent().getBlocksAsArray(); // получение массива блоков всего контента
    const arrBlocks= blockMap.map((contentBlock,i) => ({key:contentBlock.getKey(), length:contentBlock.getLength()})); //получение имени/ключа блока и его длины
    console.log('blockMap',arrBlocks);
    const quick={start:0,end:0};
    const result=arrBlocks.map((obj,i)=>{ const res={name:''};
        if(!quick.start){		quick.start=obj.key===beginSel.name?1:0;	}
        res.start=0; // нужен для выделения в одном только блоке
        if(obj.key===beginSel.name){res.name=beginSel.name;res.start=beginSel.sdvig; res.end=obj.length;}
        if(obj.key===endSel.name){res.name=endSel.name;res.end=endSel.sdvig;quick.start=0;}
        if(res.name) return res;
        if(quick.start){res.name=obj.key;res.start=0;res.end=obj.length;return res;}
        return undefined;
    });
    const itog=result.filter((elem)=>elem!==undefined)
    console.log('result',itog);
    return itog;

};
function findWithRegex (regex, contentBlock, offSetOpt, callback) {
    const text = contentBlock.getText();
    let matchArr, start, end;
    //console.log('regex',regex);
    while ((matchArr = regex.exec(text)) !== null) {
        //console.log('matchArr',matchArr,matchArr[0].length);
        start = matchArr.index+offSetOpt.start; //индекс вхождения
        end = matchArr.index + matchArr[0].length-offSetOpt.end; // +длина искомой строки
        callback(start, end);
    }
}
export const Replace= (editorState,search,replace, offSet)=> {
    const offSetOpt=offSet===undefined?{start:0,end:0}:offSet;
    const regex = new RegExp(search, 'g');
    const selectionsToReplace = [];
    const sel= editorState.getSelection().isCollapsed();

    const selectBlocks=sel?getBlockAll(editorState):getBlockSelection(editorState);
    selectBlocks.forEach((block)=>{
        const contentBlock = editorState.getCurrentContent().getBlockForKey(block.name); // получение блока по ключу
        const positBlock={start:block.start, end:block.end};
        findWithRegex(regex, contentBlock, offSetOpt, (start, end) => {
            const blockKey = contentBlock.getKey();
            const blockSelection = SelectionState
                .createEmpty(blockKey)
                .merge({
                    anchorOffset: start,
                    focusOffset: end,
                });
            if(start>=positBlock.start && end<=positBlock.end) {selectionsToReplace.push(blockSelection)}
        }) // findWithRegex
    });

    let contentState = editorState.getCurrentContent();

    selectionsToReplace.forEach((selectionState,i) => {
        contentState = Modifier.replaceText(
            contentState,
            selectionState,
            replace
        )
    });

    return EditorState.push(
        editorState,
        contentState, 'replacer'
    );


}