import axios from   'axios';
import {
    getDeleteFields,
    createQuestion,
    orderSortTable,
    pictureInElementArray,
    changeDatestampEnd,
    changeStyles,
    changePlaceInArray,
    checkIdRedactArticle,
    selectFieldsForFiles,
    selectTypeElement,
    newColorSvg,
    converEmoji
} from '../../components/adm/Sets/utils';
import Sets from '../../components/adm/Sets/sets';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import {
    ADM_RASDEL_START,
    ADMIN_CHANGE_PAGE,
    ADM_RASDEL_SUCCESS,
    ADM_RASDEL_ERROR,
    GET_ADM_RASDEL_NEWREC,
    GET_ADM_RASDEL_NEWREC_SUCCESS,
    ADM_USER_ERROR,
    UPDATE_ADM_RASDEL_SUCCESS,
    UPDATE_ADM_RASDEL_REC,
    CHANGE_ADM_RASDEL_REC,
    CHANGE_ADM_RASDEL_SUCCESS,
    ADMIN_UPDATE_SAME_RASDEL,
    SEND_ERROR_TO_BLOCK,
    SEND_ERROR_TO_LOCATION,
    ADMIN_CHANGE_ORDER_SORT,
    ADMIN_BLOCKER_ACTIVE,
    ADMIN_BLOCKER_DEACTIVE,
    ADMIN_SWITCH_SIDEBAR, ADMIN_SWITCH_SIDEBAR_HEAD, ADMIN_SWITCH_SIDEBAR_BODY,
    ADMIN_SWITCH_SIDEBAR_TYPE, ADMIN_SWITCH_SIDEBAR_SETS, ADMIN_CSS_SETS,
    ADMIN_ARTICLE_SWITCH, ADMIN_ARTICLE_SET, ADMIN_TAGS_LIST,
    ADMIN_FILE_STATE, ADMIN_DIR_STATE, ADM_STATUS_BLOCK,ADM_STATUS_META,
    ADM_COMMENTS, ADM_COMMENTS_USERS, ADM_COMMENTS_IP
} from "./actionTypes";

import {CONST_PREF} from '../../components/adm/Sets/sets';
const pref=CONST_PREF;



export function getAdmRasdelState(page,table,sort){ // get fields of rasdel
    return async (dispatch,getState)=> {
        try{
           // console.log('Load',table,page)
          //  dispatch(getAdmRasdelStart());
            let put;
            switch (table) {
                case 'rasdel':put='/api/rasdel/'+page.nom+'/'+sort; break;
                case 'setting':put='/api/sets/'+table+'/none/'+sort; break;
                case 'tag':put='/api/sets/'+table+'/none/'+sort; break;
                case 'icon':put='/api/sets/'+table+'/none/'+sort; break;
                case 'user':put='/api/sets/'+table+'/none/'+sort; break;
                case 'commusers':put='/api/sets/'+table+'/none/'+sort; break;
                case 'article':put='/api/sets/'+table+'/'+page.nom+'/'+sort; break;
                case 'element':put='/api/sets/'+table+'/'+page.nom+'/'+sort; break;
                default:break;
            }
            const res = await axios.get(pref+put);
           // console.log('Load2 table',res.data,table)
            if(res.data.isLoad) {
                dispatch(madeStatusBlock(res.data.stadm));
                if(res.data.error===1){dispatch(sendErrorToBlock(res.data.msg));
                    dispatch(getAdmRasdelSuccess({arr:{headNames:[],data:[],sort:sort || 'ASC'},namerasdel:table}));
                return;}
                else{dispatch(sendErrorToBlock([]));}
                const newRasdel={headNames:res.data.fields,data:res.data.newRecords,sort:sort || 'ASC'};
               // console.log('Load2 table',res.data,table)
                dispatch(getAdmRasdelSuccess({arr:newRasdel,namerasdel:table})); //-----

            } else{
                dispatch(makeStatusDisconnect())
            }
        }catch(e){dispatch(getRasdelError(e));}
    }
}

export function getAdmRasdelStart(){
    return{  type: ADM_RASDEL_START  }
}
export function getAdmRasdelSuccess(rasdel){
    return{  type: ADM_RASDEL_SUCCESS, rasdel  }
}
export  function makeStatusDisconnect(){
    return{
        type:ADM_USER_ERROR,  header:{page : 'Введите логин и пароль. Сессия завершена',user:''}
    }
}
export function getRasdelError(e){
    return{  type: ADM_RASDEL_ERROR,  error:e  }
}
export function madeStatusBlock(obj){
    return{  type: ADM_STATUS_BLOCK,  obj  }
}

export function addNewRecordRasdelTable(tbl, value){
    return  (dispatch,getState)=> {
             dispatch(getAdmRasdelNewRec());
            const rasdel={...getState().adminrasdel[tbl]};
        console.log("rasdel " , rasdel);
                if (rasdel.data.length){
                    const allsort=Math.max(...rasdel.data.map(item=>{return item.sort}));
                    value.sort=allsort+1;
                } else{ value.sort=1; }
             (rasdel.sort!=='DESC')?rasdel.data.push(value): rasdel.data=[value,...rasdel.data];

            dispatch(getAdmRasdelNewRecSuccess({arr:rasdel,namerasdel:tbl})); // insert record in rasdel-data noid

    }

}
export function getAdmRasdelNewRec(){
    return{ type: GET_ADM_RASDEL_NEWREC, }
}
export function getAdmRasdelNewRecSuccess(rasdel){
    return{ type: GET_ADM_RASDEL_NEWREC_SUCCESS,  rasdel   }
}
export function updateAdmRasdelRec(){
    return{  type: UPDATE_ADM_RASDEL_REC,  }
}
export function updateAdmRasdelSuccess(rasdel){
    return{ type: UPDATE_ADM_RASDEL_SUCCESS,  rasdel
    }
}

export function checkerHandlerField(valueObj){ // check select field {table:this.props.tbl,id:id,name:name}
    return  (dispatch,getState)=> {
        try {
            dispatch(updateAdmRasdelRec());
            const {table, id, name} = valueObj;
            const rasdel = {...getState().adminrasdel[table]};
            const data = rasdel.data;
            const cityId = data.find(val => String(val.id) === id);
            cityId[name]=!cityId[name];
            dispatch(updateAdmRasdelSuccess({arr:rasdel,namerasdel:table}));
        }catch(e){dispatch(getRasdelError(e));}
    }
}


export function changeRecordStatus(valueObj){
    return  async (dispatch,getState)=> {
        const {table, id, name,status} = valueObj;
        let errMsg='';
        dispatch(changeAdmRasdelRec(status));
        const rasdel = {...getState().adminrasdel[table]};
        const page = {...getState().adminrasdel.page};
        const data = rasdel.data;
        const recordId = data.find(val => String(val.id) === id);
        console.log('recordId',valueObj)
        dispatch(sendErrorToBlock(''));
        switch(status){
            case 'editrecord':recordId[name]=!recordId[name];break;
               // rasdel.data=data.map(elem=>{if(elem.id===recordId.id) {elem=recordId;} return elem;});break;
            case 'canceledit':
                if(String(recordId.id).indexOf('noid') + 1) { //delete if new record
                    rasdel.data = data.filter(record => record.id !== recordId.id);
                }else{ recordId.edit=false;recordId.delete=false; }
            break;
            case 'markdeleterecord':recordId.delete=true;break;
            case 'deleterecord':
                if(recordId.delete){
                    if(String(recordId.id).indexOf('noid') + 1) { //delete if new
                        rasdel.data = data.filter(record => record.id !== recordId.id);
                    }else{
                        const res = await axios.post(pref+'/api/delete/',{id:recordId.id,table,direct:rasdel.sort});
                        console.log('res delete',res.data.msg);
                        if(res.data.isLoad) {
                            if(res.data.error===1){dispatch(sendErrorToBlock(res.data.msg));return;}
                            rasdel.data=res.data.records;
                            const sidearticlestate=getState().adminrasdel.sidearticlestate;
                            sidearticlestate.state=0;
                            dispatch(admSwitchSideArticle(sidearticlestate));
                        } else{
                            dispatch(sendErrorToBlock(createQuestion(res.data.msg)));
                            return false;
                        }
                    }
                } break;

            case 'saverecord': // add in menup field default dont forget
                dispatch(changeAdmRasdelRec(status));
                const saveObject={...valueObj.name};
                saveObject.id=valueObj.id;
                saveObject['rasdel'] = page.nom;

                getDeleteFields().map((name,index)=>{delete saveObject[name]; return 0 ; });
                saveObject.table=table;

                try {
                    const res = await axios.post(pref+'/api/save/' + table + '/', saveObject);
                    console.log('whatsup2: ',saveObject,res)
                    if (res.data.isLoad) {
                        if(res.data.error===1){dispatch(sendErrorToBlock(res.data.msg));return;}
                        delete saveObject['table'];
                        Object.keys(recordId).forEach((name) => {if (saveObject[name] !== undefined)
                            {recordId[name] = saveObject[name];}   });
                        recordId.id = res.data.record.id ? parseInt(res.data.record.id) : parseInt(recordId.id);
                        recordId.edit = false; recordId.delete = false;
                        console.log('whatsup3: ',recordId)
                        rasdel.data = data.map(elem => {if (elem.id === recordId.id) {elem = recordId;}
                        return elem; });
                    } else {
                        dispatch(sendErrorToBlock(createQuestion(res.data.msg)));
                        return false;
                    }
                }catch(e){ errMsg=e;console.log('whatsup: '+errMsg); return; }
                break;
            default: return;
        }
       if(errMsg) {dispatch(sendErrorToBlock(createQuestion(errMsg)))}else{dispatch(sendErrorToBlock([]))};
        //if(errMsg) {console.log('whatsup2: '+errMsg)};
        dispatch(changeAdmRasdelSuccess({arr:rasdel,namerasdel:table}));


    }
}
export function changeAdmRasdelRec(status){
    console.log(status);
    return{ type: CHANGE_ADM_RASDEL_REC,   }
}
export function changeAdmRasdelSuccess(rasdel){
    return{  type: CHANGE_ADM_RASDEL_SUCCESS,   rasdel     }
}

//array divov
export function sendErrorToBlock(valueObj) {
    return  {  type: SEND_ERROR_TO_BLOCK, recorderror:valueObj    }
}
export function sendErrorToLocation(valueObj) {
    return  {  type: SEND_ERROR_TO_LOCATION,   locationerror:valueObj  }
}
export function admErrorToBlock(value){
    return   (dispatch)=> {
        dispatch(sendErrorToBlock(value));
    }
}


export function findCurrentPath(id,table) {
    return  async (dispatch,getState)=> {
        dispatch(getAdmRasdelStart('findpath'));
     //   console.log('rasdel from server',id);
        dispatch(sendErrorToLocation([]));
        let foundPos = id.toLowerCase().indexOf(`/${Sets.adm}/${table}`);// исчезнет путь полностью или нет, для анализа
        const newId=!foundPos ?  id.slice(`/${Sets.adm}/${table}`.length) : id;
        const res = await axios.post(pref+'/api/findrasdel/',{id:newId.toLowerCase(),table});
        if(res.data.isLoad) {
            if(res.data.error){
                dispatch(sendErrorToLocation(["Ошибка в пути:",res.data.error])); return 0;}
                console.log('rasdel from server',res.data.arrpath);
                const page = {...getState().adminrasdel.page};
                const fullpath=res.data.arrpath.map(item=>{return item.nameurl ? item.nameurl:null});
                page.nom=res.data.arrpath[res.data.arrpath.length - 1].master;
                page.fullpath=fullpath.slice(0,res.data.arrpath.length - 1).join('/');
                const newParts=res.data.arrpath.length>0?res.data.arrpath.slice(0,res.data.arrpath.length - 1):res.data.arrpath;
                const newState={page, partslocation:newParts};
               await dispatch(admChangePage(newState));
                const rasdel = {...getState().adminrasdel['rasdel']};
                const article = {...getState().adminrasdel['article']};
                const element = {...getState().adminrasdel['element']};
                await dispatch(getAdmRasdelState(page,'rasdel',rasdel.sort));
                await dispatch(getAdmRasdelState(page,'article',article.sort));
                await dispatch(getAdmRasdelState(page,'element',element.sort));

                dispatch(admMetaStatus(makeMetaTitle(newParts)));

        }else {

            dispatch(sendErrorToBlock(createQuestion(res.data.msg)));
            return false;
        }
    }
}
export function makeMetaTitle(partslocation){
        let name=Sets.namesite+' - ';
        let middle='';
        switch(partslocation[0].nameurl){
            case 'rasdel': middle=partslocation.length>1?`Раздел: ${partslocation[partslocation.length-1].name}`||'':'корневой'; break;
            case 'tag': middle='Раздел тэгов';break;
            case 'setting': middle='Раздел установок';break;
            case 'user': middle='Раздел пользователей';break;
            default:break;
        }
        return {title:name+middle}
}
export function admMetaStatus(obj) { // meta for helmet
    return  {    type: ADM_STATUS_META,  obj
    }
}

export function admChangePage(valueObj) { // nom + array rasdel
    return  {    type: ADMIN_CHANGE_PAGE,  newpage:valueObj
    }
}


export function admSortTable(type,table,field) {
    let director='ASC';
    if (type==='sortup' || type==='sortdown') { director=type; type='sort';}
    return  async (dispatch,getState)=> {
        //console.log('type:',table,type)
        const sortTable=orderSortTable(table);
        const rasdel = {...getState().adminrasdel[table]};
        const page = {...getState().adminrasdel.page};
        const blocker =getState().adminrasdel.blocker;
        switch(type){
            case 'changeorder':
                if(sortTable){
                    const status=localStorage.getItem(sortTable);
                    const newstatus=status==='ASC'? 'DESC':'ASC';
                    localStorage.setItem(sortTable,newstatus);
                    rasdel.sort=newstatus;
                    //console.log('type',{arr:rasdel,namerasdel:table})
                    dispatch(admChangeOrderSort({arr:rasdel,namerasdel:table})) //'DESC' ASC
                    dispatch(getAdmRasdelState(page,table,newstatus)) //'DESC' ASC
                }
                break;
            case 'initorder':
                if(sortTable){
                    const sortRasdel=orderSortTable('rasdel');
                    const status=localStorage.getItem(sortRasdel); // init same for all (sortTable)
                    localStorage.setItem(sortTable,status);
                    rasdel.sort=status;
                    dispatch(admChangeOrderSort({arr:rasdel,namerasdel:table})) //'DESC' ASC
                }
                break;
            case 'sort':
                const direct=rasdel.sort;
                const selectrecord=rasdel.data.filter(item=>item.empty);
                const outArray=selectrecord.map(item=> {return {id:item.id,sort:item.sort}});
                if (!outArray.length) return false;
                if(blocker) return false;
                dispatch(admBlockerActive());dispatch(sendErrorToBlock(''));
                const res = await axios.post(pref+'/api/sortrasdel/',{arr:outArray,direct,director,table});
                if(res.data.isLoad) {
                    if(res.data.error===1){dispatch(sendErrorToBlock(res.data.msg));return;}
                    const status=localStorage.getItem(sortTable);
                    await dispatch(getAdmRasdelState(page,table,status));//'DESC' ASC
                    await dispatch(admSelectOption(outArray,table,'empty',true));
                    dispatch(admBlockerDeactive());
                }else{dispatch(sendErrorToBlock(res.data.msg))}
                break;
            case 'list':
                const result = await axios.post(pref+'/api/orderrasdel/',{table,field});
                if(result.data.isLoad) {
                    if(result.data.error) {
                        dispatch(sendErrorToBlock(createQuestion("Ошибка при order:" + result.data.msg)));
                    }else{
                        await dispatch(getAdmRasdelState(0,table,rasdel.sort));
                    }
                }else{dispatch(sendErrorToBlock(res.data.msg))}
            break;
            default:  return false;
        }
    }
} //-----

export function admChangeOrderSort(rasdel) { // change order sort
                return  {    type: ADMIN_CHANGE_ORDER_SORT,  rasdel,  }
}

export function admSelectOption(arr,table,field,value){
    return  (dispatch,getState)=> {
        const rasdel = {...getState().adminrasdel[table]};
        rasdel.data=rasdel.data.map(item=>{
            if(arr.filter(newitem=>{
                if(newitem.id===item.id) {item[field]=value; } return false;
            } ))return item;
            return false;
        });
        dispatch(admUpdateSameRasdel({arr:rasdel,namerasdel:table}))
    }
}

export function admUpdateSameRasdel(rasdel){
    return  {    type: ADMIN_UPDATE_SAME_RASDEL,  rasdel  }
}

export function admBlockerActive(){
    return  {    type: ADMIN_BLOCKER_ACTIVE }
}
export function admBlockerDeactive(){
    return  {    type: ADMIN_BLOCKER_DEACTIVE }
}

export function admGetDataChilds(obj){ //table:Tbl,id:id,name:name,status ---- ASIDE
    return  async(dispatch,getState)=> {
        let sidestate=getState().adminrasdel.sidestate;
        const rasdel = {...getState().adminrasdel[obj.table]};
        const sidehead={...getState().adminrasdel.sidehead};
        const sidetype=getState().adminrasdel.sidetype;
        let sidevisible;
        //console.log('sidevisible',sidestate,obj)
        if(obj.table===sidehead.table && obj.id===sidehead.id){sidevisible=!sidestate;} else{sidevisible=1}
        dispatch(admSwitchSideBarType(obj.status));
        if(sidetype!==obj.status) dispatch(admSwitchSideBarBody({head:sidehead,body:{}}));
        /*if (obj.status==='countelem'){dispatch(admSwitchSideBarType(obj.status))}
            else{dispatch(admSwitchSideBarType('article'))}*/

        // for rasdel table obj.status:countelem   obj.name -- имя поля
        const record=rasdel.data.filter(item=>item.id===parseInt(obj.id)?item:false);
        if (record.length){
            if (obj.status==='countelem') {
                const nameCaption=record[0].name || record[0].namearticle;
                const head={table:obj.table,namefield:obj.name,id:obj.id,namerasdel:nameCaption,currentpictur:0};

               // console.log('head222',obj,head)
                if(sidevisible) {
                    const setsForTable=await getCurrentSetsIUploader(obj.table);
                    if (setsForTable.error){ dispatch(sendErrorToBlock(createQuestion(setsForTable.msg)));}
                    else{ delete setsForTable.error; dispatch(admSetsIUploader(setsForTable));}

                    const newSidebody=await getPictureAfterUpdate({id:obj.id,table:obj.table});
                    dispatch(admSwitchSideBarBody({head,body:newSidebody}));

                } else{dispatch(admSwitchSideBarHead(head));}
                dispatch(admSwitchSideBar(sidevisible));
                }
            //commblock
            if (obj.status==='commelem') {
                console.log('rasdel comms');
                dispatch(admSetComment([]));
                const nameCaption=record[0].name || record[0].namearticle;
                const head={table:obj.table,namefield:obj.name,id:obj.id,namerasdel:nameCaption,currentpictur:0};
                if(sidevisible) {
                    try {
                        const comments = await axios.get(pref+'/api/getlistcomment/' + obj.id);
                        console.log('comms',comments);
                        if (comments.data.error) {dispatch(sendErrorToBlock('Ошибка:'+comments.data.error)); return false;}
                        if(comments.data.comms){
                            dispatch(admSetCommentUsers(comments.data.users||[]));
                            dispatch(admSetCommentIP(comments.data.ipnot||[]));
                            dispatch(admSetComment(comments.data.comms||[]));
                        }

                    }catch (e) {console.log(e);   return {error:1,msg:'admGetDataChilds-comelem'} }


                                // load comments
                // if error if (setsForTable.error){ dispatch(sendErrorToBlock(createQuestion(setsForTable.msg)));}
                dispatch(admSwitchSideBarBody({head,body:'newSidebody'}));
            }else{dispatch(admSwitchSideBarHead(head));}
            dispatch(admSwitchSideBar(sidevisible));
            }

            if (obj.status==='propelem' || obj.status==='options'|| obj.status==='password' || obj.status==='iconvisual'
                || obj.status==='iconelem') {
                const fieldUrl = selectFieldsForFiles(obj.table);
                if (!fieldUrl) {dispatch(sendErrorToBlock(createQuestion("Ошибка: нет table[url/name]"+sidehead['table']))); return false}
                const nameCaption= record[0][fieldUrl.name];
                const head={table:obj.table,namefield:obj.name,id:obj.id,namerasdel:nameCaption};
               // console.log('newSidebody',record[0], head) // newSidebody извлечь параметры
                if(sidevisible) {
                    const newSidebody=record[0];
                    try{
                        newSidebody.parametres=  !newSidebody.parametres ? {}//({size:''})
                        : typeof newSidebody.parametres!=='object'?
                            JSON.parse(newSidebody.parametres) : newSidebody.parametres ;
                        }

                    catch (e) {   newSidebody.parametres={}; console.log('new Side body parametr parse')}
                    dispatch(admSwitchSideBarBody({head,body:newSidebody}));

                } else{dispatch(admSwitchSideBarHead(head));}
                dispatch(admSwitchSideBar(sidevisible));
            }

        }
        // for rasdel table

       // console.log('record',record)
    }
}

const getPictureAfterUpdate= async(sidehead)=>{
    let newSidebody=[];
    const res = await axios.post(pref+'/api/findpicturrasdel/', {id: sidehead.id, table: sidehead.table});
    if (res.data.answer && res.data.answer.isLoad) {
        if (res.data.answer.result.length) {
            const arrRes = res.data.answer.result;
            const site = Sets.remsite + '/' + res.data.answer.put;
            newSidebody = arrRes.map(elem => pictureInElementArray(elem, site));
            //console.log('newSidebody', newSidebody);
        }
    }
    return newSidebody;
};
export const getCurrentSetsIUploader=async (table)=>{
    const res = await axios.post(pref+'/api/findsetsuploader/', { table});
    console.log('findsetsuploader', res);
    if (res.data.answer && res.data.answer.isLoad) {
        delete res.data.answer.isLoad;
        return res.data.answer;
    }
    return false;
};
export const checkerImageIcon=(e,table)=>{
    return  async(dispatch,getState)=> {
        const sidebody={...getState().adminrasdel.sidebody};
        if(sidebody.iconurl.length>0){dispatch(sendErrorToBlock('Удалите предыдущую иконку')); return;}
        const files = e.target.files;
        dispatch(sendErrorToBlock(''));
        const arrImg=['image/jpeg','image/png','image/svg+xml','image/svg']; const MAXPIX=128;
        if (!arrImg.includes(files[0].type)) {dispatch(sendErrorToBlock('Неподдерживаемый тип')); return;}
        let url = window.URL || window.webkitURL;
        const img = new Image();
        img.src = url.createObjectURL(files[0]);
        await img.decode();
        if (files[0].type===arrImg[0] || files[0].type===arrImg[1]){
            if(img.width>MAXPIX || img.height>MAXPIX) {dispatch(sendErrorToBlock('Размер растровой картинке не более: '+MAXPIX)); return;}
        }
        const cyrillicToTranslit = new CyrillicToTranslit();
        const data = new FormData();
        const newFileName=cyrillicToTranslit.transform(files[0].name,'_').toLowerCase();
        data.append('filename', newFileName);
        data.append('folder','icon');
        data.append('nomer','');  data.append('status','old');
        data.append('file',  files[0],newFileName);
        const res=await axios.post(pref+'/api/uploadimgtofolder/', data);
        if(res.data.isLoad) { // data.record - object to array
            const head={...getState().adminrasdel.sidehead};
            sidebody.iconurl=res.data.put+'/'+res.data.name;
            sidebody.color='none';
            let objVal={table:table,id:sidebody.id.toString(),name:sidebody,status: "saverecord"};
            dispatch( changeRecordStatus(objVal) )//table id name status
            dispatch(admSwitchSideBarBody({head,body:sidebody}));
            }else{dispatch(sendErrorToBlock('Error on server '))}

    }
};
export const changeIconTag=(type,param)=>{
    return  async(dispatch,getState)=> {
        const sidebody={...getState().adminrasdel.sidebody};
        const head={...getState().adminrasdel.sidehead}; //namefield

        const res=await axios.post(pref+'/api/handleicon/', {table:head.table,id:head.id,type,param});
        if(res.data.isLoad) {
            //console.log('changeIconTag', res.data);
            if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg)));return 0;}
            sidebody.icon=res.data.icons;
            const rasdel = {...getState().adminrasdel[head.table]};
            const data = rasdel.data;
            const recordId = data.find(val => String(val.id) === sidebody.id.toString());
            recordId.icon=res.data.icons;
            dispatch(changeAdmRasdelSuccess({arr:rasdel,namerasdel:head.table}));
            dispatch(admSwitchSideBarBody({head,body:sidebody}));
        }else{}
    }
};

export function deleteFileToPut(put) {
    return  async(dispatch,getState)=> {
    if(put) {
        let res = await axios.post(pref+'/api/deletefile/', {data: JSON.stringify({put: put})});
        if(res.data.isLoad) {
            if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg)));}
            const sidebody={...getState().adminrasdel.sidebody};
            const head={...getState().adminrasdel.sidehead};
            sidebody.iconurl='';
            let objVal={table:head.table,id:sidebody.id.toString(),name:sidebody,status: "saverecord"};
            dispatch( changeRecordStatus(objVal) )//table id name status
            dispatch(admSwitchSideBarBody({head,body:sidebody}));
        }else{}
    }
    }
}
//--comms-------------------
export function admSetComment(comments){
    return  {    type: ADM_COMMENTS,
        comments  }
}
export function admSetCommentUsers(commusers){
    return  {    type: ADM_COMMENTS_USERS,
        commusers  }
}
export function admSetCommentIP(commip){
    return  {    type: ADM_COMMENTS_IP,
        commip}
}
export function changeComments(obj){
    return  async(dispatch,getState)=> {
        try{
            console.log('changeComments', obj);
            dispatch(sendErrorToBlock(''));
            const sidehead={...getState().adminrasdel.sidehead};
            let res = await axios.post(pref+'/api/updatecomm/', {...obj,article:sidehead.id});
            if(res.data.error){dispatch(sendErrorToBlock(createQuestion("Ошибка в блоке комм:"+res.data.msg)));return 0;}
            if(res.data.comms){
                dispatch(admSetCommentUsers(res.data.users||[]));
                dispatch(admSetCommentIP(res.data.ipnot||[]));
                dispatch(admSetComment(res.data.comms||[]));
            }
            if(res.data.upArt && res.data.upArt.length){
                const articles={...getState().adminrasdel.article};
                articles.data.forEach(elem=>{if(elem.id===parseInt(res.data.upArt[0].id)) {elem.comment=res.data.upArt[0].comment; }});
                dispatch(updateAdmRasdelSuccess({arr:articles,namerasdel:'article'}));
            }
            //console.log('changeComments obj', obj.status, res.data); !!!!! check delete article for comments
        }   catch (e) {

        }
    }
}

//--coms-------------------

export function admSwitchSideBar(sidestate){
    return  {    type: ADMIN_SWITCH_SIDEBAR,
                   sidestate  }
}
export function admSwitchSideBarHead(head){
    return  {    type: ADMIN_SWITCH_SIDEBAR_HEAD,
        head  }
}
export function admSwitchSideBarBody(headbody){
    return  {    type: ADMIN_SWITCH_SIDEBAR_BODY,
        headbody  }
}
export function admSwitchSideBarType(sidetype){
    return  {    type: ADMIN_SWITCH_SIDEBAR_TYPE,
        sidetype  }
}
export function admSetsIUploader(sets){
    return  {    type: ADMIN_SWITCH_SIDEBAR_SETS,
        sets  }
}

export function admCloseSideBar(){
    return  dispatch=> {
        dispatch(admSwitchSideBar(0));
    }

}
const addCount=(arr,id,nomer,field,increase)=>{
    return arr.map(elem=>{
        if(parseInt(elem[id])===parseInt(nomer)){ elem[field]=parseInt(elem[field])+increase;} return elem;})
};

export function savePicturePreview({data,tbl}){
    return  async(dispatch,getState)=> {
        console.log('savePicturePreview',tbl) //'folder --> this.props.sidehead.table
        const checker=await axios.post(pref+'/api/checkerrights/', {table:tbl,action:'savepicture'});
        if(checker.data.error===1){ dispatch(sendErrorToBlock(checker.data.msg));return;}
        const res=await axios.post(pref+'/api/upload/', data);//res.status
        let current=0;
        if(res.data.isLoad) { // data.record - object to array
            console.log('savePicturePreviewres.data',res)
            let sidebody=[...getState().adminrasdel.sidebody];
            const sidehead={...getState().adminrasdel.sidehead};
            const table=sidehead.table;
            const site=Sets.remsite+'/'+res.data.put;
            if(res.data.mystatus==='new'){
                const newPict= pictureInElementArray(res.data.record,site);
                sidebody.push(newPict);current=sidebody.length-1 ||0;
                const rasdel={...getState().adminrasdel[table]}; // add countpicture-----
                rasdel.data=addCount(rasdel.data,"id",sidehead.id,'picture',1);
                dispatch(getAdmRasdelSuccess({arr:rasdel,namerasdel:table}));
            } else{sidebody=changeDatestampEnd(sidebody,res.data.record);
                current=sidebody.map(elem=>elem.id).indexOf(parseInt(res.data.record.id)) ;}
            sidehead.currentpictur=current;
            dispatch(admSwitchSideBarBody({head:sidehead,body:sidebody}));
        }
        return res.data.isLoad;
    }
}
export function deletePicturePreview(obj){
    return  async (dispatch,getState)=> {
        const blocker=getState().adminrasdel.blocker;
        if (blocker) return false;
       // dispatch(getAdmRasdelStart('deletepicture'));
        const sidehead={...getState().adminrasdel.sidehead};
        let sidebody=[...getState().adminrasdel.sidebody];
        const table=sidehead.table;
        const idpictur=sidebody[sidehead.currentpictur].id;
        const pictuRec={id:idpictur,table:obj.table,idrasdel:sidehead.id};
        console.log('delete picture',pictuRec);
        //return;
        const res2 = await axios.post(pref+'/api/delpreview/',{...pictuRec,tblpict:obj.table+'pict'});
        if(res2.data.isLoad) {
            if(res2.data.error===1){dispatch(sendErrorToBlock(res2.data.msg));console.log('delete picture',res2.data);
                return;}
            sidebody=res2.data.records;
            const site = Sets.remsite + '/' + res2.data.put;
            sidebody=sidebody.map(elem => pictureInElementArray(elem, site));
            const lenRecords=sidebody.length;
               sidehead.currentpictur=(parseInt(sidehead.currentpictur)>lenRecords-1)
                ?(lenRecords-1)
                :sidehead.currentpictur;
            dispatch(admSwitchSideBarBody({head:sidehead,body:sidebody}));
            const rasdel={...getState().adminrasdel[table]}; // substract countpicture-----
            rasdel.data=addCount(rasdel.data,"id",sidehead.id,'picture',-1);
            dispatch(getAdmRasdelSuccess({arr:rasdel,namerasdel:table}));
            //console.log('---', res2.data)
        }
         if(res2.data.error) {
             dispatch(sendErrorToBlock(createQuestion("Ошибка при удалении:"+res2.data.msg)));
             dispatch(admSwitchSideBar(0));
         }

       // dispatch(getAdmRasdelNewRec());
    }
}

export function changeNumCurrentPict(direct){
    return  (dispatch,getState)=> {
        //console.log('direct',direct)
        const sidehead={...getState().adminrasdel.sidehead};
        const sidebody=[...getState().adminrasdel.sidebody];
        const maxRec=sidebody.length; const current=sidehead.currentpictur;
        if(direct>0 && current<maxRec-1){sidehead.currentpictur=sidehead.currentpictur+1; }
        if(direct<0 && current>0){sidehead.currentpictur=sidehead.currentpictur-1; }
        dispatch(admSwitchSideBarHead(sidehead));

    }

}

export function  sortPicturePost(obj){
    return  async (dispatch,getState)=> {
        const blocker=getState().adminrasdel.blocker;
        if (blocker) return false;
        //dispatch(getAdmRasdelStart('sortpicture'));
        const sidehead={...getState().adminrasdel.sidehead};
        const sidebody=[...getState().adminrasdel.sidebody];
        const idpictur=sidebody[sidehead.currentpictur].id;
        if(obj.name<1 && parseInt(sidehead.currentpictur)===0) {console.log('limit left');dispatch(updateAdmRasdelRec());return false;}
        if(obj.name===1 && parseInt(sidehead.currentpictur)===sidebody.length-1) {console.log('limit right');dispatch(updateAdmRasdelRec());return false;}
         const director=obj.name<1 ? 'sortup': 'sortdown';
        const pictuRec=[{id:idpictur,sort:sidehead.currentpictur+1}];
        dispatch(admBlockerActive());
        const res2 = await axios.post(pref+'/api/sortrasdel/',{arr:pictuRec,direct:'ASC',director,table:sidehead.table+'pict'});
        if(res2.data.isLoad) {
            sidehead.currentpictur+=parseInt(obj.name);
            console.log('---',res2.data)
            dispatch(admSwitchSideBarBody({head:sidehead,body:[]}));
            // blok equal bloky string 295 put out (sidehead)!!!! blocker!!!!!!!!!!
            const newSidebody=await getPictureAfterUpdate(sidehead);
            dispatch(admSwitchSideBarBody({head:sidehead,body:newSidebody}));
        }
        dispatch(updateAdmRasdelRec());
        dispatch(admBlockerDeactive());
       // console.log(obj, id,sidebody.length)
    }
}


//---action with article---------------
export function admRedactArticle(obj) {
    return async (dispatch,getState)=> {
        const cssstyle={...getState().adminrasdel.cssstyle};
        const sidearticlestate=getState().adminrasdel.sidearticlestate;
        const sidearticle=getState().adminrasdel.sidearticle;
        dispatch(sendErrorToBlock(''));
        if(obj.id===sidearticle.id){sidearticlestate.state=!sidearticlestate.state;}
        else{dispatch(admSetSideArticle({text:[]}));sidearticlestate.state=1}
        dispatch(admSetsWidthTable(changeStyles(sidearticlestate.state,cssstyle)));
        dispatch(admSwitchSideBar(0));
        dispatch(admSwitchSideArticle(sidearticlestate));

        if(sidearticlestate.state){
           // console.log("admRedactArticle", obj)savePictureArticle
            const res = await axios.post(pref+'/api/getarticle/',obj);
            if(res.data.isLoad) {
                if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.error)));}
                else{
                    const article={...res.data}; delete article.isLoad; delete article.error; article.editblock={};
                    article.id=obj.id;
                    if(article.text){
                        try {
                            const temp = JSON.parse(article.text);
                            if(Array.isArray(temp)){article.text=temp}
                            else{
                                article.text=[]; dispatch(sendErrorToBlock(createQuestion("Ошибка в данных")));
                                console.log("Ошибка в данных", article.text,temp);
                            }
                        }catch(e){console.log('error get data', e,article.text);article.text=[{text:'Error in base data:<br/>'+article.text}];}


                    } else{article.text=[]}
                    article.tags={hidden:0,arr:article.tags? (article.tags):[]};//JSON.parse
                    dispatch(admSetSideArticle(article));
                }
            }
        }
    }
}
export function admSaveArticle() {
    return async (dispatch,getState)=> {
        const sidearticle={...getState().adminrasdel.sidearticle};
        dispatch(admBlockerActive());
        const res = await axios.post(pref+'/api/savearticle/',
            {text: JSON.stringify(sidearticle.text),id:sidearticle.id,tbl:'article',tagscash:JSON.stringify(sidearticle.tags.arr)});
        if(res.data.isLoad) {
            if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg)));}
            dispatch(admBlockerDeactive());
        } else{dispatch(sendErrorToBlock(createQuestion("Ошибка: error link"))); dispatch(admBlockerDeactive()) }
    }
}
export function admCloseSideArticle(status){
    return async (dispatch,getState)=> {
        const cssstyle={...getState().adminrasdel.cssstyle};
        const sidearticlestate={...getState().adminrasdel.sidearticlestate};
         sidearticlestate.state=status; sidearticlestate.viewarticle=0;
        dispatch(admSetsWidthTable(changeStyles(sidearticlestate.state,cssstyle)));
        dispatch(admSwitchSideArticle(sidearticlestate));
    }
}
export function admHiddenMainPanel(status){
    return async (dispatch,getState)=> {
        const sidearticlestate={...getState().adminrasdel.sidearticlestate};
        sidearticlestate.viewarticle=status;
        dispatch(admSwitchSideArticle(sidearticlestate));
    }
}

export function admSetsWidthTable(cssstyle){
    return  {
        type: ADMIN_CSS_SETS,
        cssstyle  }
}

export function admSwitchSideArticle(status){
    return  {
        type: ADMIN_ARTICLE_SWITCH,
        status  }
}
export function admSetSideArticle(article){
    return  {
        type: ADMIN_ARTICLE_SET,
        article  }
}

export function admAddContentBlock(htmlobj){
    return  (dispatch,getState)=> {
        const blocker=getState().adminrasdel.blocker;
        if (blocker) return false;
        const sidearticle={...getState().adminrasdel.sidearticle};
        const newtxt=converEmoji(htmlobj.markup,'to')
        if(htmlobj.type!=='clear') {
            if (htmlobj.id === 'no') {
                sidearticle.text.push({text: newtxt, type: htmlobj.type, option:htmlobj.option||''});
            } else {sidearticle.text[sidearticle.editblock.id] = {text: newtxt, type: htmlobj.type}}
        }
        sidearticle.editblock={};
        console.log("sidearticle",sidearticle)
        dispatch(admSetSideArticle(sidearticle));
        if(htmlobj.type!=='clear') dispatch(admSaveArticle());
        console.log("admAddContentBlock",{text:newtxt,type:htmlobj.type})

    }
}
export function admCloseEditBlock(){
    return  (dispatch,getState)=> {
        const blocker=getState().adminrasdel.blocker;
        if (blocker) return false;
        const sidearticle={...getState().adminrasdel.sidearticle};
        sidearticle.editblock={};
        dispatch(admSetSideArticle(sidearticle));
        dispatch(admSaveArticle());
    }
}

export function admEditContentBlock(status,id){
    return  async (dispatch,getState)=> {
        console.log("admEditContentBlock",status,id)
        const blocker=getState().adminrasdel.blocker;
        if (blocker) return false;
        const sidearticle={...getState().adminrasdel.sidearticle};
        switch (status){
            case'markdelete':
                sidearticle.text[id].delete=true;
                break;
            case'canceldelete':
                delete sidearticle.text[id].delete;
                break;
            case'delete':
                sidearticle.text=sidearticle.text.filter((elem,index)=>index!==id); sidearticle.editblock={};
                break;
            case'editnote': sidearticle.editblock=sidearticle.text[id]; sidearticle.editblock.id=id;
                sidearticle.editblock.note=sidearticle.editblock.note || 'Введите текст';
                dispatch(admStateEditor(1)); break;
            case'edit':  const emojiObj={...sidearticle.text[id]};
                emojiObj.text=converEmoji(emojiObj.text,'from')
                sidearticle.editblock=emojiObj; sidearticle.editblock.id=id;
                 dispatch(admStateEditor(0)); break;
            case'moveup': sidearticle.text=changePlaceInArray(id,sidearticle.text,'up');
                sidearticle.text= sidearticle.text.map(elem=>{delete elem.delete;return elem;});
                sidearticle.editblock.id=checkIdRedactArticle(id,id-1,sidearticle.text.length,sidearticle.editblock.id);
            break;
            case'movedown': sidearticle.text=changePlaceInArray(id,sidearticle.text,'down');
                sidearticle.text=sidearticle.text.map(elem=>{delete elem.delete;return elem;});
                sidearticle.editblock.id=checkIdRedactArticle(id,id+1,sidearticle.text.length,sidearticle.editblock.id);
            break;
            case 'change align': const {align}=sidearticle.text[id];
                sidearticle.text[id].align=!align || align==='left'?'center':align==='center'?'right':'left'; break;
            case 'change crop': const {option}=sidearticle.text[id];
                sidearticle.text[id].option=option==='16'?'4':'16'; break;
            case'deleteimage':
                const {link,arrNames}=sidearticle.text[id]; dispatch(admBlockerActive());
                if(arrNames){ //all del allpictures article
                    const res = await axios.post(pref+'/api/deleteimginfolder/',
                            {link:link+arrNames[0],howmany:'only',id:arrNames[0].split('-')[0]});
                    console.log("status",res.data, sidearticle.text[id]);
                    if (res.data.isLoad) {
                        if(res.data.error){dispatch(getRasdelError(res.data.msg))}
                        sidearticle.text=sidearticle.text.filter((elem,index)=>index!==id); sidearticle.editblock={};
                    }
                }else{sidearticle.text=sidearticle.text.filter((elem,index)=>index!==id); sidearticle.editblock={};}
                dispatch(admBlockerDeactive());

                break;
                default: break;

        }
        dispatch(admSetSideArticle(sidearticle));
        dispatch(admSaveArticle());
        //console.log("status",status,id)
    }
}

export function admStateEditor(status){
    return  (dispatch,getState)=> {
        console.log("status",status)
        const sidearticlestate={...getState().adminrasdel.sidearticlestate};
        sidearticlestate.menu=status;
        dispatch(admSwitchSideArticle(sidearticlestate));
    }
}

export function savePictureArticle(formdata){
    return  async(dispatch,getState)=> {
        const blocker=getState().adminrasdel.blocker;
        const checker=await axios.post(pref+'/api/checkerrights/', {table:'article',action:'savepicture'});
        if(checker.data.error===1){ dispatch(sendErrorToBlock(checker.data.msg));return;}
        if (blocker) return false;
        dispatch(admBlockerActive());
        const res=await axios.post(pref+'/api/uploadimgtofolder/', formdata);//res.status
      //  let current=0;
        if(res.data.isLoad) { // data.record - object to array
            console.log('res.data',res.data)
            const result={link:res.data.put+'/',arrNames:res.data.name,note:res.data.note,type:'image',align:res.data.align};
            const sidearticle={...getState().adminrasdel.sidearticle};
            console.log('savePictureArticle res.data.isLoad',result)
            sidearticle.text.push(result);
            dispatch(admSetSideArticle(sidearticle));
            dispatch(admSaveArticle());
        }
        dispatch(admBlockerDeactive());
        return res.data.isLoad;
    }
}

export function savePictureNote(data) {
    return  async(dispatch,getState)=> {
        const sidearticle={...getState().adminrasdel.sidearticle};
        sidearticle.editblock.note=data;
        if(!data.length) sidearticle.editblock={};
        dispatch(admSetSideArticle(sidearticle));
    }
}
export function admSetSideTags(tags){
    return  {
        type: ADMIN_TAGS_LIST,
        tags  }
}
export function admEditTag(status,data){
    return  async(dispatch,getState)=> {
        if(data.type){
            const sidearticle={...getState().adminrasdel.sidearticle};
            const newdData={table:'linktagart', status:data.type};
            switch (data.type) {
                case 'visible': sidearticle.tags.hidden=!sidearticle.tags.hidden;
                    if(sidearticle.tags.hidden){
                        const res=await axios.post(pref+'/api/tagsarticle/', {data:JSON.stringify(newdData)});//res.status
                        if(res.data.isLoad){
                            if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg))); return false;}
                            dispatch(admSetSideTags(res.data.record));
                        }
                    }break;
                case 'save': const tags= sidearticle.tags.arr.filter(elem=>{return parseInt(elem.id)===parseInt(data.data.id)});
                    if(!tags.length) {
                        newdData.tagsid=data.data.id; newdData.articleid=sidearticle.id;
                        const res=await axios.post(pref+'/api/tagsarticle/', {data:JSON.stringify(newdData)});//res.status
                            if(res.data.isLoad){
                                if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg))); return false;}
                                sidearticle.tags.arr.push(data.data);
                            }
                    }break;
                case 'delete': newdData.tagsid=data.data.id; newdData.articleid=sidearticle.id;
                    const res=await axios.post(pref+'/api/tagsarticle/', {data:JSON.stringify(newdData)});
                    if(res.data.isLoad){
                        console.log('res.data',res.data);
                        if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg))); return false;}
                        sidearticle.tags.arr=res.data.record.result;
                    }
                    break;
                default: return false;
            }
            dispatch(admSetSideArticle(sidearticle));
            dispatch(admSaveArticle());
        }
    }
}
// element redaction

export function changeElement(type,value,name) {
    return  async(dispatch,getState)=> {
        dispatch(sendErrorToBlock([]));
        const sidebody={...getState().adminrasdel.sidebody};
        const sidehead={...getState().adminrasdel.sidehead};
        switch(type){
            case 'change':sidebody[name]=value; break;
            case 'changeparam':sidebody.parametres[name]=value; break;
            case 'addfields': let objKeys=Object.keys(sidebody.parametres);
                name.forEach(elem=>{ if(!objKeys.includes(elem)){sidebody.parametres[elem]='';} }); break;
            case 'saveInfo': let sidebodyTmp={...sidebody};
                let tmpParam = Object.fromEntries(Object.entries(sidebodyTmp.parametres).filter(([_, v]) => v.toString().length>0 ));
                //console.log('tmpParam', tmpParam)
                sidebodyTmp.parametres=JSON.stringify(tmpParam);
                sidebody.parametres=tmpParam;
            let objVal={table:sidehead.table,id:sidebodyTmp.id.toString(),name:sidebodyTmp,status: "saverecord"};
                dispatch(changeRecordStatus(objVal));
            break;
            case 'changeColorIcon': {let sidebodyTmp = {...sidebody};
                if(sidebodyTmp.color==='none') return;
                let res = await axios.post(pref+'/api/icongetcodeicon/', {data: JSON.stringify(sidebodyTmp.iconurl)});
                if (res.data.isLoad) {
                    if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg))); return;}
                    const recolor= newColorSvg(res.data.msg,sidebodyTmp.color);
                    if(recolor.error){dispatch(sendErrorToBlock(createQuestion("Ошибка:"+recolor.msg))); return;}
                     res = await axios.post(pref+'/api/iconsavecodeicon/', {data: JSON.stringify({text:recolor.html,url:sidebodyTmp.iconurl})});
                    if (res.data.isLoad) {
                        if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg))); return;}
                        let objVal={table:sidehead.table,id:sidebodyTmp.id.toString(),name:sidebodyTmp,status: "saverecord"};
                        dispatch(changeRecordStatus(objVal));
                        //console.log('changeColorIcon', res.data)
                    }else{dispatch(sendErrorToBlock('undefined server error')); return;}

                }else{dispatch(sendErrorToBlock('undefined server error')); return;}
            }
                break;
            case 'getInfoFile':
                const fieldUrl = selectFieldsForFiles(sidehead['table']);
                if (!fieldUrl) {dispatch(sendErrorToBlock(createQuestion("Ошибка: нет table[url/name]"))); return false}
                if(sidebody[fieldUrl.url]) {
                    let blocker=getState().adminrasdel.blocker;
                    if (blocker) return false;
                    dispatch(admBlockerActive());
                     let res=await axios.post(pref+'/api/getinfofile/', {data: JSON.stringify({put:sidebody.elementurl})});
                    if(res.data.isLoad) {
                        if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg)));}
                        else{
                            if(res.data.file){ let parametres={};
                                parametres.size=res.data.file.size || '';
                                if(res.data.file.mp3) {
                                    parametres.artist = res.data.file.mp3.name || '';
                                    parametres.song = res.data.file.mp3.title || '';
                                    parametres.time = res.data.file.mp3.time || '';
                                    if (!fieldUrl) {dispatch(sendErrorToBlock(createQuestion("Ошибка: нет table[url/name]")));
                                    } else {sidebody[fieldUrl.name] = `${parametres.artist} – ${parametres.song}`}
                                }
                                sidebody['parametres']=parametres;
                            }
                        }
                       // console.log('getinfofile', res.data)
                    }
                    dispatch(admBlockerDeactive());
                }
                break;
            default:return false;
        }
        dispatch(admSwitchSideBarBody({head:sidehead,body:sidebody}));
    }
}

export function parserLink(type,value) {
    return  (dispatch,getState)=> {
        const sidebody={...getState().adminrasdel.sidebody};
        const sidehead={...getState().adminrasdel.sidehead};
        const fieldUrl = selectFieldsForFiles(sidehead['table']);
        if (!fieldUrl) {dispatch(sendErrorToBlock(createQuestion("Ошибка: нет table[url/name]"))); return false}
        switch(type){
            case 'google':
                const params = value.split('/'); //5
                let shablon='https://drive.google.com/uc?export=download&confirm=no_antivirus&id=';
                if(params[5]) sidebody[fieldUrl.url]=shablon+params[5];
            break;
            case 'yandex':break;
            default: sidebody[fieldUrl.url]='No this variant'; break;
        }
        dispatch(admSwitchSideBarBody({head:sidehead,body:sidebody}));
    }
}


//work with File System
export function admSetFileState(filestate){
    return  {
        type: ADMIN_FILE_STATE,
        filestate  }
}

export function workFS(e,typer,arr) {
    return  async(dispatch,getState)=> {
        const [type,place]=arr;
        const filestate={...getState().adminrasdel.filestate};
        dispatch(sendErrorToBlock([]));
        console.log('arr',arr)
        switch(type){
            case 'getFolder':
                let res=await axios.post(pref+'/api/workfolder/', {data: JSON.stringify(arr)});
                if(res.data.isLoad) {
                    if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg)));return 0;}
                    filestate.dirs=res.data.dir.dirs;filestate.files=res.data.dir.files;
                    filestate.current=place; localStorage.setItem('Fileselector',place);
                    dispatch(admSetFileState(filestate));
                }
                break;
            case 'loadFile': if (e.target.files && e.target.files.length > 0) {
                const checker=await axios.post(pref+'/api/checkerrights/', {table:'filesystem',action:'loadfile'});
                if(checker.data.error===1){ dispatch(sendErrorToBlock(checker.data.msg));return;}
                const cyrillicToTranslit = new CyrillicToTranslit();
                const data = new FormData();
                const newFileName=cyrillicToTranslit.transform(e.target.files[0].name,'_').toLowerCase();
                data.append('filename', newFileName);
                data.append('folder',filestate.current);
                data.append('nomer','');  data.append('status','old');
                data.append('file',  e.target.files[0],newFileName);
                const res=await axios.post(pref+'/api/uploadimgtofolder/', data);
                if(!res.data.isLoad){dispatch(sendErrorToBlock(createQuestion("Ошибка:Нет соединения. maybe")));return 0;}
                await dispatch(workFS(null,'',['getFolder',filestate.current]));
            }            break;
            case 'selectFile': const sidehead={...getState().adminrasdel.sidehead};
                const sidebody={...getState().adminrasdel.sidebody};
                const fieldUrl=selectFieldsForFiles(sidehead['table']);
                if(!fieldUrl){dispatch(sendErrorToBlock(createQuestion("Ошибка:потерян header или нет table[url]")));return 0;}
                sidebody[fieldUrl.url]=place.put; sidebody.parametres[fieldUrl.size]=place.size;
                dispatch(admSwitchSideBarBody({head:sidehead,body:sidebody}));

                break;
            case 'orderUp': const parts=place.split('/');
                    if(parts.length>1) { parts.pop();await dispatch(workFS(null,'',['getFolder',parts.join('/')]));}
                 break;
            case 'createFolder':if(place){
                const cyrillicToTranslit = new CyrillicToTranslit();
                const nameFolder=cyrillicToTranslit.transform(place,'_').toLowerCase();
                let res=await axios.post(pref+'/api/createfolder/', {data:JSON.stringify({dir:filestate.current,name:nameFolder})});
                if(res.data.isLoad) {
                    if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg)));return 0;}
                    await dispatch(workFS(null,'',['getFolder',filestate.current]));
                    }
                console.log('res', res)
            } break;
            case 'deleteFile':
                if(place) {
                    let res = await axios.post(pref+'/api/deletefile/', {data: JSON.stringify({put: place})});
                    if(res.data.isLoad) {
                        if(res.data.error) {dispatch(sendErrorToBlock(createQuestion("Ошибка:"+res.data.msg)));return 0;}
                        await dispatch(workFS(null,'',['getFolder',filestate.current]));
                    }
                }
            break;

            default:break;
        }
    }
}
// work with structure katalog
export function admSetDirState(dirstate){
    return  {
        type: ADMIN_DIR_STATE,
        dirstate  }
}

export function admWorkInnerDir(status,data){
    return  async(dispatch,getState)=> {
        const dirstate={...getState().adminrasdel.dirstate};let res;
        switch(status){
            case 'refreshDir':
                dirstate.filestamp=Date.now();
                dispatch(admSetDirState(dirstate));
                break;
            case 'getDir':
                 res = await axios.post(pref+'/api/innerlist/', {...data,status:status});
                console.log('res````', res)
                if(res.data.isLoad) {
                    dirstate.dirs=res.data.rasdel;dirstate.elements=res.data.elements;
                    dirstate.current=res.data.current; dirstate.currentpath=res.data.currentpath;
                   // dirstate.filestamp=Date.now();
                    dispatch(admSetDirState(dirstate));
                    localStorage.setItem(data.localParam,dirstate.current);
                   // console.log('res', res)
                } break;
            case 'workDir':
                console.log('dirstate', dirstate)
                dirstate.current=data; dispatch(admSetDirState(dirstate)); console.log('dirstate', dirstate);
                break;
            case 'upDir':
                let newdata={rasdel:data[0],table:data[1]};
                 let res2 = await axios.post(pref+'/api/innerlist/', {...newdata,status:status});
                if(res2.data.isLoad) {
                    dirstate.current=res2.data.current;dispatch(admSetDirState(dirstate));
                }
                break;
            case 'selectElement':
                const sidearticle={...getState().adminrasdel.sidearticle};
                 res = await axios.post(pref+'/api/innerlist/', {rasdel:data[0],table:data[1],status:status});
                if(res.data.isLoad) {
                    if(res.data.error) {dispatch(sendErrorToBlock(createQuestion('Ошибка:problem element parametres')));return 0;}
                    dispatch(sendErrorToBlock(''));
                    const fieldUrl = selectFieldsForFiles(data[1]);
                    const newObj={}; newObj.parametres={};
                    newObj.url=res.data.record[fieldUrl.url]; newObj.name=res.data.record[fieldUrl.name];
                    newObj.type=selectTypeElement(data[1],res.data.record.rolelement);
                    newObj.note=res.data.record.note;newObj.id=res.data.record.id;
                    try {
                        if(res.data.record.parametres){
                            newObj.parametres=JSON.parse(res.data.record.parametres);
                        }
                    }catch(e){console.log('Ошибка в параметрах элемента', e);
                    dispatch(sendErrorToBlock('Ошибка в параметрах элемента'));return 0;
                    }
                    sidearticle.text.push({text: JSON.stringify(newObj), type: 'element'});
                    dispatch(admSetSideArticle(sidearticle));
                    dispatch(admSaveArticle());
                    console.log('res',  newObj)
                }

                break;
            default:break;
        }
    }
}
