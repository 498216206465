import React, {Component} from "react";
import classes from './Imguploader.module.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {ImagePreview,getCroppedImgOrig} from "./Introduce";
import axios from "axios";
import Sets from "../../adm/Sets/sets";
import Buttoncom from "../Buttoncom/Buttoncom";

class ImgUploader extends Component{

    state={ file:null, src:null, filename:[], regim:1, regimdelete:0, sizePreview:[200,200],sizeImageMax:1000,
        crop: { unit: '%', width: 30, aspect: 9 / 9,},status:'new', changeMainPictur:1,
        croppedImageUrlOrig:null,blobOrig:null, newSizeOrig:'',
        croppedImageUrl:null, blob:null, note:'', arrBody:[], currentPictur:0};
    localFunc={
        saverecord: (e)=>this._handleSubmit(e), switchon:(e)=>this.changeRegim(e), switchoff:(e)=>this.changeRegim(e),
        editrecord:(e)=>this._handleImageSaveChange(e), markdeleterecord:(e)=>this._regimDeleteOnOff(e),
        canceledit:(e)=>this._regimDeleteOnOff(e)
    };

    _handleSubmit=async (e)=> {
      //  e.preventDefault();
        let tbl='';
        if(!this.state.blob) {console.log('file dont select');return;}
       // await this.changeMainPictur(this.imageRef);  //изменить главную картинку ------------------
        const dat=Date.now(); let origBlob, origPrefix;
        if(this.state.changeMainPictur){ origBlob=this.state.blobOrig; origPrefix ='-big-'}
        else{ origBlob=this.state.blob; origPrefix ='-small-'}
        tbl=this.props.sidehead.table;
        const data = new FormData();
        data.append('filename', this.state.filename[0]);
        data.append('folder',this.props.sidehead.table);
        data.append('nomer',this.props.sidehead.id); // nomer rasdel
        data.append('status',this.state.status);
        data.append('note', this.state.note);

        if(this.state.status==='new') {    // Здесь решать запись двух картинок или одной-------------------------------------
            data.append('file', this.state.blob, dat + '-small-' + this.state.filename[0]);
            data.append('file', origBlob, dat + origPrefix + this.state.filename[0]);
        }else{
            const currentPicture=this.state.arrBody[this.state.currentPictur];
            data.append('unid', currentPicture.id);
            data.append('file', this.state.blob,  this.state.filename[0]);
            data.append('file', origBlob, this.state.filename[1]);
        }
        data.append('bigsize', JSON.stringify(this.state.newSizeOrig));
        data.append('smallsize', JSON.stringify({w:this.state.sizePreview[0],h:this.state.sizePreview[1]}));

       const res=await this.props.handler({status: 'savepicture', data:{data,tbl}});
        const newregim=this.state.status==='new'?this.state.regim:0;
       if (res) {this.setState({
           file:null, src:null, filename:null, croppedImageUrl:null, blob:null,regim:newregim,
       });}
        // TODO: do something with -> this.state.file
    };

     _handleImageChange(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = async () => {
                const newFileName=file.name;
                this.setState({
                    file: file, src: reader.result,status:'new',filename:[newFileName.replace(/\s/g,'_')]
                });
            };
            reader.readAsDataURL(file)
        }
    }
   async  _handleImageSaveChange(e) {
      //  e.preventDefault();
        const currentPicture=this.state.arrBody[this.state.currentPictur];

       if(currentPicture && currentPicture.pathbig ){
           // currentPicture send to action
           const site=Sets.remsite+'/';
           const arr=currentPicture.pathbig.split('.');
           const extension = arr[arr.length - 1];
           let url = currentPicture.pathbig.replace(site,'');
           const res = await axios.post('/api/getblob/',{urlpict:url,typ:extension});

           const newSmallpath=currentPicture.pathsmall.split('?')[0];
           const img5 = new Image();
           img5.src = "data:image/"+extension+";base64," + res.data;
           img5.onload = ()=>{
               this.setState({ src:img5.src, regim:1, status:'old',note:currentPicture.note,
                   filename:[newSmallpath,currentPicture.pathbig]
               });
               //todo: change state picture in rasdel
           };
           console.log('reader.result',this.state)
       }
    }


    changeRegim=(e)=>{ if(this.props.arrBody.length) {this.setState({ regim:!this.state.regim ,regimdelete:0, src:null})} };
    _regimDeleteOnOff=(e)=>{this.setState({regimdelete:!this.state.regimdelete});}
    // If you setState the crop in here you should return false.
    onImageLoaded =  image => { this.imageRef = image;     };

    changeMainPictur= async (image)=>{
        if (image ) {
        const {croppedImageUrl,blob,newsize}=await getCroppedImgOrig(
            this.imageRef,
            [1,this.state.sizeImageMax],
            'newFile.jpeg');
            this.setState({ croppedImageUrlOrig:croppedImageUrl,blobOrig:blob,newSizeOrig:{w:newsize.width,h:newsize.height} }); // tut vernut
        console.log('checkoriginal',);
    }}

    onCropComplete = async crop => {  this.makeClientCrop(crop); console.log(this.state);
        if(this.state.changeMainPictur){await this.changeMainPictur(this.imageRef);} //mainpicture
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop: this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const {croppedImageUrl,blob} = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl,blob });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
            const oc = document.createElement('canvas');
                oc.width = this.state.sizePreview[0];
                oc.height = this.state.sizePreview[1];
            const octx = oc.getContext('2d');
             octx.drawImage(canvas, 0,0,  this.state.sizePreview[0], this.state.sizePreview[1]);
        return new Promise((resolve, reject) => {
           // canvas.toBlob(blob => {
            oc.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve({croppedImageUrl:this.fileUrl,blob:blob});
            }, 'image/jpeg');
        });
    }

    changeInput=(e,field)=>{
        const controls={...this.state};
        controls[field]=e.target.value;
        this.setState((prevState, props)=>{
            return {...controls}
        });
    };

    outputButton= (regim,regimdelete)=>{
        const buttonsel=this.state.regimdelete?this.props.buttFor.delete:this.props.buttFor.edit;
        return regim ?

                (
                    <form onSubmit={(e)=>e.preventDefault()}>
                        <label className={classes.label} title={'Выберите изображение'}>
                            <input className={classes.fileinput} type="file" onChange={(e)=>this._handleImageChange(e)} />
                        </label>
                        <Buttoncom  buttons={this.props.buttFor.add} handler={this.props.handler}
                                   localFunc={this.localFunc} checkData={{}} tbl={this.props.sidehead.table}/>
                    </form>
                )
            : <form onSubmit={(e)=>e.preventDefault()}>
                <Buttoncom buttons={buttonsel} handler={this.props.handler}
                           localFunc={this.localFunc} checkData={{}} tbl={this.props.sidehead.table}/>

               {/* <button className={this.makeClass('editimage')} title={'Редактировать изображение'}
                        onClick={(e)=>this._handleImageSaveChange(e)}>{null}</button>
                <button className={this.makeClass('cropperon')} title={'режим добавления'}
                        onClick={(e)=> this.changeRegim(e)}>{null}</button>
                <button className={this.makeClass('deleteimage')} title={'Удалить изображение'}
                        onClick={(e)=>this.empty(e)}>{null}</button>*/}
            </form>
    };
    parametrSets= (parametr)=>{
        const {crop}=this.state;  crop.aspect= parseInt(parametr[2]||200) /parseInt(parametr[1]||200);
        return {sizeImageMax:parseInt(parametr[0]||1000), sizePreview:[parseInt(parametr[2]||200),parseInt(parametr[1]||200)],
            crop:crop,changeMainPictur:parseInt(parametr[3])}
    };

      componentDidMount() {
         const tempObj={arrBody:[], regim:0,currentPictur:0};
         if(this.props.arrBody.length){
             tempObj.arrBody=this.props.arrBody;tempObj.currentPictur=this.props.sidehead.currentpictur;
         }else{ tempObj.regim=1;}
         console.log('this.props.setting componentDidMount',this.props);
         const parametr=this.props.setting.parametr;
         if(parametr){
             this.setState({ ...this.parametrSets(parametr)})
             /*const {crop}=this.state;  crop.aspect= parseInt(parametr[2]||200) /parseInt(parametr[1]||200);
             this.setState({sizeImageMax:parseInt(parametr[0]||1000), sizePreview:[parseInt(parametr[2]||200),parseInt(parametr[1]||200)],
                 crop:crop,changeMainPictur:parseInt(parametr[3])})*/
         }
          this.setState({ arrBody:tempObj.arrBody,regim:tempObj.regim,currentPictur:tempObj.currentPictur});
            console.log('componentDidMount',this.state);
    }
    componentDidUpdate(prevProps){

        if (this.props.arrBody.length !== prevProps.arrBody.length || this.props.sidehead.id !== prevProps.sidehead.id ) {
            if(this.props.arrBody.length) {this.setState({ arrBody:this.props.arrBody,regim:0,regimdelete:0,
                currentPictur:this.props.sidehead.currentpictur});
              //  this.props.handler2({regim:0, status: 'switchregim'});
            console.log('componentDidUpdate',"true");
            }
            else{
                // this.props.handler2({regim:1, status: 'switchregim'});
                this.setState({ arrBody:[],regim:1,src:null });console.log('componentDidUpdate',"false");
            }
        }
        const parametr=this.props.setting.parametr;
        if(parametr) {
            if (this.props.sidehead.table !== prevProps.sidehead.table) { //console.log('parametr',parametr)
                this.setState({...this.parametrSets(parametr)});}
        }
        if (this.props.sidehead.currentpictur !== prevProps.sidehead.currentpictur )
        {this.setState({currentPictur:this.props.sidehead.currentpictur})}

       // console.log('this.props.regim',this.props.regim, this.state.regim);
      //  console.log('componentDidUpdate',this.state);
    }


    render(){
        let {src,crop, regim, note,regimdelete } = this.state;
        let imagePrev = (<div className={classes.previewtext}>Please select an Image for Preview</div>);
        //console.log('regim',regim);
        return(
            <div className={classes.uploader}>
                {
                    this.outputButton(regim,regimdelete)
                }
                <div className={classes.imgpreview}>
                    {!regim ?
                         <ImagePreview arr={this.state.arrBody} current={this.state.currentPictur} perehod={this.props.perehod}/>
                    :src
                        ?<React.Fragment>
                            <ReactCrop src={src} crop={crop} ruleOfThirds onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}   onChange={this.onCropChange} />
                             <input className={classes.note} onChange={e=>this.changeInput(e,'note')}
                                    value={note || ''} placeholder={'Введи подпись под картинкой'}/>
                        </React.Fragment>
                        : imagePrev
                    }

                </div>
            </div>
        )
    }

}


export default ImgUploader;

/*
    {croppedImageUrl && (
                        <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                    )}

   <button className={classes.cropper} title={'изменить размеры'}
                             onClick={(e)=>this.runCropper(e)}>{null}</button>
*/