import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Restore from './containers/Restore/Restore';
//import axios from "axios";
//import Loader from './components/UI/Loader/Loader';
import Mainadmin from "./containers/Mainadmin/Mainadmin";
import Sets from './components/adm/Sets/sets';
import RoutesHome from "./Routes/routehome";


//class App extends Component {
const App=()=>{
    /*state = {
    };*/
   /* componentDidMount() {

    };*/
    //BrowserHistory.listen(location => dispatch(routeLocationDidUpdate(location)));
    const adm='/'+Sets.adm+'/*';
   /* render() { <Helmhead path={path}/>
        const adm='/'+Sets.adm+'/!*';*/

        return (
<>
                    <Routes>
                        <Route path="/*"  element={<RoutesHome />} />
                        <Route path={adm}  element={<Mainadmin/>} />
                        <Route path="/restore"  element={<Restore/>} />
                        <Route path="/res/new"  element={<Restore/>} />
                    </Routes>
</>
    )

  /*  }*/
}

export default (App);

/*

<Routes>
                        <Route path="/"  element={<LayoutFront/>} >
                            <Route index element={<News path={path} param={{type: 'news', arrPut: ['news'], art: 0}}/>}/>
                            <Route path="*" element={<ParserPath path={path}/>}/>
                        </Route>

                        <Route path="/restore"  element={<Restore/>} />
                        <Route path="/res/new"  element={<Restore/>} />
                    </Routes>


 <Route path={adm}  element={<Mainadmin/>} />

const Home=()=>{       return <h1>Home</h1>};*/
/*
<Routes>
    <Route path="/*"  element={<RoutesHome />} />

    <Route path={adm}  element={<Mainadmin/>} />
    <Route path="/restore"  element={<Restore/>} />
    <Route path="/res/new"  element={<Restore/>} />
</Routes>*/
