import React, {Component} from "react";
import classes from './Authform.module.css';
import Input from '../../components/UI/Input/Input';
import is from 'is_js';
import {connect} from "react-redux";
import {getAdmUserAuth,updateAdmUserError} from "../../store/actions/adminuser";
import {ButtonLong} from "../../components/UI/ButtonLong/Buttonlong";
import axios from "axios";
import {CONST_PREF} from '../../components/adm/Sets/sets';
const pref=CONST_PREF;

class authForm extends Component{
    state={
        isFormValid:false,
        formControls:{
            email:{
                value:'', type:"email", label:'Email',
                errorMessage:'Введите корректный email',
                valid:false,
                touched: false,
                validation:{
                    required:true, email:true
                }
            },
            password:{
                value:'', type:"password", label:'Пароль',
                errorMessage:'Введите корректный пароль',
                valid:false, touched: false,
                validation:{
                    required:true, minLength:4
                }
            }
        },
        timeStamp: Date.now(), errorInput:''
    };
   /* loginHandler= async ()=>{
        const authData={
            email: this.state.formControls.email.value,
            password: this.state.formControls.password.value
        };
        this.props.getAdmUserAuth(authData);
    };*/
    handler=async ()=>{console.log('end',this.state.isFormValid);
        const authData={
            email: this.state.formControls.email.value,
            password: this.state.formControls.password.value
        };
        this.props.updateAdmUserError({status:0,msg:''});
        if(!this.state.isFormValid){this.props.updateAdmUserError({status:1,msg:'filling error email or pass'}); return 0}
       // this.state.isFormValid=true;
        console.log('this.state.isFormValid',authData.email,this.state.isFormValid);
        try {
            const responce = await axios.post(pref+'/api/checklogin', authData);
           // const res = await axios.get('https://geolocation-db.com/json/');
          //  const ip=(res.data.IPv4);
            console.log('answe',responce);
            if(responce.data.isLoad) {
                const ip=responce.data.ip;
                console.log('responce.data',responce.data,ip);
                if(responce.data.error) {this.props.updateAdmUserError({status:1,msg:responce.data.msg});return 0}
                authData.hash=responce.data.hashKey;
                authData.ip=ip;

                this.props.getAdmUserAuth(authData);

            }else{this.props.updateAdmUserError({status:1,msg:responce.data.msg});return 0}
        }catch (e) {this.props.updateAdmUserError({status:1,msg:'Server error2'});return 0;
        }
    }; //to node take date and login generate key/ save to user

    handleSubmit = event => {event.preventDefault();};

    validateControl(value, validation){
    if (!validation) return true;
    let isValid=true;
        if(validation.required){isValid=value.trim()!=='' && isValid}
        if(validation.email){isValid=is.email(value) && isValid}
        if(validation.minLength){isValid=value.length>=validation.minLength && isValid}

    return isValid
}
    onChangeHandler=(event,controlName)=>{
        const formControls={...this.state.formControls};
        const control={...formControls[controlName]};
        control.value=event.target.value;
        control.touched=true;
        control.valid=this.validateControl(control.value, control.validation);
        formControls[controlName]=control;
        let isFormValid=true;
        Object.keys(formControls).forEach(name=>{
            isFormValid=formControls[name].valid && isFormValid
        });
        this.setState({ formControls, isFormValid, timeStamp: Date.now(), errorInput:'' });
    };
    resetButton=()=>{this.setState({ timeStamp: Date.now() })};


    renderInputs(){
        return Object.keys(this.state.formControls).map((controlName, index)=>{
            const control=this.state.formControls[controlName];
            return (
                <Input
                key={controlName + index}
                type={control.type}
                value={control.value} label={control.label}
                valid={control.valid} touched={control.touched}
                shouldValidate={!!control.validation}
                errorMessage={control.errorMessage}
                onChange={event=>this.onChangeHandler(event,controlName)}

                />
            )
        });
    }

    render(){
        return (
            <div className={classes.Authform}>
                <div className={classes.errorblock}>
                {
                    this.props.errorLogin.status
                ? <span>{this.props.errorLogin.msg || 'Введите верное значение'}</span>
                : null
                }
                </div>
                <form onSubmit={this.handleSubmit}>
                    {this.renderInputs()}
                    <ButtonLong stopTime={5} reset={this.state.timeStamp} handler={this.handler} />
                </form>

            </div>
        )
    }


}
// <Button type="primary"
//                     onClick={this.loginHandler}
//                     disabled={!this.state.isFormValid}
//                     >Войти</Button>
function mapStateToProps(state){
    return {
        errorLogin:state.adminuser.errorLogin,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        getAdmUserAuth: obj=>dispatch(getAdmUserAuth(obj)),
        updateAdmUserError: error=>dispatch(updateAdmUserError(error)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(authForm)

