import React from "react";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import Sets from "../../adm/Sets/sets";
import {useLocation} from "react-router-dom";

const Helmhead=props=>{
    const meta = useSelector((state) => state.frontreducer.meta);
    const rasdelQuery = useSelector((state) => state.frontreducer.rasdelQuery);
    const pageArticle = useSelector((state) => state.frontreducer.pageArticle);
    const header = useSelector((state) => state.frontreducer.header);
    const indexCount = useSelector((state) => state.frontreducer.indexCount);
    const error404 = useSelector((state) => state.frontreducer.error404);
    const arrLink=[];
    let path=useLocation();
    //console.log('path path',path);
    let title=''; let page=''; const metArr=[];
    let name=Sets.namesite+': ';
    let urlName='https://colorscript.ru';
    if(error404) {title="404 error"}
    else {
        metArr.push({"name": "og:locale", "content": 'ru_RU'});
        metArr.push({"name": "og:site_name", "content": Sets.namesite});
        metArr.push({"name": "og:url", "content": urlName+''+path.pathname+path.search});

        if (rasdelQuery.art === 0) {
            title=rasdelQuery.type==='search'?name+'Поиск по сайту':name+'Статьи раздела — '+header;
            metArr.push({"name": "title", "content":name+'Статьи раздела — '+header});
            metArr.push({"name": "og:type", "content": 'website'});
            metArr.push({"name": "description", "content": Sets.description});
            metArr.push({"name": "og:description", "content": Sets.description});
            metArr.push({"name": "og:keywords", "content": Sets.keywords});
            metArr.push({"name": "og:image", "content":urlName+Sets.imagemeta });
            metArr.push({"name": "og:image:width", "content": 548});
            metArr.push({"name": "og:image:height", "content": 411});
            metArr.push({"name": "og:image:type", "content": "image/jpg"});
        }
        if (rasdelQuery.art === 1) {
            title=name+meta.title;
            metArr.push({"name": "title", "content":meta.title});
            const sizes=meta.picturesize?JSON.parse(meta.picturesize):'';
            metArr.push({"name": "description", "content": meta.description || Sets.description});
            metArr.push({"name": "og:type", "content": 'article'});
            metArr.push({"name": "og:description", "content": meta.description || Sets.description});
            metArr.push({"name": "og:keywords", "content": meta.keywords || Sets.keywords});
            metArr.push({"name": "og:image", "content":meta.picture?urlName+'/'+ meta.picture :urlName+Sets.imagemeta });
            metArr.push({"name": "og:image:type", "content": "image/jpg"});
            if(meta.picture){
                if(sizes) {
                    metArr.push({"name": "image:width", "content": parseInt(sizes.w)});
                    metArr.push({"name": "image:height", "content": parseInt(sizes.h)});
                }
            }else{
                metArr.push({"name": "image:width", "content": 548});
                metArr.push({"name": "image:height", "content": 411});
            }

        }
    }

    if(!error404) {
        if (rasdelQuery.art === 0) {
            if(rasdelQuery.type==='search'){
                arrLink.push({"rel": "canonical", "href": (props.path.pathname + document.location.search).replace('//', '/')});
            }else{
                page = pageArticle ? '/?page=' + (pageArticle + 1) : '';
                arrLink.push({"rel": "canonical", "href": (urlName+props.path.pathname + page).replace('//', '/')});
                if (pageArticle > 0) {
                    page = pageArticle > 1 ? '/?page=' + pageArticle : '';
                    arrLink.push({"rel": "prev", "href": (urlName+props.path.pathname + page).replace('//', '/')});
                }
                if (indexCount[0] < indexCount[1]) {
                    page = '/?page=' + (pageArticle + 2);
                    arrLink.push({"rel": "next", "href": (urlName+props.path.pathname + page).replace('//', '/')});
                }
            }
        }
        if (rasdelQuery.art === 1) {
            arrLink.push({"rel": "canonical", "href": (urlName+props.path.pathname).replace('//', '/')});
        }
        //arrLink.push({"rel": "icon", "href": "favicon.ico"});
    }
   return <Helmet
       link={arrLink}
       title={title} meta={metArr}
   />
};

export default  Helmhead;