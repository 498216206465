import React from "react";
import classes from '../Drafteditor.module.css';
export const HEADER_TYPES = [
    { label: "(None)", style: "unstyled" },
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "blockquote", style: "blockquote" },
    /*{ label: "H4", style: "header-four" },
    { label: "H5", style: "header-five" },
    { label: "H6", style: "header-six" },*/
    { label: "Code Block", style: "code-block" },
    { label: "Otstup Block", style: "OtstupBlock" }
];

class BlockStyleList extends React.Component {

    onToggle = event => {
        let value = event.target.value;
        this.props.onToggle(value);
    };

    render() {
        const { editorState } = this.props;
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        return (
            <div className={classes.selectblock}>
			<select value={blockType} onChange={this.onToggle}>
				<option value="">Header Levels</option>
                {HEADER_TYPES.map(heading => {
                    return <option value={heading.style} key ={heading.label}>{heading.label}</option>;
                })}
			</select>
                {/*{this.props.delitel}*/}
            </div>
        );
    }
}
export default BlockStyleList;