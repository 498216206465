import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCommentList} from "../../../store/actions/frontActions";
import Comment from "../Comment/Comment";
import classes from './Commentlist.module.css';
import {converEmoji} from "../../adm/Sets/utils";

const defColor=[230,245,252];//'ebf5fc';235,245,252

const CommentList=()=>{
    const dispatch = useDispatch();
    const idArticle = useSelector((state) => state.frontreducer.idcurrentarticle);
    const listComment = useSelector((state) => state.frontreducer.comment);
    const [list,setList]=useState('');
    const [currComm, setCurrComm]=useState(0);
    const [refreshList, setRefreshList]=useState(0);

    const action=useCallback((id)=>{console.log('answer',id,idArticle);setCurrComm(id)},[idArticle]);
    const refreshAction=()=>{setRefreshList(1);setCurrComm(0)};

    useEffect( ()=>{
        dispatch(getCommentList(idArticle));
            setRefreshList(0);
         //   console.log('setRefreshList',refreshList)
    }
    ,[dispatch,idArticle,refreshList]);


    useEffect( ()=>{
            const list=[];
            if(listComment.length){
                listComment.forEach(elem=> {
                    list.push(formatComment(elem,0,action,currComm,refreshAction));
                    if(elem.slaveRec.length){list.push(thread(elem.slaveRec,1,action,currComm,refreshAction))}
                })
            }
            setList(list);
        }
        ,[listComment,currComm,action]);

    return <div><div className={classes.headercomment}>Комментарии:</div>
        <div className={classes.listcom}>{list}</div>
    </div>
};
export default CommentList;

const generateColor=(name)=>{
    let arr= name.split("");
    const colorInsert=[0,0];
    arr.forEach((elem,index)=>{
        let kod=parseInt(elem, 36);
        kod=kod.toString().substring(1,2)
        colorInsert[index]=parseInt(kod) || 15;
    })

    arr= [...defColor];//.split("");
    arr[0]=arr[0]-colorInsert[0];arr[1]=(arr[1]+colorInsert[1]);
    return "RGB(" + arr.join(',')+')';
};

const formatComment=(elem,level,action,currComm,refreshAction)=>{
    let logoTXT=elem.user.substring(0, 2).toUpperCase();
    let color=generateColor(logoTXT);
    let kff=level>3? 3: 1*level;
    let otstup='0px';
    let user=elem.user;
    const commClass=[classes.comment];
    if(elem.vyvod===0) {commClass.push(classes.graycomm)}
    if(level>0) {commClass.push(classes.otstupcomm); 	otstup=`calc(1.5rem + ${kff}rem)`; }

    if(elem.admin>0) {commClass.push(classes.admin);
        logoTXT='A';
        color='rgb(149, 147, 223)';
        user='admin';
    }
   // console.log('emoji converEmoji',converEmoji(elem.text,'from'))
  //  const regex=/(\\u{)(\w*)(})/gm;
 //   const newres=elem.text.replace(regex,function(match,p1, p2){console.log(match,p1, p2)});
  //  console.log('emoji 90',elem.text.replace(regex,function(match){`$2`}))
    const textEmoji=converEmoji(elem.text,'from')
    return <div className={commClass.join(' ')}  style={{marginLeft:otstup}} key={elem.id} >
        <div className={classes.logoshort} style={{backgroundColor:color}}>{logoTXT}</div>
        <div className={classes.nametext}>
            <div className={classes.namedate}><span className={classes.namecomm}><strong>{user}</strong></span>
                <span className={classes.datecomm}><em>{convertDate(elem.data)}</em></span></div>
            <div className={classes.textreply}>
                <div className={classes.textcomm} >{textEmoji}</div>
                {elem.vyvod?
                <div className={classes.replycomm} title={currComm===elem.id?'скрыть':'ответить'}
                                 onClick={()=>{return (currComm===elem.id)?action(0):action(elem.id)}}>{null}</div>
                :null}
                {currComm===elem.id?<div><Comment master={elem.id} def={true} button={refreshAction} timestamp={new Date().getTime()}/></div>:<div>{null}</div>}
            </div>
        </div>
    </div>
}

const thread=(arrElem,level,action,currComm,refreshAction)=>{
    let list=[];
    arrElem.forEach(elem=>{
        list.push(formatComment(elem,level,action,currComm,refreshAction));
        if(elem.slaveRec.length){list=list.concat(thread(elem.slaveRec,level+1,action,currComm,refreshAction))}
    });

    return list;
};

const convertDate=(date)=>{
    const theDate = new Date( Date.parse(date));
    return theDate.toLocaleString();
};