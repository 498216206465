import React, {Component} from "react";
import classes from './AddInnerLink.module.css';
import Buttonpict from "../Buttonpict/Buttonpict";
import {selectTypeElement} from '../../adm/Sets/utils';
import SimpleBar from "simplebar-react";
import 'simplebar/dist/simplebar.min.css';

 class AddInnerLink extends Component{

      createButtons=(type,action)=>{
         switch (type) {
             case 'refresh': return <Buttonpict cls={'refresh'} size={classes.changesize}
                                                key={type+'inner'} onClick={()=>action('refreshDir', null)} title={'Обновить'}/>;
             case 'youtube':
                 return <React.Fragment key={type+'inner'}><div className={classes.video}  ><input  type='text' placeholder={'insert youtube link'}
                                           onChange={(e)=>action('youtubeLinkChange', e)} value={this.props.videoLink}/>

             </div> <Buttonpict cls={'youtube'} size={classes.changesize}
                  onClick={()=>action('youtubeLinkInsert', null)} title={'Вставить youtube фрейм'}/>
             </React.Fragment>;
             default: return null;
         }
     };
     createCatalog=(dirstate)=>{
         const current=dirstate.currentpath && <div className={classes.dirstr} key={dirstate.currentpath}>
             <div className={classes.currentdir} onClick={()=>{this.props.action('upDir', [dirstate.current, this.props.table]);}}
                  title={'На уровень выше'} >{dirstate.currentpath}</div>
                        </div>;
         const dirs=dirstate.dirs && dirstate.dirs.map(elem=>{
             return <div className={classes.dirstr} key={elem.name}>
                 <div className={classes.dir} onClick={()=>{this.props.action('workDir', elem.id);}}
                      title={elem.name}>{elem.name}</div><div className={classes.dirtype}>{'directory'}</div>
             </div>
         });
         const elements=dirstate.elements && dirstate.elements.map(elem=>{
             return <div className={classes.dirstr} key={elem.name}>
                 <div className={classes.file} title={elem.name} >{elem.name}</div>
                 <div className={classes.filetype}>{selectTypeElement(this.props.table,elem.rol)}</div>
                 <div className={classes.selectfile} >
                     <Buttonpict cls={'yes'} size={classes.increasefon} onClick={()=>this.props.action('selectElement', [elem.id,this.props.table])}
                                 title={'Добавить ссылку в статью'}/>
                 </div>
             </div>
         });
         return <div className={classes.dirtbl}>{current}{dirs}{elements}</div>;
     };

     async componentDidMount () {
        let rasdel = localStorage.getItem(this.props.localParam);
        const data={table:this.props.table,rasdel:rasdel ||0,localParam:this.props.localParam};
         await this.props.action('getDir',data);
         //console.log('result',result);
    }
     async componentDidUpdate(prevProps) {
         if(this.props.dirstate.current !== prevProps.dirstate.current || this.props.dirstate.filestamp!==prevProps.dirstate.filestamp) {
             const data={table:this.props.table,rasdel:this.props.dirstate.current,localParam:this.props.localParam};
             await this.props.action('getDir',data);
             console.log('componentDidUpdate','result')
         }
     }

     render() {
         const style=this.props.cls==='hidden'?{display:'none'}:{};
         const buttons=<div className={classes.buttonhead}>{this.props.buttons.map(elem=>this.createButtons(elem,this.props.action))}</div>;
         const tbl=this.createCatalog(this.props.dirstate);

         return (
             <div className={classes.blockaddinnerlink} style={style}>
                 {buttons}
                 <SimpleBar style={{ maxHeight: '30vh' }}>
                 {tbl}
                 </SimpleBar>
             </div>
         )
     }





};

export default AddInnerLink;

