import React, {useEffect,  useMemo, useRef, useState} from "react";
import classes from './Header.module.css';
import {useDispatch, useSelector} from "react-redux";
import {getHeaderMenu} from '../../../store/actions/frontActions';
import {NavLink} from "react-router-dom";
import {setPageArticle, setRepeatQuery, setScrollArt,setRepeatQueryLink} from "../../../store/reducers/frontslice";
import Sets from "../../adm/Sets/sets";
import {ReactComponent as ReactLogo} from '../../../images/logo.svg';
import {ReactComponent as TlgLogo} from '../../../images/telegram.svg';
import Search from "../Search/Search";

const Header=()=>{
    const dispatch = useDispatch();
    const selector = useSelector((state) =>state.frontreducer.headermenu);
    const [prevScroll,setPrevScroll]=useState(window.scrollY);
    const [showButScrol,setButScrol]=useState(0);
    const [classSwitch,setClassSwitch]=useState('');
    const ref = useRef(null);
    const refMenu = useRef(null);
    const buttRef = useRef(null);
    const SearchElem=useMemo(
        ()=> <Search />
    ,[]);
    const makeMenu=(arr)=>{
        return !arr.length?[] // here empty squares
            :arr.map(elem=>{
                let pnkt = '/'+elem.nameurl;
                let icon=elem.icon.length?<img alt={elem.icon[0].name}  src={Sets.remsite + '/' + elem.icon[0].url}/>:null;
                //console.log(elem.icon)
                return   <li  className={classes.lipunkt} key={elem.name}>
                <NavLink to={pnkt} key={elem.name} onClick={()=>{ChangeQueryListing()}}
                         className={(navData) => (navData.isActive ? classes.overactive : classes.over)}>
                    <div className={classes.overicon}>{icon}</div>
                    <div className={classes.overtext}>{elem.name}</div></NavLink>
                </li>
            })
    };
    const ChangeQueryListing=()=>{
        dispatch(setPageArticle({pageArticle:0}));
        dispatch(setRepeatQuery({repeatQuery: 1}));
        dispatch(setRepeatQueryLink({repeatQueryLink: 1}));
        dispatch(setScrollArt({scrollArt: ''}));
        window.scrollTo({top: 0}) //, behavior: "smooth"
    };
    const menu=makeMenu(selector);
    useEffect(() => {
            dispatch(getHeaderMenu());
         },[dispatch]);


    const scrollLenta=()=>{

       // console.log('refMenu.current.',refMenu.current,refMenu.current.scrollWidth,refMenu.current.scrollLeft,refMenu.current.scrollLeftMax,
        //    classSwitch,refMenu.current.offsetWidth)
        // refMenu.current.offsetWidth vidimaya chast
        const leftMax=refMenu.current.scrollWidth - refMenu.current.clientWidth;
        const ostatok=leftMax-refMenu.current.scrollLeft; //refMenu.current.scrollLeftMax
        const scrollPut=refMenu.current.offsetWidth<ostatok?refMenu.current.scrollLeft+refMenu.current.offsetWidth
            :refMenu.current.scrollLeft+ostatok;
        if(refMenu.current.scrollLeft>=(leftMax-2)) //.scrollLeftMax
        {refMenu.current.scrollTo({ top: 0, left: 0, behavior: 'smooth'});
           // buttRef.current.classList.add(classes.left);
            setClassSwitch('')
        }else {

            refMenu.current.scrollTo({top: 0, left: scrollPut, behavior: 'smooth'});
            if(refMenu.current.offsetWidth>ostatok) setClassSwitch(classes.left);

           // buttRef.current.classList.remove(classes.left);
        }
    }

    const buttScrollMenu =showButScrol? <div className={classes.scrollelement} ref={buttRef}>
        <button className= {[classes.buttscrollelement,classSwitch].join(' ')} id= {"scrolllenta2"} onClick={scrollLenta}></button>
    </div>: null;

    const loadHeader=()=>{
        if(refMenu.current.offsetWidth<refMenu.current.scrollWidth) {
            setButScrol(1)}else{setButScrol(0)}
    }

    useEffect(() => {
        const changeSizeMenu = () => {
           // console.log('refMenu.current.2',refMenu.current,refMenu.current.scrollWidth,refMenu.current.scrollLeft,refMenu.current.scrollLeftMax)
            if (!showButScrol){ if(refMenu.current.offsetWidth<refMenu.current.scrollWidth) setButScrol(1)}
             else{ if(refMenu.current.offsetWidth+buttRef.current.clientWidth-15 >= refMenu.current.scrollWidth)
                 setButScrol(0)}
        };
        window.addEventListener('resize', changeSizeMenu);
        return () => {
            window.removeEventListener("resize", changeSizeMenu);
        };
    },[showButScrol]);

    useEffect(() => {
        const onScroll = () => {
            const temp = window.scrollY;
            const winWidth = window.innerWidth;
            if (temp > prevScroll && prevScroll>55 && winWidth<801){ref.current.classList.add(classes.header_hidden)}// console.log('up',temp,prevScroll);
            if (temp < prevScroll || (temp > prevScroll && winWidth>800)) {ref.current.classList.remove(classes.header_hidden)}//console.log('down',temp,prevScroll);
            setPrevScroll(window.scrollY)
        };
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    },[prevScroll]);
    return <div className={classes.headerup} ref={ref}>
        <div className={classes.header} ref={refMenu} onLoad={loadHeader}>
        <div className={classes.logo}><NavLink to={'/'}  className={classes.logoback} onClick={()=>{ChangeQueryListing()}}>
            <ReactLogo/></NavLink></div>

        <nav className={classes.headernav}>
            <ul id="menu" className={classes.menu}>
                {menu}
            </ul>
        </nav>
        <div className={classes.asideheader}>
            <div className={classes.tlglogo}>
                <a className={classes.buttonpanel} href="https://t.me/colorscript" title={'Перейти в телеграм-канал'} target="_blank"
                   rel="noopener noreferrer"><TlgLogo/></a>
            </div>
            {SearchElem}</div>
        </div>
        {buttScrollMenu}
    </div>;
};

export default Header;