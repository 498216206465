import React, {} from "react";
import classes from './CommentAdmin.module.css';
import {CheckboxRound,Checkbox} from "../Inputable/Inputable";
import Buttonpict from "../Buttonpict/Buttonpict";
import AddCommentAdm from'./AddComment';
import {converEmoji} from "../../adm/Sets/utils";
//const defColor=[230,245,252];//'ebf5fc';235,245,252

export function formatRecords(state,handler){
    const {list,users,editcomm,editCommHeight}=state;
    const res=list.map(elem=>{
        let kff=elem.level>3? 3: 1*elem.level; let otstup='0px';
        const commClass=[classes.comment];
        const buttonClass=[classes.vyvod];
        if(elem.delete) commClass.push(classes.markdelete);
        if(elem.delete || elem.edit) buttonClass.push(classes.always);
        const offsetClass=[classes.nametext];
        if(elem.level>0) {offsetClass.push(classes.otstupcomm); 	otstup=`calc(1.5rem + ${kff}rem)`; }
        if(elem.admin>0) {commClass.push(classes.admin);}
        if(!elem.vyvod) {commClass.push(classes.novyvod);}
        let user=users.find(user=>user.id===elem.user);
        if (user===undefined){user={};user.name='missing';user.id=0;user.ip='';user.stopip=false; user.stopdevice=false;}
        const textEmoji=converEmoji(elem.text,'from')
       // console.log('editcomm',editcomm)

        const showButton=()=> {
            let res=<> <Checkbox field={elem.vyvod} title={'Скрыть/показать'}
                             handler={() => handler({status: 'hiddencomm', id: elem.id})}/>
                <Buttonpict cls={'note'} onClick={() => handler({status: 'editcomm', id: elem.id,field:'edit'})} disabled={false}
                            title={'edit'}/>
                <Buttonpict cls={'markdelete'} onClick={() => handler({status: 'markdelcomm', id: elem.id, field:'delete'})}
                            disabled={false} title={'mark delete'}/></>;
            if(elem.delete) {
                res=<> <Buttonpict cls={'delete'} onClick={() => handler({status: 'deletecomm', id: elem.id})} disabled={false}
                            title={'delete'}/>
                    <Buttonpict cls={'cancel'} onClick={() => handler({status: 'cancel', id: elem.id,field:'cancel'})} disabled={false}
                                title={'отмена'}/>
                    </>
                }
            if(elem.edit) {
                res=<> <Buttonpict cls={'save'} onClick={() => handler({status: 'savecomm', id: elem.id})} disabled={false}
                                   title={'save'}/>
                    <Buttonpict cls={'cancel'} onClick={() => handler({status: 'cancel', id: elem.id,field:'cancel'})} disabled={false}
                                title={'отмена'}/>
                </>
            }


            return <div className={buttonClass.join(' ')}>{res}</div>
        };

        const resShowButton=showButton();

        return  <div className={commClass.join(' ')}  key={elem.id} >

            <div className={classes.checkadm}><CheckboxRound field={elem.check} title={'Отметить'}
                                                     handler={()=>handler({status:'checkcomm',id:elem.id})}/></div>

            <div className={offsetClass.join(' ')} style={{marginLeft:otstup}} >
                <div className={classes.namedate}>
                    <div className={classes.namecomm}><strong>{elem.admin?'admin':user.name || 'missing'}</strong></div>
                    {!elem.admin?
                        <>
                            <Checkbox field={user.stopdevice} title={'Заблокировать юзера'}
                                                                     handler={()=>handler({status:'lockuser',id:user.id})}/>
                            <div className={classes.namecomm}>{user.ip}</div>
                            <Checkbox field={user.stopip} title={'Заблокировать ip'}
                              handler={()=>handler({status:'lockip',id:user.id,ip:user.ip,stopip:user.stopip})}/>
                        </>:null}
                    <div className={classes.datecomm}><em>{elem.data}</em></div>
                </div>
                <div className={classes.textreply}>
                    {elem.edit?
                        <textarea  className={classes.textareacomm}  style={{height:(editCommHeight)}} spellCheck={false}
                             onChange={(e)=>handler({status:'edittext',txt:e.target.value,target:e.target})}
                                   onFocus={(e)=>handler({status:'edittext',txt:e.target.value,target:e.target})}
                             value={editcomm}/>
                        :  <div className={classes.textcomm}>{textEmoji}</div>
                    }

                    <div className={classes.replycomm} title={elem.showreply?'скрыть':'ответить'}
                         onClick={()=>{handler({status:'showreply',id:elem.id})}}>{null}</div>
                    {elem.showreply?<div><AddCommentAdm handler={handler} master={elem.id} field={'reply'}/></div>:<div>{null}</div>}
                </div>
            </div>
            {resShowButton}
        </div>
    })

    return res;
}