import React from 'react';
import classes from './ButtonGroup.module.css';

class ButtonGroup extends React.Component {

    state ={current:'', styleCurrent:{},listStyles:[], list:[], style:{}, switcher:false,keysStyles:[]};

    currentClick=()=>{ this.props.handler(this.state.current.action);
        //console.log('current:', this.state.current)
    };

    selectButton=async (e)=>{
        const current=this.state.list[parseInt(e.target.getAttribute('name'))];
        const styleCurrent=current.style;
        await this.setState({current,styleCurrent,switcher:!this.state.switcher});
        this.currentClick();
        //this.setState({current:e.title})

    };
    toggleList=(e)=>{const target=e.target;
        const switcher= this.state.switcher;
        this.props.closer('tip');
        const c = target.getBoundingClientRect();
        if (!switcher) {
            const newstyle={...this.state.style};
            newstyle.top=target.offsetTop+23; newstyle.left=c.left-c.width-this.props.offsetList;
            newstyle.position='absolute';
            this.setState({style:newstyle, switcher:!switcher})
        } else {
            this.setState({switcher:!switcher})
        }
//console.log('coords',c)
//const top=target.
    };
    componentDidMount(){
       // console.log('ghfghfg',this.props.listButtons);
        if(this.props.listButtons) {
            const current=this.props.listButtons[this.props.current];
            const styleCurrent=current.style;
            this.setState({current,styleCurrent,list:this.props.listButtons})
        }
        const keysStyles=this.props.reactStyleMap?Object.keys(this.props.reactStyleMap):null;
        const listStyles=this.props.listButtons?this.props.listButtons.map((elem)=>elem.name):null;
        this.setState({keysStyles,listStyles});

    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.colorListSwitch!==this.props.colorListSwitch && this.state.switcher){
            this.setState({switcher:!this.state.switcher});
        }
        if(prevProps.editorState!==this.props.editorState){
            if(this.props.getInlineStyle) {
                const arrStyles=this.props.getInlineStyle(this.props.editorState);
                let result='';
                arrStyles.forEach((value)=>{
                    const temp=this.state.keysStyles.find((element)=>{
                        return element===value;
                });
                    result=temp || result;
                });
                if(result) {
                    const currentElement = this.state.listStyles.findIndex((element) => {
                        return element === result;
                    });
                    if(currentElement>-1) {
                        const current = this.state.list[currentElement];
                        const styleCurrent = current.style;
                        this.setState({current, styleCurrent});
                    }

                }else{const current = this.state.list[0];
                    const styleCurrent = current.style;
                    this.setState({current, styleCurrent});
                }
            }
        }
    }

    render(){
        const styles=this.state.styleCurrent || null;
        let rasmerCur;
        if(this.state.current.name){
             rasmerCur=(this.state.current.name.indexOf('BACK')===-1)?this.props.listButtonSize[0]:this.props.listButtonSize[1];
        } else{rasmerCur=styles;}

        const newStyleCur={...styles,width: rasmerCur+'px', height:rasmerCur+'px'};
        const groupButton=(<div className={classes.groupbutton}>
            <div className={classes.current} style={newStyleCur} title={this.state.current.name? this.state.current.name.toLowerCase(): null} onClick={this.currentClick}></div>
            <div className={classes.currentlist}  onClick={this.toggleList}></div>
        </div>);
        const listbutton=this.state.list.map((item,i)=>{
            const rasmer=(item.name.indexOf('BACK')===-1)?this.props.listButtonSize[2]:this.props.listButtonSize[3];
            const newStyle={...item.style,width: rasmer+'px', height:rasmer+'px'};
            return (<div className={classes.listbutton} onClick={this.selectButton} key={i} name={i} style={newStyle} title={item.name.toLowerCase()}>{item.title}</div>)
        });
        const switcher= this.state.switcher;
        const style=this.state.style;
        return (
            <div className={classes.inlineblock}>
                {groupButton}
                {switcher ?
                    <div className={classes.Listgroupbutton} style={style}>
                        {listbutton}
                    </div>
                    : null
                }
            </div>
        );

    }
}

export default ButtonGroup;