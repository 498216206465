import React from "react";
import classes from './Buttoncom.module.css';

const Buttoncom=props=>{
    const arrButt=props.buttons;
    const localFunc=props.localFunc;
    const handler=props.handler;
    const checkData=props.checkData ;
    return arrButt.map((button,index)=>{
        const checker=checkData[button.type] || null;
        const func=localFunc[button.type] || handler;
        const action=actionButton(func,button.type,props.tbl,checker);
        return getButton(button,action,index);
    })
};
function getButton(button,actionButton,index) {
    const nameKey = Object.keys(button);
    const cls=[classes.buttoncom,classes[button.type]];
    return (<button key={`${nameKey}-${index}`} className={cls.join(' ')}
                    onClick={actionButton} title={button.title}>{null}</button>);

}

function actionButton(handler, type,tbl,checker){
    let param;
    switch(type){
        case 'editrecord': param='edit';  break;
        case 'deleterecord':  param='delete';  break;
        case 'markdeleterecord':  param='delete';  break;
        case 'canceledit':  param='cancel';  break;
        case 'saverecord':  param='save';  break;
        case 'switchon':  param=0;  break;
        case 'switchoff':  param=1;  break;
        case 'sortleft':  param=-1;  break;
        case 'sortright':  param=1;  break;

        default: return param='non action'
    }
    return (e)=>{
        const { target } = e;
        const checkData=checker ?checker():null;
        if(checkData && checkData.error){
            handler({table: tbl, id: target, name: checkData.message, status: 'erroraction'});
        }else {
            handler({table: tbl, id: target, name: param, status: type});
        }
    };
}

export default Buttoncom;