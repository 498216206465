import React from "react";
import {Route, Routes, useLocation} from 'react-router-dom';
import Rasdel from '../containers/Rasdel/Rasdel';
//import Setting from '../containers/Settings/Setting';
//import Sets from '../components/adm/Sets/sets';
import {HocSetting, HocTag, HocUser,HocIcon,HocCommUsers} from '../containers/Hoctable/Hoctable'
//import Resetable from '../hoc/Resetable/Resetable';
//import Parserpath from "../components/adm/Parserpath/Parserpath";

const RoutesAdm=props=>{
    const adm='';//'/'+Sets.adm;
    const arrLink={
        rasdel:adm+"rasdel/*",
        rasdel1:adm+"/rasdel/:id",
        rasdel2:"/:id",
        setting:adm+"setting",
        tag:adm+"tag",
        icon:adm+"icon",
        user:adm+"user",
        commusers:adm+"commusers"

    };
//const ResetRasdel=Resetable(Rasdel); <Route  component={Setting} props={admBlocker}
    let path=useLocation();
    return(
        <Routes>

            <Route  path={arrLink.rasdel}  element={<Rasdel location={path}/>}/>
            <Route  path={arrLink.tag}  element={<HocTag />}/>
            <Route  path={arrLink.setting}  element={<HocSetting />}/>
            <Route  path={arrLink.icon}  element={<HocIcon />}/>
            <Route  path={arrLink.user}  element={<HocUser />}/>
            <Route  path={arrLink.commusers}  element={<HocCommUsers />}/>


    </Routes>
    );

    //<Route path={arrLink.rasdel}  exact render={props => (<Rasdel {...props} key={Math.random()} />)}/>
    // <Route path={arrLink.rasdel} exact component={Rasdel} />
    //             <Route path={arrLink.rasdel1}   component={Rasdel} />
};

export default  RoutesAdm;

