//Add Link Component
import React from "react";
import {  EditorState, Modifier,RichUtils } from "draft-js"; //CompositeDecorator

const Link = ({ entityKey, contentState, children }) => {
    let { url } = contentState.getEntity(entityKey).getData();
    return (
        <a  style={{ color: "blue", fontStyle: "italic" }}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
        >{children}</a>
    );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "LINK"
        );
    }, callback);
};
const LinkInner = ({ entityKey, contentState, children }) => {
    let { url } = contentState.getEntity(entityKey).getData();
    return (
        <a  style={{ color: "red", fontStyle: "italic" }}
            href={url}  >
            {children}
        </a>
    );
};
const findLinkInnerEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "LINKINN"
        );
    }, callback);
};

/*const hrEntity = () => {
    return (<hr/>);
};
const findHrEntity = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "HORIZONTAL_RULE"
        );
    }, callback);
};*/

/*export const decoratorHr=new CompositeDecorator([{
    strategy: findHrEntity,
    component: hrEntity,
}]);*/
            /*export const createLinkDecorator2 = () =>
                new
                CompositeDecorator
                ([
                    {=
                        strategy: findLinkEntities,
                        component: Link,
                    },
                    {
                        strategy: findLinkInnerEntities,
                        component: LinkInner,
                    }
                ]);*/

export const createLinkDecorator =
    [{
        strategy: findLinkInnerEntities,
        component: LinkInner,
    },
        {
            strategy: findLinkEntities,
            component: Link,
        },
        /*{
            strategy: findHrEntity,
            component: hrEntity,
        }*/
    ];

/*export const onAddHRline = (editorState, setEditorState) => {
    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const createEntity = currentContent.createEntity("HORIZONTAL_RULE", "IMMUTABLE");
    let entityKey = currentContent.getLastCreatedEntityKey();

    const textWithEntity  = Modifier.applyEntity(
        createEntity,
        selection,
        entityKey,
    );
    let newState = EditorState.createWithContent(textWithEntity);
    setEditorState(newState);
    return "handled";
};*/

// call all together
export const onAddLink = (editorState, setEditorState,linkUrl,status) => {
    const selection = editorState.getSelection();

  //  const contentState = editorState.getCurrentContent();
  //  const startKey = editorState.getSelection().getStartKey();
  //  const startOffset = editorState.getSelection().getStartOffset();
  //  const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
  //  const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
   // let url = "";
    /*if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
    }
    console.log('url',url);
    let linkUrl = window.prompt("Add link http:// ",url); //'http://localhost:3000/';//*/

   // const decorator = createLinkDecorator();
    if (!linkUrl) {
        setEditorState(RichUtils.toggleLink(editorState, selection, null));
        return "handled";
    }
    if (linkUrl) {
        //let displayLink = window.prompt("Display Text");
        if (1) {
            const currentContent = editorState.getCurrentContent();
           // const createEntity = currentContent.createEntity("LINK", "MUTABLE", { url: linkUrl,})
            const createEntity = status ? currentContent.createEntity("LINK", "MUTABLE", { url: linkUrl,})
            : currentContent.createEntity("LINKINN", "MUTABLE", { url: linkUrl,});

            let entityKey = currentContent.getLastCreatedEntityKey();

            const textWithEntity  = Modifier.applyEntity(
                createEntity,
                selection,
                entityKey,
            );
           // let newState = EditorState.createWithContent(textWithEntity, decorator);
            let newState = EditorState.createWithContent(textWithEntity);
            setEditorState(newState);
            return "handled";

        }
    }
};
export const getLink = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    let url = {};
    if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = {url:linkInstance.getData().url,type:linkInstance.type};
       // console.log('linkKey',linkInstance.type);
    }
    return url;
};
