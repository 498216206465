import React, { useState, useEffect, useRef } from "react";
import classes from './Buttonlong.module.css';

export const ButtonLong = (props) => {
    const [seconds, setSeconds] = useState(0);
    const [stoper, setStop] = useState(false);
    const [stopTime, setStopTime] = useState(0);
    const [coda, setCoda] = useState(0);
    const [classDone,setClassDone]=useState('');
    const intervalRef = useRef();
    const handler=props.handler;
    useEffect(() => {setStopTime(props.stopTime);setSeconds(0); setCoda(0); setClassDone('')
    },[props.stopTime,props.reset]);
    useEffect(()=>{if(coda) handler(); },[coda,handler]);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (stoper && seconds < stopTime) {
                setSeconds((sec) => sec + 1);
            }else{clearInterval(intervalRef.current); }}, 100);
        return () => clearInterval(intervalRef.current);
    }, [stoper,seconds,stopTime]);

    const cancelInterval = () => {
            clearInterval(intervalRef.current);
        if(seconds===stopTime & stopTime>0 && !coda) {setCoda(1);
            setClassDone('active') ; }
     //   console.log(seconds,stopTime,coda)
    };
    const toggle=(val)=>{
        setStop(val); clearInterval(intervalRef.current);
        if(!val && seconds<stopTime && seconds>0){setSeconds(0)}

    };

    cancelInterval();
    const fill=100/parseInt(props.stopTime)*seconds +'%';
   // console.log(seconds,stopTime,coda);
    return (
        <div >
            <div className={classes.mainblock} onMouseDown={()=>toggle(true)} onMouseUp={()=>toggle(false)} onMouseOut={()=>toggle(false)}
                 onTouchStart={()=>toggle(true)} onTouchEnd={()=>toggle(false)}
            >  <div className={[classes.inner,classDone?classes[classDone]:''].join(' ')} style={{width:fill}}>{null}</div>
                <div className={classes.innertext}
                ><span>Press & Hold</span></div>

            </div>
        </div>

    );
};


