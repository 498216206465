import React,{useEffect} from "react";
import Sets from "../../adm/Sets/sets";
import classcom from "../../UI/Styles/Stylecommon.module.css";
import classes from "./Blog.module.css";
import {iconArt,iconRasdel} from "../../UI/Iconart/iconart";
import {useDispatch} from "react-redux";
import {setOverlay, setOverlayImg, setScrollArt, setUrlSearch} from "../../../store/reducers/frontslice";
import Comment from "../Comment/Comment";
import CommentList from "../Commentlist/Commentlist";
import MyIframe from "../../UI/MyFrame/MyFrame";
import {converEmoji, createVideoFrame} from "../../adm/Sets/utils";
import {Link} from "react-router-dom";
import {makeSearchURL} from "../Search/SearchPanel";
import {useNavigate} from "react-router-dom";
import Imgonload from "../../UI/Imgonload/Imgonload";

export const FullArticle=({id,objArt,type,forlink})=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
  //  const [windowSize, setWindowSize] = useState({  width: undefined,     height: undefined,   });
    const openPicture=(overlayImg)=>{dispatch(setOverlay({overlay:1})); dispatch(setOverlayImg({overlayImg})); };

    const goToSearch=()=>{dispatch(setUrlSearch({urlSearch:1}));dispatch(setScrollArt({scrollArt: ''}));};
    const goToRasdel=()=>{dispatch(setScrollArt({scrollArt: ''}));};

        const site=Sets.remsite+'/';
        const objAlign={left:'flex-start',right:'flex-end',center:'center'};
        const blocks=objArt.text.map((elem,index)=>{
            switch(elem.type){
                case 'text': return (<div key={id+'-b-'+index} className={classcom.articlebody}
                                          dangerouslySetInnerHTML={createMarkup(converEmoji(elem.text,'from'))}/>);
                case 'image': const note=elem.note && elem.note.length? <div className={classcom.noteimage}>{elem.note}</div>
                    :<div className={classcom.emptynote}>{null}</div>;
              //  console.log('elem.link',elem.link,site)
                    const link=site+elem.link;
                    const srcsetImage=elem.type==='image' && elem.arrNames?elem.arrNames[1] || elem.arrNames[0]:null;
                    const srcImage=elem.type==='image' && elem.arrNames? elem.arrNames[0]:null;
                    const align=elem.align ?{alignSelf: objAlign[elem.align]}:null;
                    return  <div className={classcom.imagemain} style={align} key={id+'-b-'+index}> {/*<img src={link} alt={''}/>*/}

                        <Imgonload alt={''} src={link+srcImage} srcSet={[[link+srcsetImage,"(max-width: 800px)"]]}
                                   type={'picture'} params={{radius:'10px',count:1,fill:true,height:'9rem',width:'80vw'}}
                                   clicker={()=>openPicture({link:link+srcImage,srcSet:[[link+srcsetImage,"(max-width: 800px)"]],
                                       note:elem.note})}/>
                        {/*<picture onClick={()=>openPicture({link:link+srcImage,srcSet:[[link+srcsetImage,"(max-width: 800px)"]],note:elem.note})} >
                            <Imgonload alt={''} src={link+srcsetImage} media={"(max-width: 800px)"}
                                       type={'source'} params={{height:'9rem',width:'99%'}}/>
                            <source srcSet={link+srcsetImage} media="(max-width: 800px)"/>
                            <Imgonload alt={''} src={link+srcImage} type={'img'} params={{height:'9rem',width:'99%'}}/>
                            <img src={link+srcImage} alt=""/>
                        </picture>*/}

                        {note}
                    </div>;
                case 'youtube':
                    const videoCrop=elem.option==='16'?classcom.videoWrapper16:classcom.videoWrapper4;
                    const videoPolbloka=elem.option==='16'?classcom.polbloka16:classcom.polbloka4;
                    return <div className={videoPolbloka}  key={id+'-b-'+index}>
                        <div className={videoCrop}>
                            {createVideoFrame(elem)}</div></div>;

                case 'element':
                    return <div key={id+'-b-'+index}>{selectElement(elem.text)}</div>;
                default: return null;
            }
        });
        let artHeader=null;
        let artDate=null;
        let tags=null;
        if(type==='one'){
            const {namearticle,createDate,iconrasdel,iconart}=objArt.headers;
            if(createDate) {
                const msec=Date.parse(createDate);
                const objDate=new Date(msec);
                artDate=<div className={classes.datecreated}><div>{objDate.getDate()+'/'+ (objDate.getMonth()+1) + '/'+ objDate.getFullYear()}</div></div>;
            }
            let arrPutMenu= [...objArt.headers.putRasdel];
            arrPutMenu.shift();
            const rasdOut=iconrasdel.length?iconRasdel(objArt.headers,arrPutMenu.join('/'),goToRasdel):null;
            const artOut=iconart.length?iconArt(objArt.headers,goToSearch):null;
           // const iconrasdel =""//iconRasdel(iconRas,'/');
            artHeader=<div className={classes.headerblockdown}>
                    <div className={classes.iconblock}>{rasdOut}</div>
                    <h1 className={classes.headercenter}>{namearticle}</h1>
                    <div className={classes.iconartinblock}>{artOut}</div>
            </div>;
        }
    const tagart=objArt && objArt.tagart? objArt.tagart : objArt.headers? objArt.headers.tagart:null;
    if(tagart && tagart.length){tags=<div className={classes.tagblock}><span>tеги:</span>{tagart.map(tag=>{
                 const url=makeSearchURL({tagArray:[tag.id],iconArray:[],wordArray:[]});
            return  <Link to={url.pathname+url.search} key={tag.name+'-'+tag.id} title={tag.note}>{tag.name}</Link>})}</div>
    }

    useEffect(()=>{
            const checkSender = (e) => {
                // console.log ('e.data.msg',e.data);
                if(e.data.location) setIframeHeight(e.data.height, parserStr(e.data.location));
                if (e.data === 'hello')  console.log ('e.data.msg',e.data);

            };
            function handleResize() {
                let iframes=document.getElementsByTagName('iframe');

               // setWindowSize({width: window.innerWidth, height: window.innerHeight,});
                for(let i=0;i<iframes.length;i++){
                    iframes[i].contentWindow.postMessage('hello', '*');
                }
            }
            if (window.addEventListener) {
                window.addEventListener('message', checkSender);
                window.addEventListener("resize", handleResize);
            } else if (window.attachEvent) {
                window.attachEvent('message', checkSender)
                window.attachEvent("resize", handleResize);
            }
            handleResize();
            return () =>  {window.removeEventListener('message', checkSender);
                window.removeEventListener("resize", handleResize);}
        },[]    );

    useEffect(()=>{
        const innerClicker=(e)=>{
            if (e.target.tagName === 'A') {
                if(e.target.className===classcom.linkinn) {
                    e.preventDefault();
                    let current=window.location.origin;
                    const url = new URL(current+e.target.pathname);
                    navigate(e.target.pathname);
                }
            }
        };
        if (window.addEventListener) { window.addEventListener('click', innerClicker);
        }else if (window.attachEvent) { window.attachEvent('click', innerClicker)}
        return () =>  {window.removeEventListener('click', innerClicker);
            window.removeEventListener("click", innerClicker);}
    },[navigate]);


    const setIframeHeight = (val,id) => {
        try {
            let iframe = document.getElementById(id);
            if(iframe)  iframe.style.height = val+20+'px'
        } catch (e) {console.error('Resizing method call', e)}
    };
    const comments= type==='one'?<><CommentList /><Comment master={0} def={false}  /></>
        :<div className={classes.commcount}><Link to={forlink} >comments:{objArt.commcount}</Link></div>;
    return (
        <div id={'block_'+id} key={'block_'+id} className={classes.articlebody}>
            {artHeader}{artDate}{blocks}{tags}
            {comments}
        </div>
    )

};

const parserStr=(str)=>{
    let arr=str.split('/');
    arr=arr[arr.length-1].split('.');
    return arr[0];
}

const selectElement=(text)=>{
    let txt='---im---ag-ing';
    const site=Sets.remsite+'/images/';
    let reg=/^http/; // google drive
    try {
        const newObj = JSON.parse(text);
        const url=reg.test(newObj.url)?newObj.url:site+newObj.url;
        const note=newObj.note || `Скачать ${newObj.name}`;
        const size=newObj.parametres.size?  "("+Math.round(parseInt(newObj.parametres.size)/1024/10.24)/100+'Mb)' : null;
        //const time=newObj.parametres.time?  "("+makeTime(newObj.parametres.time)+')' : null;
        switch(newObj.type){
            case 'frame':
                let arr=newObj.url.split('/'); arr=arr[arr.length-1].split('.');
                txt=<MyIframe  id={arr[0]} src={url}/>;
                break;
            case 'Архив': txt=<div className={classcom.archive}><span className={classcom.icon}>{null}</span>
                <a className={classcom.link} href={url} target="_blank" title={note}
                   rel="noopener noreferrer">{newObj.name} {size}</a>
            </div>;
                break;
            case 'PDF': txt=<div className={classcom.pdf}><span className={classcom.icon}>{null}</span>
                <a className={classcom.link} href={url} target="_blank" title={note}
                   rel="noopener noreferrer">{newObj.name} {size}</a>
            </div>;
                break;
            default: break;
        }
    }catch (e) { return txt; }
    return txt;
};

const createMarkup=(html)=> {
    return {__html: html}};

