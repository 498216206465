import React, {Component} from "react";
import {NavLink} from 'react-router-dom';
import classes from './Mainmenu.module.css';
import Sets from '../Sets/sets';
//import { withRouter } from 'react-router-dom';

class Mainmenu extends Component {
    menu= ()=> {
        const names = Sets.namesmenu;
        const adm = Sets.adm+'/';
        return this.props.menulist.map((punkt, index) => {
            let pnkt = '/'+adm+punkt;
            let name = names[punkt];
            if(name===undefined) return false;
            return (<li key={index}>
                    <NavLink to={pnkt}
                             className={(navData) => (navData.isActive ? classes.active : null)}
                    >{name}</NavLink>
                </li>
            )
        })
    }
        render(){
            return(
                <nav className={classes.mainmenu}>
                    <ul>
                        {this.menu()}
                    </ul>
                </nav>
            )
        }
}

export default (Mainmenu);