import React, {Component} from "react";
import { createPortal } from 'react-dom';
import classes from './CommentAdmin.module.css';
import Emoji from "../Emoji/Emoji";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";

class AddCommentAdm extends Component{
        state={text:'',height:'auto',changer:0};

        enter=(e=>{this.setState({text:e.target.value});
            this.multilineTextarea.style.height = 'auto';
            this.multilineTextarea.style.height = this.multilineTextarea.scrollHeight+3 + 'px';
        });
     wrapperRef=React.createRef();
     Clicker=()=>{this.setState({changer:!this.state.changer}); console.log('clicker')};
    EmojInput=(obj)=>{
        this.setState({text:this.state.text+' '+obj.native,changer:0});};

     handleClickOutside=(e)=> {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
            this.setState({changer:0})
        }
    }

    componentDidMount() {
        if (this.multilineTextarea) {
            this.multilineTextarea.style.height = 'auto';
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside)
    }
//sidehead id -- article
    render(){
        const textbutton=this.props.master?'add comm':'add new comm';
        const emogiHide=this.state.changer?classes.hider:'';
        return <div className={classes.answerblock}>
            <textarea ref={ref => this.multilineTextarea = ref} className={classes.answertar} value={this.state.text}
                      onChange={this.enter} style={{ height: this.state.height}}
            />
            <div className={[classes.emojiicon,emogiHide].join(' ')} onClick={this.Clicker} title={'эмоджи'}>
                <span  role={"img"}><Emoji label="Smile" symbol="😁" /></span></div>

            {this.state.changer?
                createPortal( <div className={classes.emojiblock} >
           <span ref={this.wrapperRef}>
           <Picker data={data} onEmojiSelect={this.EmojInput} previewPosition={'none'}
                   navPosition={'bottom'} searchPosition={'top'}/>
            </span>
                </div>,document.body)
                : null}

            <button className={classes.answerbutton}
                    onClick={()=>this.props.handler({status:'addcomm',text:this.state.text,master:this.props.master, field:this.props.field
                    })}>{textbutton}</button>
        </div>
    }
}

export default AddCommentAdm;