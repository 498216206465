import React from "react";
import classes from './RAsdelrow.module.css';
import Rasdelrow from "./Rasdelrow";
import Rasdelrowedit from "./Rasdelrowedit";
//import {buttonsForAction} from '../../Sets/utils';
import ButtonsTd from "../../../UI/Buttonrow/Buttonrow";


const rasdelRows=props=>{
    const heads=props.heads;
    const admBlocker=props.admBlocker;
    const fullpath=props.fullpath;
    const handler=props.checkerHandler;
    const buttonsRow=props.buttonsRow;
    const buttForView=buttonsRow.buttForView;
    const buttForViewCancel=buttonsRow.buttForViewCancel;
    const buttForActions=buttonsRow.buttForActions;
    const buttForActionsCancel=buttonsRow.buttForActionsCancel;

    const butView=(<ButtonsTd buttons={buttForView} handler={handler}  tbl={props.tbl}/>);
    const butViewCancel=(<ButtonsTd buttons={buttForViewCancel} handler={handler}  tbl={props.tbl}/>);
    //const butAction=(<ButtonsTd buttons={buttForActions} handler={handler}  tbl={props.tbl} />);
    return props.fields.map((obj,index)=>{
        const cls=[classes.rasdelrow];
        const selectButton=obj.delete ? butViewCancel : butView;
        const selectButtonAction=obj.delete ? buttForActionsCancel : buttForActions;
        const keyTr=`${obj.id}-${obj.updatedAt}`; // todo updatedAt - absent
        if(obj.delete)  cls.push(classes.delete);
        return (

            <tr className={cls.join(' ')} key={keyTr} id={obj.id}>
                {obj.edit
                    ? <Rasdelrowedit fields={obj}
                                 heads={heads}
                                 checkerHandler={handler}
                                 tbl={props.tbl}
                                 button={selectButtonAction}
                                 statuspicture={props.statuspicture}
                                 admBlocker={admBlocker}
                    />
                    :    <Rasdelrow fields={obj}
                                     heads={heads}
                                     checkerHandler={handler}
                                     tbl={props.tbl}
                                     button={selectButton}
                                     fullpath={`${fullpath}/${obj.nameurl}`}
                                     statuspicture={props.statuspicture}
                        />
                }
           </tr>

        )
    });
};

export default rasdelRows;