import React from "react";
import classes from './Inputable.module.css';
import Sets from '../../adm/Sets/sets'
import {complementColor} from '../../adm/Sets/utils'

function isInvalid({valid, touched}){ return !valid && touched }
function checkBoxClass(field){return classes[!!field ?'class-true':'class-false']}

export const InputTbl =  props=>{
    const clsinput=[classes.Inputfield];
    if (isInvalid(props.data)){ clsinput.push(classes.invalid)}
     return (<input className={clsinput.join(' ')} onChange={props.handler} value={props.data.value || ''}/>);
};

export const DivDef=props=>{
    return (<div className={props.cls ? props.cls :null}>{props.field}</div>)
};
export const DivCheckbox=props=>{
    const cls=checkBoxClass(props.field);
    return (<div className={cls}>{null}</div>);
};
export const DivCheckboxDisable=props=>{
    const cls=classes.disable;
    return (<div className={cls}>{null}</div>);
};
export const Checkbox=props=>{
    const cls=classes[!!props.field ?'class-check':'class-nocheck'];
    const title=props.title ||null;
    return (<input type="checkbox" className={cls} title={title} checked={props.field|| false} onChange={props.handler}/>);
};
export const CheckboxRound=props=>{
    const cls=classes[!!props.field ?'class-true':'class-false'];
    const title=props.title ||null;
    return (<input type="checkbox" className={cls} title={title} checked={props.field|| false} onChange={props.handler}/>);
};
export const SelectList=props=> {
    const lister = []; const def=props.field===null || props.field===''?0:props.field;
    Object.keys(props.list).forEach(name => {
        lister.push(<option key={name} value={name}>{props.list[name]}</option>);
    });
    return (<select value={def} onChange={props.handler}>
        {lister}
    </select>);
};
export const DivSelect=props=>{
    return (<div data-value={props.field}>{props.value}</div>);

};
export const DivCount=props=>{ const newclasses=[]; newclasses.push(classes[props.cls]);
    if(props.clsActive.length>1)  newclasses.push(classes[props.clsActive]);
    return (<div className={props.cls ? newclasses.join(" ") :null} onClick={props.handler}>{props.field}</div>)
};
export const DivProperties=props=>{ const newclasses=[]; newclasses.push(classes[props.cls]);
    if(props.clsActive.length>1)  newclasses.push(classes[props.clsActive]);
    return (<div className={props.cls ? newclasses.join(" ") :'empter'} onClick={props.handler}><span>{props.field}</span></div>)
};
export const DivOptions=props=>{ const newclasses=[]; newclasses.push(classes[props.cls]);
    if(props.clsActive.length>1)  newclasses.push(classes[props.clsActive]);
    return (<div className={props.cls ? newclasses.join(" ") :null} onClick={props.handler}>{props.field}</div>)
};
export const DivIcons=props=>{ const newclasses=[]; newclasses.push(classes[props.cls]);
    const icon=props.field?<img src={Sets.remsite+'/'+props.field+"?" + new Date().getTime()} alt={'not select'}/>:null;
    if(props.clsActive.length>1)  newclasses.push(classes[props.clsActive]);
    return (<div className={props.cls ? newclasses.join(" ") :null} onClick={props.handler}>
        <div>{icon}</div>
        <div>{props.field}</div></div>)
};
export const DivIconOne=props=>{ const newclasses=[]; newclasses.push(classes[props.cls]);
    const icon=Array.isArray(props.field) && props.field.length>0?
        <div ><img src={Sets.remsite+'/'+props.field[0].url} alt={props.field[0].id}/>
            <span className={classes.postfix}>{props.field.length>1?props.field.length:null}</span></div>
        :<div className={classes.border}>{null}</div>;
    if(props.clsActive.length>1)  newclasses.push(classes[props.clsActive]);
    return (<div className={props.cls ? newclasses.join(" ") :null} onClick={props.handler}>{icon}</div>)
};
export const DivColor=props=>{
    //const complCol=complementColor(props.field);
    const style=props.field && props.field!=='none'?{backgroundColor:props.field,color:complementColor(props.field),border:'none'}:{};
    return (<div className={props.cls ? props.cls :null} style={style}>{props.field}</div>)
};