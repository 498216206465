import React, {Component} from "react";
import classes from './Setting.module.css';
import {connect} from "react-redux";
import Loader from "../../components/UI/Loader/Loader";
import Menup from "../../components/adm/Parts/Menup/Menup";
import {
    addNewRecordRasdelTable, admGetDataChilds,
    admSortTable,
    changeRecordStatus, checkerHandlerField, findCurrentPath,
    getAdmRasdelState, sendErrorToBlock
} from "../../store/actions/adminrasdel";
import Header from "../../components/adm/Parts/Header/Header";
import Rasdelrows from "../../components/adm/Rasdel/Rasdelrow/Rasdelrows";
import {buttonsForAction} from "../../components/adm/Sets/utils";
import {Helmet} from "react-helmet";

class Setting extends Component{
    state={tbl:null};

    localCheckHandler=(obj)=>{ // create state localCheckHandler={this.localCheckHandler}
      //   console.log('obj',obj)
        switch(obj.status) {
            case 'selectrow':  this.props.checkerHandlerField(obj); break;
            case 'editrecord': this.props.changeRecordStatus(obj);break;
             case 'canceledit': this.props.changeRecordStatus(obj);break;
             case 'saverecord': this.props.changeRecordStatus(obj);break;
             case 'deleterecord': this.props.changeRecordStatus(obj);break;
             case 'markdeleterecord': this.props.changeRecordStatus(obj);break;
            case 'password': if(this.props.admBlocker.visual===1) {this.props.getDataChilds(obj)}break;
             case 'erroraction': this.props.sendErrorToBlock(obj.name);break;
            case 'iconelem': this.props.getDataChilds(obj);break;
            default: console.log('no any action for it',obj); return;
        }
    };
    createButtons=(blocker)=> {
        if(blocker===1) {
            const buttForView = buttonsForAction(['edit', 'markdelete']);
            const buttForViewCancel = buttonsForAction(['edit', 'delete', 'cancel']);
            const buttForActions = buttonsForAction(['markdelete', 'cancel', 'save']);
            const buttForActionsCancel = buttonsForAction(['save', 'delete', 'cancel']);
            return {buttForView,buttForViewCancel,buttForActions,buttForActionsCancel}
        }
        if(blocker===2) {//2
            const buttForView = buttonsForAction(['edit']);
            const buttForViewCancel = buttonsForAction(['edit']);
            const buttForActions = buttonsForAction([ 'cancel', 'save']);
            const buttForActionsCancel = buttonsForAction(['save', 'cancel']);
            return {buttForView,buttForViewCancel,buttForActions,buttForActionsCancel}
        }
        const buttForView = buttonsForAction(['cancel']);
        const buttForViewCancel = buttonsForAction(['cancel']);
        const buttForActions = buttonsForAction([ 'cancel']);
        const buttForActionsCancel = buttonsForAction([ 'cancel']);
        return {buttForView,buttForViewCancel,buttForActions,buttForActionsCancel}
    };

    async componentDidMount() {
        this.setState({ tbl:this.props.tableput });
        await this.props.findCurrentPath(this.props.location.pathname,this.props.put);
        console.log('this.props.location.pathname',this.props.location.pathname);
        if(!this.props.locationerror.length) {
            this.props.admSortTable('initorder',this.props.tableput);
            this.props.getAdmRasdelState(this.props.page,this.props.tableput,this.props[this.state.tbl].sort);
        } else{
        }
    }

    async componentDidUpdate(prevProps) {
      //  console.log('componentDidUpdate','this.props.page')
        if (this.props.location.pathname !== prevProps.location.pathname) {
            await this.props.findCurrentPath(this.props.location.pathname,this.props.put);
            if(!this.props.locationerror.length) {
                this.props.getAdmRasdelState(this.props.page,this.props.tableput,this.props[this.state.tbl].sort);
            }
        }
    }

    render() {

        const buttonsRow=this.createButtons(this.props.admBlocker.visual);
        const table=this.props.tableput;
        const arrNewHeadButtons=!this.props.admBlocker.crbutt ?[]:['Новый '+table+'...'];
        /*const fieldsError={...this.props[table].data};
        if (table==='user'){
            delete user.occupation;
        }*/
       // console.log('Settings2',this.props[table],table)
        return (
            this.props.loading
                ? <Loader/>
                : !this.props.locationerror.length
                ? <React.Fragment>
                    <Helmet>
                        <title>{this.props.meta.title}</title>
                        <meta name="description" content={this.props.meta.title} />
                    </Helmet>
                    <Menup action={this.props.addNewRecordRasdelTable} table={table} actionSort={this.props.admSortTable}
                           headnames={{[table]:this.props[table].headNames}}
                           partslocation={''} sorter={this.props[table].sort} head={{[table]:arrNewHeadButtons}}/>
                    <div className={classes.scrolrasdel}>
                        <table className={classes.sets}>
                            <Header fields={this.props[table].headNames} lengthRecords={this.props[table].data.length}/>
                            <tbody>
                            <Rasdelrows fields={this.props[table].data} heads={this.props[table].headNames}
                                         checkerHandler={this.localCheckHandler} buttonsRow={buttonsRow}
                                         tbl={table} fullpath={null}
                                        statuspicture={{state:this.props.selectpicturestate,selectpicture:this.props.selectpicture,
                                            selectype:this.props.selectpicturetype}}
                                        admBlocker={this.props.admBlocker}
                            />
                            </tbody></table>
                    </div>
                  </React.Fragment>
                : null

        )
    }
}
function mapStateToProps(state) {
    return {
        page:state.adminrasdel.page,
        loading:state.adminrasdel.loading,
        setting:state.adminrasdel.setting,
        tag:state.adminrasdel.tag,
        user:state.adminrasdel.user,
        icon:state.adminrasdel.icon,
        commusers:state.adminrasdel.commusers,
        locationerror:state.adminrasdel.locationerror,
        meta:state.adminrasdel.meta,
        admBlocker:state.adminrasdel.admBlocker,
        selectpicture:state.adminrasdel.sidehead,
        selectpicturestate:state.adminrasdel.sidestate,
        selectpicturetype:state.adminrasdel.sidetype,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAdmRasdelState: (page,table,sort)=>dispatch(getAdmRasdelState(page,table,sort)),
        addNewRecordRasdelTable: (tbl,value)=>dispatch(addNewRecordRasdelTable(tbl,value)),
        admSortTable: (type,tbl,field)=>dispatch(admSortTable(type,tbl,field)),
        changeRecordStatus: (valueObj)=>dispatch(changeRecordStatus(valueObj)),
        checkerHandlerField: (valueObj)=>dispatch(checkerHandlerField(valueObj)),
        findCurrentPath: (id,tbl)=>dispatch(findCurrentPath(id,tbl)),
        sendErrorToBlock: (valueObj)=>dispatch(sendErrorToBlock(valueObj)),
        getDataChilds: (valueObj)=>dispatch(admGetDataChilds(valueObj)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Setting)
