import React from "react";
import classes from './Skeleton.module.css';

const Skeleton=({params,cls})=>{
    const arr=[];
    const maincls=cls?[cls,classes.skeleton]:[classes.skeleton];
     params.forEach((elem,index)=>{
         const count=elem.count || 1; const radius=elem.radius || '0px';
         const height=elem.height || '2rem';
         const width=elem.width || '99%';
         const margin=elem.margin || '0';
         for(let i=0;i<count;i++){
             const tmp=<div key={height+index+'_'+i} className={classes.rectangle}
                            style={{height:height,borderRadius:radius,width:width,margin:margin}}/>;
             arr.push(tmp);
         }
     });
    return <div className={maincls.join(' ')}>{arr}</div>;
};

export default Skeleton;