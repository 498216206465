import React, {Component} from "react";
import classes from "./Layout.module.css";
import Header from "./Header/Header";
import Article from "../../containers/Article/Article";
import {connect} from "react-redux";
import {logoutAdmUser} from "../../store/actions/adminuser";
import SidebarSelect from "../../components/adm/Sidebar/Sidebarselect";
import SideElement from "../../components/adm/Sideelement/Sideelement";
import axios from "axios";


class Layout extends Component {
    logout=()=> {
        this.props.logoutAdmUser()
    }
     setTokenAxios=(token)=>{
                const curToken=token || sessionStorage.getItem("apicolorscript");
                if(!axios.defaults.headers.common.authorization || axios.defaults.headers.common.authorization !== `${curToken}`)
                    axios.defaults.headers.common.authorization = `${curToken}`;
    }

    render() {
        const asideOut=this.props.sidetype==='countelem' || this.props.sidetype==='commelem'?<SidebarSelect />:<SideElement />;
        const headerSize={width: this.props.cssstyle.headerwidth+'vw'};
        const headerError={width: this.props.cssstyle.headererror+'vw'};
        const leftPart=!this.props.sidearticlestate.viewarticle?classes.leftpart:classes.leftparthidden;
        const rightPart=!this.props.sidearticlestate.viewarticle?classes.rightpart:classes.rightpartcenter;
        this.setTokenAxios(this.props.token)
        return (
            <div className={classes.Layout}>
                <div className={leftPart}   >
                    <div className={classes.head}>
                        <Header header={this.props.headers} logout={this.logout} style={headerSize}/>
                        <div className={classes.error} style={headerError}
                        >{this.props.errors ? this.props.errors : null}
                            {this.props.locaterror ? this.props.locaterror.join(' ') : null}
                        </div>
                    </div>
                    <main>
                        {this.props.children}
                    </main>
                </div>
                <div className={rightPart}>
                <main>


                        {this.props.sidestate?
                            <aside className={classes.asider}>
                                {asideOut}  </aside> :
                        null}



                        {this.props.sidearticlestate.state?
                            <aside className={classes.article}>
                            <Article /> </aside>:
                            null}

                </main>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state){
    return {
        headers:state.adminuser.headers,
        errors: state.adminrasdel.recorderror,
        locaterror:state.adminrasdel.locationerror,
        sidestate:state.adminrasdel.sidestate,
        sidetype:state.adminrasdel.sidetype,
        sidearticlestate:state.adminrasdel.sidearticlestate,
        cssstyle:state.adminrasdel.cssstyle,
        token:state.adminuser.token

    }
}
function mapDispatchToProps(dispatch){
    return {
         logoutAdmUser: ()=>dispatch(logoutAdmUser()),
    }
}
export default (connect(mapStateToProps,mapDispatchToProps)(Layout))
