import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AsideUp from "../Asideup/Asideup";
import classes from "../Asideup/Asideup.module.css";
import {getSideData} from "../../../store/actions/frontActions";

const Aside=(props)=>{
    const dispatch = useDispatch();
    const rq = useSelector((state) => state.frontreducer.rasdelQuery);
    const [result,setResult]=useState({type:'empty',name:''});
    const query=rq.arrPut;


    useEffect(()=>{
            console.log('aside',query) //query
            switch(query.length){
                case 0: setResult( {type:'rasdel',name:'news'});break;
                case 1: setResult( {type:'rasdel',name:query[0]});break;
                case 3: if(query[1]==='a') setResult( {type:'article',name:query[2],rasdel:query[0]});
                    if(query[1]==='i') setResult( {type:'instrument',name:query[2]});
                    break;
                default: setResult( {type:'rasdel',name:'news'});break;
            }

        },
        [query]);

    useEffect(() => {
        dispatch(getSideData({...result,side:['asideup','asidedown']}));
        //const param={...query};
        //  setResult(menu);
     //   console.log('44444444',result)
    },[result,dispatch]);

    return (
        <div className={classes.aside}>
        <AsideUp side={'asideup'} cls={'main'} result={result}/>
        <AsideUp side={'asidedown'} cls={'main'} result={result}/>
        </div>
    );
};

export default Aside;