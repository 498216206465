import React, {Component} from "react";
import classes from './Fileselector.module.css'
import Loader from '../../../components/UI/Loader/Loader';
import Buttonpict from "../../UI/Buttonpict/Buttonpict";
import SimpleBar from 'simplebar-react';

class Fileselector extends Component{
        state={loading:true,blockconfirm:null,name:''};

        changeField=(e)=>{ this.setState({name:e.target.value}); };
        closeConfirm=()=>this.createblockConfirm('cancel',null);
        sendAndClose=(obj)=>{this.props.action(null,'workFolder', ['createFolder',obj]);this.setState({name:''});
        this.createblockConfirm('cancel',null)};
        deleteAndClose=(put)=>{this.props.action(null,'workFolder', ['deleteFile',put]);this.createblockConfirm('cancel',null)};
        createblockConfirm=(type,obj)=>{ let blockconfirm;
            switch(type){
                case 'delete':
                    blockconfirm=obj && obj.name?(<div className={classes.blockdelete}><div className={classes.textdel}> {`Точно удалить ${obj.name}?`}</div>
                        <Buttonpict cls={'closeround'} size={classes.deletebutton} title={'Удалить файл'}
                                    onClick={()=>this.deleteAndClose(obj.put)} />
                        <Buttonpict cls={'cancel'} size={classes.deletebutton} title={'Передумать'}
                                    onClick={()=>this.createblockConfirm('cancel',{name:null})} /></div>):null; break;
                case 'newfolder': blockconfirm=<div className={classes.blockcreate}>
                    <input type='text' className={classes.inputfolder} name='name' placeholder='Введи название папки...'
                           onChange={(event)=>this.changeField(event)} val={this.state.name}/>
                    <Buttonpict cls={'yes'} size={classes.deletebutton} title={'Создать папку'}
                                onClick={()=>this.sendAndClose(this.state.name)} />
                    <Buttonpict cls={'cancel'} size={classes.deletebutton} title={'Передумать'}
                                onClick={()=>this.createblockConfirm('cancel',{name:null})} />
                </div>; break;
                default:blockconfirm=null;
            }
            this.setState({blockconfirm});
        };

  createCatalog=(filestate)=>{
      const dirs=filestate.dirs && filestate.dirs.map(elem=>{
          return <div className={classes.dirstr} key={elem.name}>
              <div className={classes.dir} onClick={()=>{this.props.action(null,'workFolder', ['getFolder',elem.put]);this.closeConfirm();}}
              title={elem.name}>{elem.name}</div><div className={classes.dirtype}>{'directory'}</div>
                  <Buttonpict cls={'closeround'} size={classes.deletebutton}
                              onClick={()=>this.createblockConfirm('delete',elem)} title={'Удалить файл'}/>
          </div>
      });
      const files=filestate.files && filestate.files.map(elem=>{
          return <div className={classes.dirstr} key={elem.name}>
              <div className={classes.file} title={elem.name} >{elem.name}</div>
              <div className={classes.filetype}>{elem.ext}</div>
              <div className={classes.selectfile} >
                  <Buttonpict cls={'yes'} size={classes.increasefon} onClick={()=>this.props.action(null,'workFolder', ['selectFile',elem])}
                              title={'Добавить ссылку'}/>
                  <Buttonpict cls={'closeround'} size={classes.deletebutton}
                              onClick={()=>this.createblockConfirm('delete',elem)} title={'Удалить файл'}/>
              </div>
              </div>
      });
      return <div className={classes.dirtbl}>{dirs}{files}</div>;
  };
  createButtons=(filestate)=>{
        const handler=(e)=>{this.props.action(e,'workFolder', ['loadFile'])};
        const inputFile=this.createLoadFileButton([classes.labelfile,classes.inputfile], handler,'Выбрать файл');
      return <div className={classes.header}><div className={classes.currentdir} title={filestate.current}>/{filestate.current}</div>
          <div className={classes.buttondir}>
              <Buttonpict cls={'orderup'}  onClick={()=>{this.props.action(null,'workFolder', ['orderUp',filestate.current]);
              this.closeConfirm();}} title={'Выше...'}/>
              {inputFile}
              <Buttonpict cls={'directory'}  onClick={()=>this.createblockConfirm('newfolder',null)} title={'Создать директорию файл'}/>
              <Buttonpict cls={'refresh'} onClick={()=>this.props.action(null,'getFile', null)} title={'Обновить'}/>
          </div>
      </div>
  };
createLoadFileButton=(arrclass,handler,title)=>{
    const clslabel=Array.isArray(arrclass) && arrclass[0]?arrclass[0] :null;
    const clsfileinput=Array.isArray(arrclass) && arrclass[1]?arrclass[1] :null;
    return <form onSubmit={(e)=>e.preventDefault()}>
        <label className={clslabel} title={title}>
            <input className={clsfileinput} type="file" onChange={(e)=>handler(e)} />
        </label>
    </form>
};
    async componentDidMount() {
        let status=localStorage.getItem('Fileselector');
        if(!status){status='files';localStorage.setItem('Fileselector',status)}
        await this.props.action(null,'workFolder', ['getFolder',status]);
        this.setState({loading:false,folder:status});
    }
    async componentDidUpdate(prevProps) {
      //  console.log('this.props.filestamp !== prevProps.filestamp',this.props.filestamp ,prevProps.filestamp)
        if(this.props.filestamp !== prevProps.filestamp) {
            let status = localStorage.getItem('Fileselector');
            await this.props.action(null, 'workFolder', ['getFolder', status]);
        }
    }

    render() {
        const header=this.createButtons(this.props.filestate);
        const tbl=this.createCatalog(this.props.filestate);
        const blockconfirm=this.state.blockconfirm;

        return(
            this.state.loading
                ? <Loader/>
                :<div>{header}<SimpleBar style={{ maxHeight: '40vh' }}> {blockconfirm}{tbl}</SimpleBar></div>
    )};
}

export default Fileselector;