import React, {Component} from "react";
import classes from './Iconselect.module.css';
import axios from "axios";
import Sets from "../../adm/Sets/sets";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

class IconSelect extends Component{
    state={loading:false,arricon:[]};
    getIcons= async ()=>{
        try {
            return await axios.get('/api/geticon/');

        }catch (e) {
            console.log('not has worked get icon', e)
        }
    };
    makeIconList=async (arrnew)=>{
        if(arrnew.length){
            return arrnew.map( elem=>{
                return (<div className={classes.iconone} key={'icon'+elem.id}>
                    <img alt={elem.iconurl}  src={Sets.remsite+'/'+elem.iconurl} />
                    <div onClick={()=>this.props.action(null,'addicontag',elem.id)}>Add...</div>
                </div>);
            });
        }
    };

    async componentDidMount() {
        const arr=await this.getIcons();
        const arrnew=arr.data.newRecords;
        const iconList=await this.makeIconList(arrnew) ;
        this.setState({loading:true,arricon:iconList});

    }

    render() {
            const iconList=this.state.arricon; //this.makeIconList() ||'no icon';
        return (<div>
            {this.state.loading?
                <SimpleBar style={{ maxHeight: '40vh'}}>
                    <div className={classes.iconlist}>{iconList}</div>
                </SimpleBar>
            : 'Loading...'
            }
        </div>)

    }
}
export default IconSelect;