export const ADM_USER_START='ADM_USER_START';
export const ADM_USER_SUCCESS='ADM_USER_SUCCESS';
export const ADM_USER_ERROR='ADM_USER_ERROR';// send to auth menu
export const ADM_STATE_ERROR_LOGIN='ADM_STATE_ERROR_LOGIN';

export const ADM_STATE_ERROR='ADM_STATE_ERROR';

export const ADM_RASDEL_START='ADM_RASDEL_START';
export const ADM_RASDEL_SUCCESS='ADM_RASDEL_SUCCESS';
//export const ADM_RASDEL_DISCONNECT='ADM_RASDEL_DISCONNECT'; //no
export const ADM_RASDEL_ERROR='ADM_RASDEL_ERROR';
export const GET_ADM_RASDEL_NEWREC='GET_ADM_RASDEL_NEWREC';
export const GET_ADM_RASDEL_NEWREC_SUCCESS='GET_ADM_RASDEL_NEWREC_SUCCESS';

export const UPDATE_ADM_RASDEL_SUCCESS='UPDATE_ADM_RASDEL_SUCCESS';
export const UPDATE_ADM_RASDEL_REC='UPDATE_ADM_RASDEL_REC';
export const CHANGE_ADM_RASDEL_REC='CHANGE_ADM_RASDEL_REC';
export const CHANGE_ADM_RASDEL_SUCCESS='CHANGE_ADM_RASDEL_SUCCESS';

export const SEND_ERROR_TO_BLOCK='SEND_ERROR_TO_BLOCK';
export const SEND_ERROR_TO_LOCATION='SEND_ERROR_TO_LOCATION';

export const ADMIN_CHANGE_PAGE='ADMIN_CHANGE_PAGE';
export const ADMIN_CHANGE_ORDER_SORT='ADMIN_CHANGE_ORDER_SORT';
export const ADMIN_UPDATE_SAME_RASDEL='ADMIN_UPDATE_SAME_RASDEL';

export const ADMIN_BLOCKER_ACTIVE='ADMIN_BLOCKER_ACTIVE';
export const ADMIN_BLOCKER_DEACTIVE='ADMIN_BLOCKER_DEACTIVE';

export const ADMIN_SWITCH_SIDEBAR='ADMIN_SWITCH_SIDEBAR';
export const ADMIN_SWITCH_SIDEBAR_HEAD='ADMIN_SWITCH_SIDEBAR_HEAD';
export const ADMIN_SWITCH_SIDEBAR_BODY='ADMIN_SWITCH_SIDEBAR_BODY';
export const ADMIN_SWITCH_SIDEBAR_TYPE='ADMIN_SWITCH_SIDEBAR_TYPE';
export const ADMIN_SWITCH_SIDEBAR_SETS='ADMIN_SWITCH_SIDEBAR_SETS';

export const ADMIN_ARTICLE_SWITCH='ADMIN_ARTICLE_SWITCH';
export const ADMIN_ARTICLE_SET='ADMIN_ARTICLE_SET';

export const ADMIN_TAGS_LIST='ADMIN_TAGS_LIST';

export const ADMIN_FILE_STATE='ADMIN_FILE_STATE';
export const ADMIN_DIR_STATE='ADMIN_DIR_STATE';

export const ADM_STATUS_BLOCK='ADM_STATUS_BLOCK';
export const ADM_STATUS_META='ADM_STATUS_META';


export const ADMIN_CSS_SETS='ADMIN_CSS_SETS';

export const ADM_COMMENTS='ADM_COMMENTS';
export const ADM_COMMENTS_USERS='ADM_COMMENTS_USERS';
export const ADM_COMMENTS_IP='ADM_COMMENTS_IP';




