import React from "react";
import classes from './Header.module.css';
import {getHeadersForm} from '../../Sets/utils'

const headRasdel=props=>{
    const fields=props.fields;
    const cls=props.classhead? [classes.headerasdel,classes[props.classhead]].join(' '):classes.headerasdel;
    return props.lengthRecords ?
        ( <thead><tr className={cls}>
        {
           fields.map((punkt,index)=>{
               const cls2 = [cls,classes[punkt]].join(' ');
                return designElementTH(punkt,index,cls2)
        })
        }
    </tr></thead>) : null
};

export default headRasdel;

function designElementTH(name,key,cls){
    const note=getHeadersForm(name);
    if (note!=='none') { return <th className={cls} key={key} >{note}</th>}
    else{return null;}
    }