import {
    ADM_RASDEL_START,
    ADMIN_CHANGE_PAGE,
    ADM_STATUS_BLOCK,
    ADM_STATUS_META,
    ADM_RASDEL_SUCCESS,
    ADM_RASDEL_ERROR,
    GET_ADM_RASDEL_NEWREC,
    GET_ADM_RASDEL_NEWREC_SUCCESS,
    UPDATE_ADM_RASDEL_SUCCESS,
    UPDATE_ADM_RASDEL_REC,
    CHANGE_ADM_RASDEL_SUCCESS,
    CHANGE_ADM_RASDEL_REC,
    ADMIN_CHANGE_ORDER_SORT,
    ADMIN_UPDATE_SAME_RASDEL,
    SEND_ERROR_TO_BLOCK,
    SEND_ERROR_TO_LOCATION,
    ADMIN_BLOCKER_ACTIVE,
    ADMIN_BLOCKER_DEACTIVE,
    ADMIN_SWITCH_SIDEBAR,
    ADMIN_SWITCH_SIDEBAR_HEAD,
    ADMIN_SWITCH_SIDEBAR_BODY,
    ADMIN_SWITCH_SIDEBAR_TYPE,
    ADMIN_SWITCH_SIDEBAR_SETS,
    ADMIN_CSS_SETS,
    ADMIN_ARTICLE_SWITCH,
    ADMIN_ARTICLE_SET,
    ADMIN_FILE_STATE,
    ADMIN_DIR_STATE,
    ADMIN_TAGS_LIST,
    ADM_COMMENTS, ADM_COMMENTS_USERS, ADM_COMMENTS_IP
} from "../actions/actionTypes";
// todo что сделать: таблицу пункты-цены с картинками параметры, модуль директории, теги к статье, удаление статьи с картинками и файла в элемент
// todo пользователи + блокиратор для editora или выбор параметров, проверка раздела на не пустоту
// когда статьи удалены раскрывать раздел
const initialState={
    icon:{sort:'ASC',headNames:[],data:[]},
    admBlocker:0,
    filestate:{},
    comments:[],userscomms:[],commip:[],
    dirstate:{},
    sidebody: {},
    sidestate:0,sidetype:'', //tester //sidestate:0,sidetype:'',
    sidesets:{},
    sidehead:{},
    /*sidehead:{table: "article",    namefield: "comment",    id: "12",    namerasdel: "Related to Items You've Viewed",
    currentpictur: 0},*/ //tester
    //sidearticlestate:{state:false,menu:1},
    sidearticlestate:{state:0,menu:0,viewarticle:0},
    sidearticle:{name:'', editblock:{}, tags:{hidden:0,arr:[ ]},text:  [ ],id:1},
    blocker:0,
    article:{sort:'ASC',headNames:[],data:[]},
    tagslist:[],
    page:{nom:0, fullpath:''},
    rasdel:{sort:'ASC',headNames:[],data:[]},
    partslocation:[],
    error:'',
    loading:false,
    meta:{title:'Административная часть'},
    recorderror:[], locationerror:[],
   // sidestate:1, sidehead:{id:"2", namefield:"picture", namerasdel:"vbnvbnvb gfsd fgdfgd",table:"rasdel"},
    element:{sort:'ASC',headNames:[],data:[]},
    setting:{sort:'ASC',headNames:[],data:[]},
    tag:{sort:'ASC',headNames:[],data:[]},
    commusers:{sort:'ASC',headNames:[],data:[]},
    user:{sort:'ASC',headNames:[],data:[]},
   // cssstyle:{leftwidth:44, headerwidth:25,headererror:15,articlewidth:55} //changeStyles in reducer
    cssstyle:{leftwidth:65, headerwidth:40,headererror:20,articlewidth:35}

};

export default function adminRasdelReducer(state=initialState,action){

    switch(action.type){
        case ADM_RASDEL_START:
            return{
                ...state,loading:true
            };
        case ADM_RASDEL_SUCCESS:
            return{
                ...state,loading:false, [action.rasdel.namerasdel]:action.rasdel.arr //page:action.page,
            };
        case ADM_RASDEL_ERROR:
            return{
                ...state,loading:false, error:action.error
            };
        case GET_ADM_RASDEL_NEWREC:
            return{
                ...state,loading:false
            };
        case GET_ADM_RASDEL_NEWREC_SUCCESS:
            return{
                ...state,loading:false, [action.rasdel.namerasdel]:action.rasdel.arr
            };
        case UPDATE_ADM_RASDEL_REC:
            return{
                ...state,loading:false
            };
        case UPDATE_ADM_RASDEL_SUCCESS:
            return{
                ...state,loading:false, [action.rasdel.namerasdel]:action.rasdel.arr
            }
        case CHANGE_ADM_RASDEL_REC:
            return{
                ...state
            };
        case CHANGE_ADM_RASDEL_SUCCESS:
            return{
                ...state, [action.rasdel.namerasdel]:action.rasdel.arr
            }
        case SEND_ERROR_TO_BLOCK:
            return{
                ...state, recorderror:action.recorderror//,loading:false
            }
        case SEND_ERROR_TO_LOCATION:
            return{
                ...state, locationerror:action.locationerror,loading:false
            }
        case ADMIN_CHANGE_PAGE:
            return {
                ...state, page:action.newpage.page,//rasdel:action.newrasdel.rasdel, loading:false,
                partslocation:action.newpage.partslocation
            }

        case ADMIN_BLOCKER_ACTIVE:
            return{ ...state,blocker:1}
        case ADMIN_BLOCKER_DEACTIVE:
            return{ ...state,blocker:0}

        case ADMIN_CHANGE_ORDER_SORT:
                    return{
                        ...state, [action.rasdel.namerasdel]:action.rasdel.arr
            }
        case ADMIN_UPDATE_SAME_RASDEL:
            return{
                ...state, [action.rasdel.namerasdel]:action.rasdel.arr
            }

        case ADMIN_SWITCH_SIDEBAR:
            const arr=action.sidestate?state.sidebody:[];
            return{ ...state,sidestate:action.sidestate,sidebody:arr};
        case ADMIN_SWITCH_SIDEBAR_HEAD:
            return{ ...state,sidehead:action.head};
        case ADMIN_SWITCH_SIDEBAR_TYPE:
            return{ ...state,sidetype:action.sidetype};
        case ADMIN_SWITCH_SIDEBAR_SETS:
            return{ ...state,sidesets:action.sets};
        case ADMIN_SWITCH_SIDEBAR_BODY:
            return{ ...state,sidebody:action.headbody.body,sidehead:action.headbody.head};

        case ADMIN_CSS_SETS:
            return{ ...state,cssstyle:action.cssstyle};

        case ADMIN_ARTICLE_SWITCH:
            return{ ...state,sidearticlestate:action.status};
        case ADMIN_ARTICLE_SET:
            return{ ...state,sidearticle:action.article};
        case ADMIN_TAGS_LIST:
            return{ ...state,tagslist:action.tags};

        case ADMIN_FILE_STATE:
            return{ ...state,filestate:action.filestate};

        case ADMIN_DIR_STATE:
            return{ ...state,dirstate:action.dirstate};

        case ADM_STATUS_BLOCK:
            return{ ...state,admBlocker:action.obj};

        case ADM_STATUS_META:
            return{ ...state,meta:action.obj};

        case ADM_COMMENTS:
            return{ ...state,comments:action.comments};
        case ADM_COMMENTS_USERS:
            return{ ...state,userscomms:action.commusers};
        case ADM_COMMENTS_IP:
            return{ ...state,commip:action.commip};
        default:
            return state;
    }
}

