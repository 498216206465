import React, {useCallback, useEffect, useState} from "react";
import classes from './Comment.module.css'

const reg =new RegExp( /^([a-zа-яё0-9_-]+|\d+)$/i);
const ComLog=(props)=>{
    const [name,setName]=useState('');
    const [status,setStatus]=useState(0);
    const [cls,setCls]=useState('');

    const getName=useCallback((data)=>{props.getName(data)},[props]);

    const nameStorage='colorScriptorName';
    const changeName=(e)=>{
        if(props.blocker) return;
        setName(e.target.value);
        if(!reg.test(e.target.value) && e.target.value.length ) {setCls(classes.errorlog);getName('');}
        else{setCls('');getName(e.target.value);}
    };
    const saveName=()=>{if(name && !cls) {localStorage.setItem(nameStorage,name);setStatus(1)}}; //check
    const delName=()=>{localStorage.removeItem(nameStorage);setStatus(0)};

    useEffect(()=>{
        const data=localStorage.getItem(nameStorage);
        if(data) {setName(data);setStatus(1);getName(data);}
    },[setName,setStatus,getName] );

    //const status=localStorage.getItem(sortTable);
    const inpField=status?<div className={classes.namestore}>{name}</div>:<input className={[classes.inputlog,cls].join(" ")}
                                                                                 type={'text'} onChange={changeName} value={name}/>;
    const button=status
        ?<button className={classes.buttonlog} onClick={delName} title={'Изменить'}>Изменить</button>
        :<button className={classes.buttonlog} onClick={saveName} title={'Запомнить в куки'}>Запомнить</button>;

    return <div >
        <form className={classes.comlog} onSubmit={e => {e.preventDefault();}}>
            <label >Name: </label>
            <input type={'password'} className={classes.hider} value={''}  onChange={e => {e.preventDefault()}}/>
            {inpField}{button}
            {cls?<div className={classes.errormsg}>Только буквы, цифры, '-' и '_'</div>:null}
        </form>
        </div>
};
export default ComLog;

