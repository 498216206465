import React from "react";
import classes from './Footer.module.css';
import Sets from "../../adm/Sets/sets";

const Footer =()=>{
    const year=new Date().getFullYear();
    const left= <div className={classes.left}>
        <a href={Sets.remsite+"/rss.xml"}   rel="noopener noreferrer" target="_blank" >RSS</a>
        <a href="https://t.me/colorscript" target="_blank" rel="noopener noreferrer" >Telegram</a>
    </div>;
    const right= <div className={classes.right}>© ColorScript, 2023—{year}. Интернет-журнал о цвете и скриптах.
Все права на тексты, изображения, видео и аудио принадлежат их авторам. 
Материалы журнала могут быть использованы при указании гиперссылки на источник. </div>;

    return <div className={classes.footer}>
        {left}{right}
    </div>;
};

export default Footer;

//  serve -s build -l 4000
//  "http://localhost:3001"
// https://api.colorscript.ru