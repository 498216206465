import React, {useState} from "react";
import Skeleton from "../../UI/Skeleton/Skeleton";
import classes from "./Imgonload.module.css";

const Imgonload=(props)=>{
    const [onLoader,setOnload]=useState(false);
    const handler=()=>{
        setTimeout(()=>{ setOnload(1)},300);
        /*setOnload(1);*/ }
    const params=props.params || {};
    const imageStyle = !onLoader ? { display: "none" } : {};
    const emptyret=()=>{return 0};
    const clicker=props.clicker || emptyret;
    const cls=props.type === 'picture'?[classes.sflex,classes.picture]:[classes.sflex];
    const source=props.srcSet && props.srcSet.length?props.srcSet.map((elem, idx)=><source srcSet={elem[0]} media={elem[1]} key={idx}/>)
        :null;
    const img = props.type === 'img' ? <img src={props.src} style={imageStyle} alt={props.alt} onLoad={handler}/>
        : <picture onClick={clicker} style={imageStyle} onLoad={handler}>
            {source}
            <img src={props.src}  alt={props.alt} />
        </picture>;

    return <>
       {/* {   <Skeleton params={[params]} cls={cls.join(' ')} /> }*/}
        {  !onLoader && <Skeleton params={[params]} cls={cls.join(' ')} /> }
        {img}
    </>
};
export default Imgonload;