import React from "react";
import classes from './loaderfront.module.css';

const LoaderFront=(props)=>{
    if(props.type==='cube')
    return <div className={classes.container}>
        <div className={classes.pagepreloader} id="page-preloader">
            <span className={[classes.preloaderitem,classes.preloaderitem1].join(" ")}>{null}</span>
            <span className={[classes.preloaderitem,classes.preloaderitem2].join(" ")}>{null}</span>
            <span className={[classes.preloaderitem,classes.preloaderitem3].join(" ")}>{null}</span>
            <span className={[classes.preloaderitem,classes.preloaderitem4].join(" ")}>{null}</span>
        </div>
    </div>;

    return <div className={classes.wrapper}>
        <div className={classes.maincircle}>
            <div className={classes.greencircle}>
                <div className={classes.browncircle}>

                </div>
            </div>
        </div>
    </div>


};
export default LoaderFront;

