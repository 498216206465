import axios from  'axios';
import {
    setMenu,
    setListArticles,
    addListArticles,
    changeStatusArticle,
    setListHeader,
    setQueryList,
    setRepeatQuery,
    setIndexCount,
    setScrollArt,
    switchLoading,
    setVisualArt,
    setErrorPage,
    setCurrentArticle,
    setPageArticle,
    setRepeatQueryLink,
    setMainList,
    setSideList,
    setCommentList,
    setSearchPanel,
    setUrlSearch,setMeta
} from "../reducers/frontslice";
import {CONST_PREF} from '../../components/adm/Sets/sets';
//import React from "react";
const pref=CONST_PREF;
const pauser=10;
//console.log("CONST_PREF",process.env.REACT_APP_API_STATUS)
export const getHeaderMenu=()=> {
    return async(dispatch) => {
       // await timeout(2000);
        try {
            const res = await axios.get(pref+'/front/menu/');
            if(res.error) {console.log('error: ',res.msg);return 0;}
            const menu=res.data.records.map(elem=>{return{id:elem.id,icon:elem.icon,name:elem.name,nameurl:elem.nameurl}});
            dispatch(setMenu({header: menu}));
        } catch (e) {  console.log("error "+e);}
       // console.log("selector")
       //  dispatch(setMenu({adder: 2}))


    }
};
export const getListArticles=(param)=>{
    return async(dispatch,getState) => {
        try {
          // console.log('param',param)
            dispatch(setErrorPage({error404:0}));
            const paramsString = document.location.search;
            const searchParams = new URLSearchParams(paramsString);
          // console.log('document.location.search',searchParams.get("page"),document.location.origin+document.location.pathname,paramsString)
            if(param.param.type==='error404') {dispatch(setErrorPage({error404:1}));return 0;}

            const repeatQuery=getState().frontreducer['repeatQuery'];
            let repeatQueryLink=getState().frontreducer['repeatQueryLink'];
            let oldPageArticle=getState().frontreducer['pageArticle'];
            let urlSearch=getState().frontreducer['urlSearch'];
            let pageArticle=param.pageArticle || oldPageArticle;

            let rasdelQuery=getState().frontreducer['rasdelQuery'];
            let testRasdelQ=rasdelQuery.type?rasdelQuery.type+rasdelQuery.arrPut.join(''):'';
            testRasdelQ=testRasdelQ===param.param.type+param.param.arrPut.join('');
            //если разделы (запрос Route и State) не равны то TRUE и запрос ниже со статьями
            const testPageA=oldPageArticle===pageArticle;
            // если страницы (запрос Route и State) равны то запрашивать не надо.
            // Запрос когда Разделы или Статьи не равны
            //console.log('oldPageArticle JOIN',oldPageArticle,pageArticle) //kogda ravni nelza

            if(repeatQuery) {
               if (rasdelQuery.arrPut && rasdelQuery.arrPut[0] !== param.param.arrPut[0]) pageArticle = 0;
           } //else{pageArticle = 0;}
          //  console.log('rasdelQuery.type',rasdelQuery.type,document.location.pathname)
            if(rasdelQuery.type==='search' && document.location.pathname.split('/')[1]==='search'){
                const tags=searchParams.get('tags') || 'null' ;
                const icons=searchParams.get('icons') || 'null';
                const word=searchParams.get('word') || 'null';
                if(rasdelQuery.query.tags.toString()!==tags.toString()) repeatQueryLink=true;
                if(rasdelQuery.query.icons.toString()!==icons.toString()) repeatQueryLink=true;
                if(rasdelQuery.query.word.toString()!==word.toString()) repeatQueryLink=true;

               // if (searchParams){ console.log('SEACHER1-!',searchParams.toString()) }
             //   console.log('SEACHER22-!',rasdelQuery)
                // const location=
            }

           // todo if(urlSearch)  если предыжущий не совпадает  то запрос заново


           // console.log('SEACHER!!!',rasdelQuery,searchParams)

            //searchParams.get("page") работает если ввести в строке, по умолчанию нет
            let headertype=getState().frontreducer['headertype'];
            let header=getState().frontreducer['header'];
            let articles=getState().frontreducer['articles'];

            let headerprev=header;
            let currentScroll=getState().frontreducer['scrollArt'];
           // dispatch(setScrollArt({scrollArt: ''}));
            dispatch(switchLoading({loading: 0}));


           // console.log('from from from !!!',searchParams)
             if(urlSearch)   {repeatQueryLink=true;
                 //window.scrollTo({top: 0})
             }  //scrollToBegin currentScroll=''; , behavior: "smooth"
             if(repeatQueryLink)  dispatch(setListArticles({articles: []})) //zero new articles if new rasdel
           // console.log('param@@@@@%',param,{repeatQueryLink,repeatQuery,testRasdelQ,testPageA})
            if(repeatQueryLink || ((!articles.length || repeatQuery) &&  (!testRasdelQ || !testPageA) )){
                if(searchParams.get("page")>0 && !pageArticle) param.pageArticle=pageArticle=parseInt(searchParams.get("page"),10)-1;
                const res = await axios.get(pref+'/front/list/',{ params: {...param} });
             //   console.log("!!!was query to server LIST",res.data)
                dispatch(setUrlSearch({urlSearch:0}));
                if(res.data.error) {console.log('error: ',res.data.msg);
                    if(res.data.iderr===404) dispatch(setErrorPage({error404:1}));
                    dispatch(switchLoading({loading: 1}));
                     return 0;
                }
               /* if(!res.data.error && !res.data.records.length) {
                    dispatch(switchLoading({loading: 1}));
                    return false;
                }*/
                if(pageArticle && !res.data.records.length) {dispatch(setErrorPage({error404:1}));
                   // dispatch(setScrollArt({scrollArt: ''}))
                    dispatch(switchLoading({loading: 1}));
                    return 0;
                }
                dispatch(setQueryList({query: {...param.param}}));
                dispatch(setListHeader({header: res.data.header,type:param.param.type}));
                headertype=param.param.type;header=res.data.header;


                if(pageArticle && !searchParams.get("page") ){ //set scroll if not firstpage: searchParam - если была в строке, типа рефреш страницы
                    const lastId=articles.length?articles[articles.length-1].id+'_'+articles[articles.length-1].nameurl:0;
                    currentScroll=res.data.records.length?res.data.records[0].id+'_'+res.data.records[0].nameurl:lastId;
                    dispatch(addListArticles({articles: res.data.records}))
                   // const navigate = useNavigate();
                   // navigate("../"+stateObj+"?page=2", { replace: true });
                }else{
                   // await setTimeout(()=>{ console.log('roller&&')},pauser);
                    dispatch(setListArticles({articles: res.data.records}))}
                dispatch(setIndexCount({indexCount: res.data.indexCount})); //todo сюда массив ИД поисковых добавить
                dispatch(setRepeatQuery({repeatQuery: 1}));
                headerprev=res.data.header;
            }else{
                dispatch(setQueryList({query: {...param.param}}));
                dispatch(setListHeader({header,type:headertype}));
                dispatch(setRepeatQuery({repeatQuery: 1}));
               // dispatch(setListArticles({articles: res.data.records}));
            }
            dispatch(setMainList({mainlist: 1}));
            dispatch(setPageArticle({pageArticle}));

            dispatch(setRepeatQueryLink({repeatQueryLink: 0}));
          //  console.log('currentScroll',currentScroll);
          //  const list=res.data.records.map(elem=>{return{id:elem.id,rasdel:elem.rasdel, icon:elem.icon,name:elem.name,nameurl:elem.nameurl}});
           // console.log('---',{...param});
        //    await setTimeout(()=>{ dispatch(switchLoading({loading: 1}))},pauser);

          //  const nomArt=res.data.nomart;
          //  console.log('-PREVheadertype--',headertype,header);
            if(headertype==='news' || headertype==='rasdel' || headertype==='search'){
                dispatch(setVisualArt({visualArt: 0}))
                    //    console.log('-PREVheadertype--',headertype,header);
                     //   console.log('-headertype--',param.param.type,{headerprev,header});
                if(header===headerprev){dispatch(setScrollArt({scrollArt: currentScroll}));
                   // dispatch(setVisualArt({visualArt: 0}))
                }
            }
            await setTimeout(()=>{ dispatch(switchLoading({loading: 1}))},pauser);
           //
           // dispatch(switchLoading({loading: 1}));
        } catch (e) {  console.log("error "+e);}
    }
};
export const getArticlesInList=(id)=> {
    return async (dispatch) => {
        try {
            const res = await axios.get(pref+'/front/article/',{ params: {id,type:'articlein'} });
            if(res.data.error) {console.log('error: ',res.data.msg);return 0;}
            const text=JSON.parse(res.data.records[0].text);
            dispatch(changeStatusArticle({id: id,text}));
        } catch (e) {
            console.log("error " + e);
        }
    }
};
export const getOneArticle=(param)=> {
    return async (dispatch) => {
        try {
            dispatch(switchLoading({loading: 0}))
           // console.log('getOneArticle',param);
            dispatch(setErrorPage({error404:0}));
            dispatch(setRepeatQuery({repeatQuery: 0}));
            dispatch(setVisualArt({visualArt: 1}))
           // const posTop = (window.pageYOffset !== undefined) ? window.pageYOffset
           //     : (document.documentElement || document.body.parentNode || document.body).scrollTop;
            const res = await axios.get(pref+'/front/article/',{ params: {...param,type:'articlefull'} });
            if(res.data.error) {console.log('error: ',res.data.msg);
                if(res.data.iderr===404) dispatch(setErrorPage({error404:1}));
                dispatch(switchLoading({loading: 1}))
                return 0;
            }
           // console.log("!!!was query to server article",res.data)
            const idSelect=res.data.records[0].id+'_'+res.data.records[0].nameurl;

            const text=JSON.parse(res.data.records[0].text);
            const namearticle=res.data.records[0].namearticle || '';
            const name=res.data.records[0].nameurl || '';
            const putRasdel=res.data.records[0].put || '/';
            const rasdelname=res.data.records[0].rasdelname || '---';
            const createDate=res.data.records[0].createdAt || '';
            const iconrasdel=res.data.records[0].iconrasdel|| [];
            const iconart=res.data.records[0].iconart || [];
            const tagart=res.data.records[0].tagart || [];
            const id=res.data.records[0].id || 0;

            const meta={picture:res.data.records[0].picture||'',picturesize:res.data.records[0].picturesize||'',
                title:namearticle,description:res.data.records[0].anons || []};
            const tags=tagart.map(elem=>elem.name);
            meta.keywords=tags.join(',');

            const headers={namearticle,createDate,iconrasdel,iconart,tagart,name,putRasdel,rasdelname};

       //     console.log('-----article', (res.data.records[0]));
           dispatch(setMeta( {meta}));
           dispatch(setCurrentArticle( {article:{text,headers},id}));
            dispatch(setMainList({mainlist: 0}));
            dispatch(setScrollArt({scrollArt: idSelect}));
            dispatch(setQueryList({query: {...param,type:'article'}}));
           // dispatch(setVisualArt({visualArt: 1}))
            await setTimeout(()=>{ dispatch(switchLoading({loading: 1}))},500);
           // dispatch(switchLoading({loading: 1}));
        } catch (e) {
            console.log("error " + e);
        }
    }
};

export const getSideData=(param)=> {
    return async (dispatch) => {
        try {
           // console.log("getSideData " , param);
            if(param.type!=='empty') {
                const res = await axios.get(pref+'/front/sider/', {params: {...param}});
               // console.log("!!!was query  getSideData", res.data.records)
                param.side.forEach((item,index)=>{
                if (res.data && res.data.records[index].length) {dispatch(setSideList({side: param.side[index], arr: res.data.records[index]}));}
                else{dispatch(setSideList({side: param.side[index], arr: []}))}
                });
            }
//return false
        }catch (e) {
            console.log("error " + e);
        }
    }
};

export const getCommentList=(param)=> {
    return async (dispatch,getState) => {
        try {
          //  const article=getState().frontreducer.idcurrentarticle;
            const res = await axios.get(pref+'/front/commentlist/',{params:{art:param}} );
           // console.log("!!!was query commentlist " , res.data);
            dispatch(setCommentList({comment:res.data.records}));
        }catch (e) {
            console.log("error " + e);
        }
    }
};
export const getArrayForSearch=()=>{
    return async (dispatch) => {
        try {
            const res = await axios.get(pref+'/front/searchparam/',{params: {option:['tag','icon']}} );
           // console.log("!!!was query ArrayForSearch ", res.data);
            if(res.data.error) {console.log('error: ',res.data.msg); return 0;}
            dispatch(setSearchPanel({searchpanel:res.data.records}))
        }catch (e) {
            console.log("error " + e);
        }
    }
};

/*export const overShowImage=(objImg)=> {
    return (dispatch) => {
        try {
            const srcSet=objImg.srcSet.length?objImg.srcSet.map(elem=><source srcSet={elem[0]} media={elem[1]}/>):null;
            const pictur= <picture> {srcSet}
                    <img src={objImg.link} alt=""/>
                    </picture>;


        } catch (e) {
            console.log("error " + e);
        }
    }
}*/
/*
function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}*/
