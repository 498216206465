import React, {Component} from "react";
import {EditorState,RichUtils,Modifier, DefaultDraftBlockRenderMap} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { Map } from 'immutable';
import classcom from '../Styles/Stylecommon.module.css';
import classes from './Drafteditor.module.css';
import ExtendedRichUtils, {ALIGNMENT_DATA_KEY} from './components/ExtendedRichUtils';
import {onAddLink,getLink,createLinkDecorator} from "./components/addLink";
import Editorbuttons from './components/Editorbuttons';
import  {ToolTipPush} from '../ToolTipPush/ToolTipPush';
import {LinkCreator, getInlineStyle,colorStyleMap,styleColorButton, Replace,OtstupBlocker,
    DividerBlock,addHorizontalRule} from './components/Draftutils';
import converterImport from "./components/importData";

function myBlockStyleFn(contentBlock) {
    const textAlignStyle = contentBlock.getData().get(ALIGNMENT_DATA_KEY);
    switch (textAlignStyle) {
        case 'RIGHT':
            return classcom['align-right'];
        case 'CENTER':
            return classcom[`align-center`];
        case 'LEFT':
            return classcom[`align-left`];
        case 'JUSTIFY':
            return classcom[`align-justify`];
        default:   return classcom[`align-left`];
    }
}
const customStyleMap = {
    ...colorStyleMap,
    CODE: {
        backgroundColor: '#e1e1e1',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },

};
const blockRenderMap = Map({
    'OtstupBlock': {
        // element is used during paste or html conversion to auto match your component;
        // it is also retained as part of this.props.children and not stripped out
        element: 'section',
        wrapper: <OtstupBlocker cls={classcom.postotstup}/>,
    }
});

// keep support for other draft default block types and add our myCustomBlock type
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);


//--------------------------------------------------

class MyEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {editorState: EditorState.createEmpty(), toolTip:{opacity:false, target:null,width:0},
        elementForTip:'',tipObject:{linkvalue:'',linkouter:false,}, editblock:{}};
        //this.plugins = [addLinkPlugin];

        this.onChange = editorState =>  { this.setState({editorState});}
    }

     focus = () => { this.editor.focus();    };

    handleKeyCommand = command => { const newState = RichUtils.handleKeyCommand(
            this.state.editorState, command);
        console.log("handleKeyCommand", newState);
        if (newState) {this.onChange(newState);  return "handled"; }
        return "not-handled";
    };
    addHR = () => {
       // onAddHRline(this.state.editorState,this.onChange);
       const { editorState } = this.state;
        this.onChange(
            addHorizontalRule(editorState),
        );
    };
    blockRenderer = (block) => {
        const { editorState } = this.state;
        if (block.getType() === 'atomic') {
            const contentState = editorState.getCurrentContent();
            const entityKey = block.getEntityAt(0);

            const entity = contentState.getEntity(entityKey);
            if (entity && entity.type === 'HORIZONTAL_RULE') {
                return {
                    component: DividerBlock,
                    editable: false,
                };
            }
        }
        return undefined;
    };

    onStyleClick = (e) => {
        const { target } = e;
        //console.log(target.getAttribute('title'),this.state.editorState)
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, target.getAttribute('title')));
    };
    toggleBlockType = blockType => {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    };
    alignSwitch=(alignment)=>{
        this.onChange(ExtendedRichUtils.toggleAlignment(this.state.editorState, alignment));
    };
    undoRedoHandler=(param)=>{
      param? this.onChange(EditorState.undo(this.state.editorState)):
          this.onChange(EditorState.redo(this.state.editorState));
    };
    addLink= (e)=>{
       // console.log('fdgf',this.state.toolTip.opacity);
        const url=getLink(this.state.editorState);
        const tipObject = {...this.state.tipObject};
        if(url.url) { tipObject.linkvalue = url.url;tipObject.linkouter = url.type === 'LINK'; }
        const toolTip = {...this.state.toolTip};toolTip.opacity = !this.state.toolTip.opacity;
        toolTip.target=e.target; toolTip.width='300px';
        const elementForTip=()=>(<LinkCreator valueTip={this.state.tipObject}
                                         closeTip={this.closeTip} onChange={this.onChangeLink}/>);
        this.setState({toolTip,elementForTip,tipObject,colorList:!this.state.colorList});
    };
    onChangeLink=(value, target)=>{
        const tipObject= {...this.state.tipObject};
        tipObject[target]=value;
        console.log('target',target,tipObject,value);
        this.setState({tipObject});
        //this.setState({[target]:value.value});
       // this.setState(function(prevStates,props){return{tipObject:tipObject}})
        console.log('this.state.tipObject',this.state.tipObject);
    };
    closeTip= (status)=>{
        const toolTip= {...this.state.toolTip}; toolTip.opacity=false;
       // this.setState({toolTip});
        const url=status!=='clear'?this.state.tipObject.linkvalue:'';
        onAddLink(this.state.editorState,this.onChange,url,this.state.tipObject.linkouter);
        const tipObject= {...this.state.tipObject}; tipObject.linkvalue='';
         this.setState({tipObject,toolTip});
        console.log('tipObject',this.state.tipObject)
    };
    closeAllTips=(status)=>{         let toolTip;
        switch(status){
            case 'all':
                 toolTip= {...this.state.toolTip}; toolTip.opacity=false;
                this.setState({toolTip,colorList:!this.state.colorList}); break;
            case 'tip':
                 toolTip= {...this.state.toolTip}; toolTip.opacity=false;
                this.setState({toolTip}); break;
            default:
        }
        this.focus()
    };

    styleColor=async (toggledColor)=>{
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        let nextContentState = editorState.getCurrentContent();
        styleColorButton.forEach((style) => {nextContentState = Modifier.removeInlineStyle(nextContentState, selection, style.name);});
        const newcontent = EditorState.push( editorState, nextContentState, "remove-inline-color-style");
        await this.onChange(newcontent);

    if(toggledColor!=='clear')   {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, toggledColor));
    }
//setTimeout(()=>{this.moveFocusToStart(this.state.editorState)},1000)
 //this.focus();
    };
     /*moveFocusToStart=(editorState)=> { //zamena focusa
        const selectionState = editorState.getSelection();
        return EditorState.forceSelection(
            editorState,
            selectionState.merge({
                anchorOffset: 3,
                focusOffset: 6,
            }),
        );
    }*/


    replaceQuotes=async ()=>{
        const { editorState } = this.state;
        let newcontent =Replace(editorState, '\\S"','»',{start:1,end:0});
        newcontent =Replace(newcontent, '"\\S','«',{start:0,end:1});
        await this.onChange(newcontent);
        this.focus();
    };
    handlerImport=(txtelem)=>{
       // const txtelem=document.getElementById('bodycomplete').innerHTML;
        const blocksFromHTML = converterImport(txtelem.text); // <-- [1]
       this.setState( {editorState: EditorState.createWithContent(blocksFromHTML),
           editblock:{type:txtelem.type,id:txtelem.id}});
      //  console.log('blocksFromHTML',blocksFromHTML)
    };
    closerEditMode=()=>{this.setState( {editblock:{}})};

    componentDidMount() {
        if(this.props.htmlEditBlock && this.props.htmlEditBlock.text) this.handlerImport(this.props.htmlEditBlock);

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
       if (this.props.htmlEditBlock !== prevProps.htmlEditBlock) {
           if(this.props.htmlEditBlock){
               this.props.htmlEditBlock.text? this.handlerImport(this.props.htmlEditBlock)
                   :this.setState({editorState: EditorState.createEmpty(),editblock:{}})
           }
            //console.log('htmlEditBlock update', this.props.htmlEditBlock);
        }
    }

    render() {
        const opacity=this.state.toolTip.opacity;
        const clickOnEditor=()=>this.closeAllTips('all');
        const {editblock}=this.state;
        const typButtonExpImp=editblock.id===undefined?'no': editblock.type==='text'?editblock.id: 'no';
        const style=this.props.cls==='hidden'?{display:'none'}:{};
        return (
            <div className={classes.draftmain} style={style}>
                <Editorbuttons cls={classes.draftpanel}
                    editState={this.state.editorState}
                    blockStyleList={true}  blockStyleHandler={this.toggleBlockType}
                    styleButton={['bold','italic','underline','strikethrough','code']}
                    styleHandler={this.onStyleClick} alignHandler={this.alignSwitch} buttonHr={true} handlerHr={this.addHR}
                    addLink={true} linkHandler={this.addLink}  undoRedo={true} undoRedoHandler={this.undoRedoHandler}
                     buttonGroup={true} buttonGroupCurrent={0} ButtonGroupList={styleColorButton} closerTip={this.closeAllTips}
                               buttonGroupHandler={this.styleColor} colorListSwitch={this.state.colorList}
                               getInlineStyle={getInlineStyle} colorStyleMap={colorStyleMap}
                    replaceQuotes={true} replaceQuotesHandler={this.replaceQuotes}
                     exportImport={typButtonExpImp} handlerExport={this.props.addHandler} closerEditMode={this.closerEditMode}
                               handlerImport={this.handlerImport}
                />
                {
                    opacity ?
                        <ToolTipPush options={this.state.toolTip} element={this.state.elementForTip} />
                        : null
                }
                <div  className={classes.draftdiv} onClick={clickOnEditor}>
                    <Editor editorState={this.state.editorState}
                            onChange={this.onChange}
                            handleKeyCommand={this.handleKeyCommand}
                            blockStyleFn={myBlockStyleFn}
                            blockRenderMap={extendedBlockRenderMap}
                            blockRendererFn={this.blockRenderer}
                            customStyleMap={customStyleMap}
                            decorators={createLinkDecorator}
                            ref={(element) => {
                                this.editor = element;
                            }} />
                </div>

                <div >
                </div>


            </div>
        );
    }

}
export default MyEditor;

