import React, {useEffect, useState,useCallback} from "react";
import { useDispatch, useSelector } from 'react-redux';
import {getListArticles,getArticlesInList,getOneArticle} from "../../../store/actions/frontActions";
import {iconArt,iconRasdel} from "../../UI/Iconart/iconart";
import {FullArticle} from "./FullArticle";
import Sets from '../../adm/Sets/sets';
import classcom from '../../UI/Styles/Stylecommon.module.css';
import classes from "./Blog.module.css";
import {Link} from "react-router-dom";
import LoaderFront from "../../UI/Loaderfront/loaderfront";
import Helmhead from "../Blog/Helmhead";
import Error404 from "../Blog/404";
import Empty from "../empty/Empty";
import {setScrollArt, setUrlSearch} from "../../../store/reducers/frontslice";
import Imgonload from "../../UI/Imgonload/Imgonload";
import {converEmoji} from "../../adm/Sets/utils";
const News=props=>{

    const dispatch = useDispatch();
    const selector = useSelector((state) => state.frontreducer.articles);
    const loading = useSelector((state) => state.frontreducer.loading);
    const visualArt = useSelector((state) => state.frontreducer.visualArt);
    const article = useSelector((state) => state.frontreducer.currentarticle);
    const idArticle = useSelector((state) => state.frontreducer.idcurrentarticle);
    const scrollArt = useSelector((state) => state.frontreducer.scrollArt);
    const pageArticle = useSelector((state) => state.frontreducer.pageArticle);
    const indexCount = useSelector((state) => state.frontreducer.indexCount);
    const rasdelQuery = useSelector((state) => state.frontreducer.rasdelQuery);
    const error404 = useSelector((state) => state.frontreducer.error404);
   // const rasdelType = useSelector((state) => state.frontreducer.rasdelQuery.type);
    const [artValue,setArt]=useState(0);
    const [rasValue,]=useState(props.param);
    const [arrNews,setArrNews]=useState([]);
    const [showEmpty,setShowEmpty]=useState(0);

   // console.log('News arrNews',arrNews);
    let visualNext=indexCount[0] < indexCount[1] || (indexCount[2] && indexCount[2].length)?1:0;

    const getMoreArt=useCallback((param,pageArticle)=>{
        const arr=indexCount[2] ||[];
        const newparam={...param,arrIds:arr};
       // console.log('News getMoreArt', newparam,indexCount)
        dispatch(getListArticles({param:newparam,pageArticle:pageArticle+1}));
    },[dispatch,indexCount]);

    const makeOneArt=useCallback((elem)=>{
        const goToSearch=()=>{dispatch(setUrlSearch({urlSearch:1}));};
        const goToRasdel=()=>{dispatch(setScrollArt({scrollArt: ''}));};
        return makeArtTag(elem,setArt,goToSearch,goToRasdel);
    },[dispatch]);

    useEffect(() => { //useLayoutEffect
       // console.log('ScrollArt layout', scrollArt,Sets.remsite)
        if (!visualArt && scrollArt && loading) {
            if (document.getElementById(scrollArt)) {
                //document.getElementById(scrollArt).scrollIntoView({ behavior: "smooth" })
                const element = document.getElementById(scrollArt);
                const offset = 130;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({left:0, top:offsetPosition,behavior: 'smooth'});
            }
        }
        if(visualArt) window.scrollTo(0,0);
    },[scrollArt,visualArt,loading]);

    useEffect(() => {
        if(!props.param.art){ //not list of arts
          //  console.log('props.path',props.path)
            dispatch(getListArticles({param: props.param}));
        } else{
            if(!rasValue.type){
                //console.log('rasValue.type exist rasdel',rasValue.type);
                dispatch(getListArticles({param: {type:'news'},pageArticle:0}));
            }
            dispatch(getOneArticle({...props.param}));
        }

    },[dispatch,props.param,rasValue.type]);

    useEffect(() => {
        if(artValue) {dispatch(getArticlesInList(artValue));

            setArt(0);
        }
    },[dispatch,artValue]);

    let newPath=(props.path.pathname+"/?page="+(pageArticle+2)).replace('//', '/');

    useEffect(()=>{

        const addOnlyNew=(newArr)=>{
            const arrNewsOld=selector.length<arrNews.length?[]:[...arrNews]; let swtch=0;
            newArr.forEach((elem,idx)=>{
                if(arrNewsOld.length<(idx+1)) {arrNewsOld.push(makeOneArt(elem));swtch=1}
                else {
                    if (arrNewsOld[idx].id!==elem.id) {arrNewsOld[idx]=makeOneArt(elem);swtch=1}
                }
                if(arrNewsOld[idx].id===elem.id && arrNewsOld[idx].view!==elem.view){arrNewsOld[idx]=makeOneArt(elem);swtch=1}
            });
            if(swtch) setArrNews(arrNewsOld);
        };
      //  console.log('News addOnlyNew',selector);
        if(selector.length){addOnlyNew(selector);}
        else{if(arrNews.length)setArrNews([]); window.scrollTo({left:0, top:0}); setShowEmpty(1);}
    },[selector,setArrNews,arrNews,makeOneArt]);

    let artOrList=visualArt && loading?<FullArticle id={idArticle} objArt={article} type={"one"} forlink={null}/>
    :arrNews.length && !visualArt?arrNews.map(elem=>elem.txt):
            !loading?null:<Empty param={rasdelQuery.type} showEmpty={showEmpty}/>;

    let buttonDown=visualNext && arrNews.length && !visualArt?buttMoreArticle(1,()=>getMoreArt(props.param,pageArticle),newPath):null;

    let loader=!loading? <LoaderFront/>:buttonDown;
    let viewer=error404?<Error404/>:artOrList;



    return ( <>
            <Helmhead path={props.path}/>
            {viewer}
            {loader}
       </>
       )
};



export default News;

/*onst makeList= (arr, setArt,path,action,visualNext)=>{
    return <>
        {makeListArt(arr, setArt)}
        {visualNext?buttMoreArticle(1,action,path):null}
    </> todo  sweater in drawer
};*/

const makeArtTag=(elem, setArt,goToSearch,goToRasdel)=>{
    let newput; let put=''; let ret=null;
    if(elem.put.length>0){ newput=[...elem.put]; newput.shift(); put=newput.join('/')+'/a/'+elem.nameurl; }
    const header=<Link to={'/'+put} >{elem.namearticle}</Link>;
    const iconart =iconArt(elem,goToSearch);
    const iconrasdel =iconRasdel(elem,newput.join('/'),goToRasdel);
    if(!elem.view) {
        const picture = elem.picture ?
            <div className={classes.headerpict}>
                <Imgonload alt={elem.namearticle} src={Sets.pictsite + '/' + elem.picture} type={'img'}
                params={{radius:'10px',count:1,fill:true,height:'9rem',width:'15rem'}}/>
                {/*<img alt={elem.namearticle} src={Sets.pictsite + '/' + elem.picture}/>*/}
            </div> : null;
        put='/';
        if(elem.put.length>0){ newput=[...elem.put]; newput.shift(); put=newput.join('/'); }
        ret= <div key={elem.id} className={classes.article} id={elem.id+'_'+elem.nameurl}>
            <div className={classes.header}>{header}</div>
            <div className={classes.headerblock}>
                {picture}
                <div className={classcom.articlebody}>{converEmoji(elem.text,'from')}</div>
                <div className={classes.iconblock}>{iconrasdel}</div>
            </div>
            <div className={classes.outblock}>
                <div className={classes.outart} onClick={() => setArt(elem.id)}>Развернуть...</div>
                <div className={classes.iconartinblock}>{iconart}</div>
            </div>
        </div>;
    } else{

        const formaTxt=<FullArticle id={elem.id} objArt={elem} type={"inner"} forlink={'/'+put}/>;//fullArticle(elem.id,elem,"inner");
        ret= <div key={elem.id} className={classes.articledown} id={elem.id+'_'+elem.nameurl}>
            <div className={classes.headerblockdown}>
                <div className={classes.iconblock}>{iconrasdel}</div>
                <div className={classes.headercenter}>{header}</div>
                <div className={classes.iconartinblock}>{iconart}</div>
            </div>
            <div>{formaTxt}</div>
        </div>
    }
    return {id:elem.id,txt:ret,view:elem.view}
};



const buttMoreArticle=(switcher,action,path)=>{
    //console.log('--path',path)
    return <div className={classes.morearticle}>
        <button onClick={action} data-url={path} ><a href={path} onClick={(e)=>{e.preventDefault();return false;}}>Показать ещё</a></button>
    </div>;
};
