import React from "react";
import classes from './Header.module.css';
import Button from "../../../components/UI/Button/Button";

const Header=props=>{
    return (
        <div className={classes.Header} style={props.style}>
            { props.header.page
                ? <div className={classes.head}>{props.header.page}</div>
                : null
            }
            { props.header.user
                ?<div > <div className={classes.user}>Вы работаете под пользователем {props.header.user}</div>
                <Button type="adminprimary" onClick={props.logout}>Выйти</Button>
                </div>
                : null
            }

        </div>
    );
};

export default Header;

//<div > <div className={classes.user}>Ошибка сессии. Выйдите и зайдите заново.</div>
//                     <Button type="adminprimary" onClick={props.logout}>Выйти</Button>
//                 </div>