import React from "react";
import classes from './Urlselector.module.css'
import Button from "../Buttonpict/Buttonpict";

const Urlselector=props=>{
    const myRef = React.createRef();
    const clsbutton=classes.buttonel;
    const getTextArea=(type)=>{props.action(null,type)};
        const button=props.arrbutton && Array.isArray(props.arrbutton)?
            props.arrbutton.map(elem=>{
                switch (elem){
                    case 'google': return <Button onClick={()=>{getTextArea(elem)}} size={clsbutton} cls={elem}
                                           key={elem} title={'Make Link '+elem}/>;
                    case 'cancel': return <Button onClick={()=>{props.action(null,'changeareavalue','')}} size={clsbutton} cls={'close'}
                                                  key={elem} title={'clear'}/>;
                    default:return null;
                }
            })
            :null;

    return(
        <div> {button}
            <div className={classes.blocktextarea}><label className={classes.label}>Введите url:</label>
                <textarea onChange={(e)=>{props.action(e,'changeareavalue',e.target.value)}}
                       className={classes.areaurl} ref={myRef}  value={props.areavalue}/></div>
        </div>
    );
};

export default Urlselector;