import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
//import { compose,applyMiddleware} from 'redux';
import { configureStore } from "@reduxjs/toolkit"
import {Provider} from 'react-redux';
import rootReducer from "./store/reducers/rootReducer";
//import thunk from "redux-thunk";

/*const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;*/

/*const store=createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
        );*/
const store=configureStore(
    {reducer:rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        })
    });

const app=(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
const rootElement = document.getElementById("root");
ReactDOM.createRoot(rootElement).render(app);
/*
ReactDOM.render(app,
  document.getElementById('root')
);
*/


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
