import News from "../components/front/Blog/News";
import React  from "react";
import { useSearchParams } from "react-router-dom";
//import {useDispatch, useSelector} from "react-redux";


const ParserPath=props=>{
    const [searchParams] = useSearchParams();
   // const dispatch = useDispatch(); {useEffect, useState}
   // const selector = useSelector((state) => state.frontreducer.headertype);
   // const [type,setType]=useState(selector);

    let obj={type:'error404'};
    const arrPut=props.path.pathname.split('/').filter(elem=>{return elem.length});
   // console.log('selector arrPut',arrPut,props.path);
    switch(arrPut.length){
        case 1: if(arrPut[0]==='search') {
         //  console.log('searchParams',searchParams.get('tags'))
            const query={tags:searchParams.get('tags'),icons:searchParams.get('icons'),word:searchParams.get('word')}
            obj={type:'search', arrPut,art:0,query,useIds:1}}
            else{obj={type:'rasdel', arrPut,art:0,useIds:0}} break;
        case 3:
            if(arrPut[1]==='a') {
                obj={type:'rasdel', arrPut,art:1,useIds:0};
            } break;

        default:obj={...obj, arrPut,art:0};break;
    }


   // console.log('path',props.path.pathname);

    return <News path={props.path} param={obj}/>;

    // rasdel/a/article
};

export default ParserPath;