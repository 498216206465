import React from "react";
import classes from './Imguploader.module.css';

export const ImagePreview=props=>{
    const current=props.current; const arr=props.arr;
    if (arr) {
        const element = arr[current];
        return (<React.Fragment>
            <div className={classes.imgdone}><img alt={''} src={element.pathsmall}/></div>
            <div className={classes.perehodpicture}>
                <button className={classes.perehodleft} onClick={()=>props.perehod(-1)}
                        title={'К предыдущей'}>{null}</button>
                <div className={classes.imgnote}>{element.note} <span> {' '} ({props.current+1} из {arr.length})</span> </div>

                <button className={classes.perehodright} onClick={()=>props.perehod(1)}
                        title={'К следующей'}>{null}</button>
            </div>
        </React.Fragment>)
    } else {return false;}
};
const makeCropValue=(changes,origSizes)=>{ // расчет новых размеров оригинальной картинки
    const crop={}; let newMaxsize;
    switch(changes[0]){
        case 1:
            const kff=parseFloat(origSizes[0])/parseFloat(origSizes[1]);
            if(kff>1){ newMaxsize=origSizes[0]>changes[1]?changes[1]:origSizes[0];
                crop.width=newMaxsize; crop.height=origSizes[1]/(origSizes[0]/newMaxsize);
            }
            else{ newMaxsize=origSizes[1]>changes[1]?changes[1]:origSizes[1];
                crop.height=newMaxsize; crop.width=origSizes[0]/(origSizes[1]/newMaxsize);
            }
            break;
        default: crop.width=crop.height=100;
    }
    return crop;
};

export const getCroppedImgOrig=function(image, arrCrop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth ;
    const scaleY = image.naturalHeight ;
    const crop=makeCropValue(arrCrop,[scaleX,scaleY]);
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0,0,  crop.width, crop.height);

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
            if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
          //  window.URL.revokeObjectURL(this.fileUrl);
                let fileUrl = window.URL.createObjectURL(blob);
            resolve({croppedImageUrl:fileUrl,blob:blob,newsize:crop});
        }, 'image/jpeg');
    });
}