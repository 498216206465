import React, { Component } from "react";

export class Autocomplete extends Component {

    static defaultProperty = {
        suggestions: []
    };
    constructor(props) {
        super(props);
        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: "", userKey:0,
            visible:0,
            dblClick:0
        };
    }
    getResult=()=>{
        if(this.state.userKey){   this.props.handler('save',{id:this.state.userKey,txt:this.state.userInput});
            this.setState({userInput: ''})
        }
    }

    onChange = e => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;

        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.txt.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value,
            dblClick:0
        });
    };

    onClick = e => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
            userKey: e.currentTarget.id
        },()=>this.getResult());
    };
    onClear=()=>{
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput:'', userKey: 0
        });
    };
    onDoubleClick=(e)=>{
        const { suggestions } = this.props;
        const userInput = '';
        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.txt.toLowerCase().indexOf(userInput.toLowerCase()) !== -1
        );

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value,
            dblClick:1
        });
    }
    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        if (e.keyCode === 13) {
            if(!filteredSuggestions.length) {this.onClear(); return 0;}
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion].txt,
                userKey: filteredSuggestions[activeSuggestion].id
            },()=>this.getResult());
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        } else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }

    };
    componentDidUpdate (prevProps) {
        if(prevProps.visual!==this.props.visual){
            this.setState({ visible: this.props.visual });
        }
    }

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            onDoubleClick,
            /*onClear,*/
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            }
        } = this;
        let suggestionsListComponent;
        if ((showSuggestions && userInput) || this.state.dblClick) {
            console.log({filteredSuggestions})
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className={this.props.classArray.suggestions}>
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;

                            if (index === activeSuggestion) {
                                className = this.props.classArray.suggestionactive;
                            }

                            return (
                                <li className={className}
                                    key={suggestion.txt} onClick={onClick} id={suggestion.id}>
                                    {suggestion.txt}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                /*suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions</em>
                    </div>
                );*/
            }
        }

        return (
            this.state.visible
            ?<div className={this.props.classArray.ulblock}>
                <input
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onDoubleClick={onDoubleClick}
                    value={userInput}
                />
                {suggestionsListComponent}
            </div>
                : null
        );
    }
}

export default Autocomplete;
