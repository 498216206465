import React from "react";
import classes from './404.module.css';

const Error404=()=>{
    return (
        <div className={classes.flex}>
            <div className={[classes.block404,classes.text].join(' ')} title={"Не можем найти страницу"}>Не можем найти страницу</div>
            <div className={classes.block404} title={"404"}>404</div>

        </div>
    )
};
export default Error404;