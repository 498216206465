import React from "react";
import {NavLink} from 'react-router-dom';
import Sets from '../../Sets/sets';
import classes from './Menup.module.css';
import Sorter from "../../../UI/Sorter/Sorter";  // in RASDEL

const MenuButtUp=props=>{
    const record={  // get heads for newrecord
        id:'noid_'+String(Date.now()),
        empty:false,
        sort:Math.round(Math.random()*10),
        name: '',
        namearticle: '',
        elementname:'',
        email:'',
        nametag:'',
        nameicon:'',
        iconurl:'',
        color:'none',
        nameurl:'',
        elementurl:'',
        rol:0,
        rolarticle:0,
        rolelement:0,
        roluser:0,
        redirect:"", vyvod:false,
        note:'',parametr:'',value:'', parametres:'',
        picture:0,
        anons:'',leftpanel:0,rightpanel:0,
        comment:[],
        nameuser:'',ip:'',device:'',stopdevice:0,stopip:0,
        edit: true,
        delete: false
    };
const getFields=(record, headkey)=>{
    const obj={};
    const headnames=props.headnames[headkey];
    console.log('headkey',headkey)
     headnames.map(item=>{if(item in record){obj[item]=record[item]} return true;});
    obj.delete=false; obj.edit=false; obj.id='noid_'+String(Date.now());
    return obj;
};

    //const action=()=>props.action(props.table,getFields(record));
    //<div className={classes.menup} onClick={action}>  {props.head[0]} </div>
    let adm = '/'+Sets.adm;
    let parts;
    if (Array.isArray(props.partslocation)){ //  партс можно не передавать
        const arrayBread=props.partslocation.map(item=>{adm+='/'+item.nameurl; return {adm, nameurl:item.nameurl,name:item.name ||'rasdel'}});
        parts=()=>arrayBread.map((item,index)=> (
        <NavLink to={item.adm}
                 className={(navData) => (navData.isActive ? classes.active : null)}
                 key={`${item.nameurl}-${index}`}>{item.name}</NavLink>));
    }
    const resParts=parts? parts():null;
    // menu новый... props.head[0]
    const keys = Object.keys(props.head);
    const mapCreateLink=keys.map((elem,index)=>(<span key={index} className={classes.menup} onClick={()=>props.action(elem,getFields(record,elem))}>
        {props.head[elem]} </span>));
    const order=props.table==='tag'?{field:'nametag',title:'упорядочить по имени'}:null;
    return(
        <div className={classes.flexmenu}>
            <div className={classes.left}>
                <div className={classes.inlineall}>{mapCreateLink}</div>
                <React.Fragment>
                    {resParts?
                    <div className={classes.breadcrumbs}>
                         <span className={classes.rasdelname}>РАЗДЕЛЫ: </span>{resParts}
                    </div>
                        : null}
                </React.Fragment>
            </div>
            <div className={classes.right}><Sorter actionSort={props.actionSort} table={props.table}
                                                   order={order} sorter={props.sorter}/>
            </div>
        </div>
    );
};

export default MenuButtUp;