import React, {Component} from "react";
import classes from './CommentAdmin.module.css';
import {connect} from "react-redux";
import Buttonpict from "../Buttonpict/Buttonpict";
import {changeComments} from "../../../store/actions/adminrasdel";
import {formatRecords} from'./MakeCommentList';
import AddCommentAdm from "./AddComment";
import SimpleBar from "simplebar-react";
import {converEmoji} from "../../adm/Sets/utils";

class CommentAdmin extends Component{

    state={list:[],users:[],currAnswer:0,editcomm:'',editCommHeight:'auto' };

    myRef=React.createRef();

    localCheckHandler=(obj)=>{
        switch(obj.status) {
            case 'checkcomms':  this.props.changeComments(obj); break;//no
            case 'showreply': this.newAddComm(obj);break;//yes

            case 'hiddencomm': this.props.changeComments(obj);break;//yes
            case 'checkcomm': this.props.changeComments(obj);break;//yes
            case 'lockuser': this.props.changeComments(obj);break;//yes
            case 'lockip': this.props.changeComments(obj);break;//yes

            case 'addnewcomm': this.scrollToRef();break;
            case 'editcomm': this.markAction(obj);break;//yes
            case 'markdelcomm': this.markAction(obj);break;//yes
            case 'cancel': this.markAction(obj);break;//yes
            case 'deletecomm': this.props.changeComments(obj);break;//yes

            case 'edittext': this.setState({editcomm:obj.txt});
                              this.setTxtAreaHeight('editCommHeight',obj.target); break; //scrollHeight offsetHeight
            case 'addcomm':   const newobj={...obj}; newobj.text=converEmoji(newobj.text,'to')
                this.props.changeComments(newobj);this.markAction(newobj); break; //yes

            case 'savecomm':   {//converEmoji(this.state.editcomm,'to')
                const newobj={...obj,txt:converEmoji(this.state.editcomm,'to')}; this.props.changeComments(newobj);this.markAction(obj)} break;
            default: console.log('no any action for it',obj); return;
        }
    };
    setTxtAreaHeight=(name,obj)=>{
       // console.log('scroll offset',obj.scrollHeight,obj.offsetHeight)
        const height=obj.scrollHeight>=obj.offsetHeight?obj.scrollHeight+6+'px':obj.offsetHeight+'px';
        this.setState({[name]:height});
    };

    scrollToRef=()=>{
        const currRef=this.state.currAnswer;
        this.setState({currAnswer:!this.state.currAnswer})
        if(!currRef) {this.myRef.current.scrollIntoView()}
    };

    switchAnswer=(obj)=>{console.log(obj.id,obj.status,obj)}; // no need

    editListComm=(obj)=>{
      const state=[...this.state.list]; let txt='';
      state.forEach(elem=>{if(elem.id===obj.id) {elem.edit=!elem.edit; txt=elem.text} else{elem.edit=false;}});
        this.setState({list:state,editcomm:txt,editCommHeight:'auto'});
    };
    newAddComm=(obj)=>{
        const state=[...this.state.list];
        state.forEach(elem=>{if(elem.id===obj.id) {elem.showreply=!elem.showreply; } else{elem.showreply=false;}
            elem.edit=0;elem.delete=0;});
        this.setState({list:state});
    };
    markAction=(obj)=>{
        const state=[...this.state.list];
        let txt='';
        if(obj.field==='delete')
            {state.forEach(elem=>{if(elem.id===obj.id) {elem[obj.field]=!elem[obj.field]; } else{elem[obj.field]=false;}
                elem.showreply=0;elem.edit=0;});
                this.setState({list:state});}
        if(obj.field==='edit')
        {   state.forEach(elem=>{if(elem.id===obj.id) {elem[obj.field]=!elem[obj.field]; txt=converEmoji(elem.text,'from') }
        else{elem[obj.field]=false;}
            elem.showreply=0;elem.delete=0;});
            this.setState({list:state,editcomm:txt,editCommHeight:'auto'});
        }
        if(obj.field==='cancel')
            {state.forEach(elem=>{elem.delete=false;elem.edit=false;});
            this.setState({list:state});}
        if(obj.field==='newcomm')
            {state.forEach(elem=>{elem.delete=false;elem.edit=false;});
            this.setState({currAnswer:0});}
        if(obj.field==='reply')
        {state.forEach(elem=>{elem.showreply=0});
            this.setState({list:state});}
    };

    outputButton=
        <div className={classes.sizebutton}>
        <Buttonpict  cls={'class-true'} onClick={()=>this.localCheckHandler({status:'checkcomms'})} disabled={false} title={'checked comments'}/>
            <Buttonpict  cls={'note'} onClick={()=>this.localCheckHandler({status:'addnewcomm'})} disabled={false} title={'add comment'}/>
        </div> ;

    getListComms=()=>{
        const users=[]; let list=[];
        if(this.props.commusers.length){
            this.props.commusers.forEach(elem=> {
                users.push({...elem, stopip:!!this.props.commip.find(ip=>ip===elem.ip)})
            })
        }

        if(this.props.comments.length){
            this.props.comments.forEach(elem=> {
                list.push({...elem,slaveRec:[],level:0,data:convertDate(elem.data),showreply:0,edit:0,delete:0});
                if(elem.slaveRec.length){list=list.concat(this.threadArray(elem.slaveRec,1))}
                //  console.log (list,{...elem,slaveRec:[]})
            })
        }
        this.setState({list:list,users:users});
    };
    threadArray=(arrElem,level)=>{
        let list=[];
        arrElem.forEach(elem=>{
            list.push({...elem,slaveRec:[],level:level,data:convertDate(elem.data),showreply:0,edit:0,delete:0});
            if(elem.slaveRec.length){list=list.concat(this.threadArray(elem.slaveRec,level+1))}
        });

        return list;
    };

    async componentDidMount() {
        this.getListComms();
    }
    async componentDidUpdate(prevProps) {
        if (this.props.sidehead.id !== prevProps.sidehead.id ) {
            this.getListComms();
        }
        if (this.props.comments !== prevProps.comments ) {
            this.getListComms();
        }
         if (this.props.editCommHeight === 'auto' && this.state.editcomm) {

         }
     }

/*<MakeCommentList users={this.props.commusers} comments={this.props.comments}
ip={this.props.commip} handler={this.localCheckHandler}/>*/

    render() {
        const listComm=this.state.list.length?formatRecords(this.state,this.localCheckHandler):null;
        return <div>{this.outputButton}
            { this.props.comments.length?
                <div className={classes.commentblock}>
                    <SimpleBar style={{ maxHeight: '70vh' }}>
                        {listComm}
                    </SimpleBar>
                    </div>
            :<div className={classes.emptycomments}>пусто</div>}
           <div ref={this.myRef}> {this.state.currAnswer?
                <div><AddCommentAdm  handler={this.localCheckHandler} master={0} field={'newcomm'}/></div>
                :<div>{null}</div>

           }</div>

        </div>
    }
}

function mapStateToProps(state) {
    return {
        comments:state.adminrasdel.comments,
        commusers:state.adminrasdel.userscomms,
        commip:state.adminrasdel.commip,
        sidehead:state.adminrasdel.sidehead,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeComments:(valueObj)=>dispatch(changeComments(valueObj)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CommentAdmin)

const convertDate=(date)=>{
    const theDate = new Date( Date.parse(date));
    return theDate.toLocaleString();
};