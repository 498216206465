import React, { Component } from "react";
import classes from './Tags.module.css';
import Autocomplete from "../Autocomplete/Autocomplete";
import Button from "../Buttonpict/Buttonpict";
import ElementWButton from'../ElementWButton/ElementWButton'
/*"proxy": "http://localhost:3001",*/

class Tags extends Component{
    state={tagslist:[]};

    suggestions=[{id:1,txt:"White"}, {id:2,txt:"Black"},{id:3,txt:"Green"},{id:4,txt:"Yellow"},{id:4,txt:"Blue"}];
    handler=(status,data)=>{
        switch(status){
            case 'save': this.props.handler('tags',{type:'save',data}); break;
            case 'delete': this.props.handler('tags',{type:'delete',data}); break;
            default:break;
        }
    };

    render() {
        const tags=this.props.tags && this.props.tags.arr? this.props.tags.arr.map((elem,index)=>{return <ElementWButton cls={'close'} key={elem.id}
             onClick={() => {this.handler('delete', {id: elem.id})}} title={'Delete'+elem.txt} >{elem.txt}</ElementWButton>})
        :null;

        return ( this.props.tags
            ?<div className={classes.tagsauto}><Button onClick={() => {
            this.props.handler('tags', {type: 'visible'})
        }} cls={'tag'} title={'add tags'}/>
            <Autocomplete visual={this.props.tags.hidden}
                          suggestions={this.props.tagslist} handler={this.handler}
                          classArray={{
                              suggestions: classes.suggestions, suggestionactive: classes.suggestionactive,
                              ulblock: classes.ulblock
                          }}
            />
            {tags}
        </div>
        :null)
    }
}

export default Tags;