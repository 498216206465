import React from "react";
import classes from './Sorter.module.css';

const Sorter=props=>{
    const {actionSort, table, sorter}=props;
    const dopclass=sorter==='ASC'?'asc':'desc';
    const order=props.order?<Sortbutton type={'list'} field={props.order.field} title={props.order.title} actionSort={actionSort} table={table}/>
        :null;
    // check sorting
    return (
      <div className={classes.sorter}>
          {order}
          <Sortbutton type={'sortup'}  title={'Поднять вверх'} actionSort={actionSort} table={table} field={null}/>
          <Sortbutton type={'sortdown'}  title={'Опустить вниз'} actionSort={actionSort} table={table} field={null}/>
          <Sortbutton type={'changeorder'} dopclass={dopclass} title={'Порядок сортировки'} actionSort={actionSort} table={table} field={null}/>
      </div>
    );

};

export default Sorter;

const Sortbutton=props=>{
    const cls=[classes.sortbutton,classes[props.type]];
    if(props.type==='changeorder') {cls.push(classes[props.dopclass])}
    const action=()=>props.actionSort(props.type,props.table,props.field);
    return (
        <button className={cls.join(' ')} title={props.title}
                onClick={action}>{null}</button>
    )  ;

};