import React from "react";
import classes from "./iconart.module.css";
import {Link} from "react-router-dom";
import Sets from "../../adm/Sets/sets";
import {makeSearchURL} from "../../front/Search/SearchPanel";

export const iconArt =(elem,changeSearch)=> {
   return elem.iconart.length ?
        elem.iconart.map(icon=>{
          // const put='find/tag/'+icon.id;
            const put=makeSearchURL({tagArray:[],iconArray:[icon.id],wordArray:[]});
            return <div key={'icon'+icon.id} className={classes.headericonart}><Link to={put.pathname+put.search} onClick={changeSearch}
                                                                                     key={elem.name} state={{ from: "occupation" }}>
                <img alt={elem.namearticle} title={'Найти статьи с ' +icon.name} src={Sets.remsite + '/' + icon.url}/></Link></div>})
        : null
};
export const iconRasdel =(elem,put,goToRasdel)=> {

    return elem.iconrasdel.length ?
        <div className={classes.headericonras}><Link to={'/'+put} key={elem.name} onClick={goToRasdel}>
            <img alt={elem.namearticle} title={'Перейти в раздел ' + elem.rasdelname} src={Sets.remsite + '/' + elem.iconrasdel[0].url}/></Link></div> : null;

};