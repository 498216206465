import React from "react";
import classes from './Buttonrow.module.css';

const ButtonsTd=props=>{
    const arrButt=(props.buttons);
    const handler=props.handler;
    const objdata=props.objdata ||{};
    return arrButt.map((button,index)=>{
        const action=actionButton(handler,button.type,props.tbl,objdata,props.errors);
        return getButton(button,action,index);
    })
};

function getButton(button,actionButton,index) {
    const nameKey = Object.keys(button);
    const cls=[classes.buttonrow,classes[button.type]];
    return (<button key={`${nameKey}-${index}`} className={cls.join(' ')}
                        onClick={actionButton} title={button.title}>{null}</button>);

}

function actionButton(handler, type,tbl,objdata,errors){
    let name;
    switch(type){
        case 'editrecord': name='edit';  break;
        case 'deleterecord':  name='delete';  break;
        case 'markdeleterecord':  name='delete';  break;
        case 'canceledit':  name='cancel';  break;
        case 'saverecord':  name=objdata;  break;
        default: return name='non action'
    }
    return (e)=>{
        const { target } = e;
        const id=target.closest('tr').getAttribute('id');
       // console.log('objdata.isValid',objdata.isValid)
        if(type==='saverecord' && !objdata.isValid){
            handler({table: tbl, id: id, name: errors(), status: 'erroraction'});
        }else {
            handler({table: tbl, id: id, name: name, status: type});
        }
    };
}



export default ButtonsTd;