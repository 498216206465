import React, {useState,useRef,useEffect} from "react";
import classes from './Comment.module.css';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Emoji from "../../UI/Emoji/Emoji";

const AddComment=(props)=>{
    const [commentValue, setCommentValue] = useState("");
    const [opener, setOpen] = useState(false);
    const wrapperRef = useRef(null);
    const textRef = useRef(null);
    console.log('AddComment',opener)

    useDynamicHeightField(textRef, commentValue);
    useOutsideAlerter(wrapperRef, setOpen);
    const onChange = (e) => {if(!props.changer)setCommentValue(e.target.value) };

    const Clicker=()=>{setOpen(!opener); console.log('clicker')};

    const EmojInput=(obj)=>{

        setCommentValue(commentValue+' '+obj.native);setOpen(!opener);
    };
    const button= !props.changer? {text:'в печать',class:'',tip:0}:{text:'еще раз антиспам',class:classes.antispam,tip:1};
    const emogiHide=props.changer?classes.hider:'';
    const buttonHide=!props.changer?classes.hider:'';

    useEffect( ()=>{
        textRef.current.style.height = "auto";
        textRef.current.style.height = (textRef.current.scrollHeight+5) + "px";
    },[props.changer]);

    useEffect( ()=>{if(textRef && textRef.current)textRef.current.focus()},[props.changer]);
    //const tim=new Date().getTime();
    return <div className={classes.addcomment}>
        <textarea ref={textRef} onChange={onChange} className={classes.addtextarea} value={commentValue}>{null}</textarea>

        <div className={[classes.emojiicon,emogiHide].join(' ')} onClick={Clicker} title={'эмоджи'}>
            <span  role={"img"}><Emoji label="Smile" symbol="😁" /></span></div>
        {opener? <div className={classes.emojiblock} >
           <span ref={wrapperRef}>
           <Picker data={data} onEmojiSelect={EmojInput} previewPosition={'none'}
                        navPosition={'bottom'} searchPosition={'top'}/>
            </span>
         </div>
        : null}
                <div className={classes.addbuttonblock}>
                <button className={[classes.addbutton,button.class,buttonHide].join(' ')}
                        onClick={()=>props.action(commentValue,!button.tip,0)}>{'отмена'}</button>
                <button className={[classes.addbutton,button.class].join(' ')} onClick={()=>props.action(commentValue,1,1)}>{button.text}</button>
                </div>
           </div>
};
export default AddComment;

const useDynamicHeightField = (element, value) => {
    useEffect(() => {
        if (!element) return;

        element.current.style.height = "auto";
        element.current.style.height = (element.current.scrollHeight+5) + "px";
    }, [element, value]);
};


const useOutsideAlerter=(ref, setOpen)=> {
    useEffect(() => {
        const handleClickOutside=(e)=> {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref,setOpen])
}
