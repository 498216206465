import React, {Component} from "react";
import classes from './Rasdel.module.css';
import {connect} from "react-redux";
import Loader from '../../components/UI/Loader/Loader';
import Header from '../../components/adm/Parts/Header/Header';
import Menup from '../../components/adm/Parts/Menup/Menup';
import Rasdelrows from "../../components/adm/Rasdel/Rasdelrow/Rasdelrows";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {
    getAdmRasdelState, addNewRecordRasdelTable, checkerHandlerField,
    changeRecordStatus, sendErrorToBlock, findCurrentPath, admSortTable,admGetDataChilds,admRedactArticle
} from "../../store/actions/adminrasdel";
import {buttonsForAction} from "../../components/adm/Sets/utils";
import {Helmet} from "react-helmet";

class Rasdel extends Component{


    localCheckHandler=(obj)=>{ // create state localCheckHandler={this.localCheckHandler}
        console.log('obj',obj)
        switch(obj.status) {
            case 'selectrow':  this.props.checkerHandlerField(obj); break;
            case 'editrecord': this.props.changeRecordStatus(obj);break;
            case 'canceledit': this.props.changeRecordStatus(obj);break;
            case 'saverecord': this.props.changeRecordStatus(obj);break;
            case 'deleterecord': this.props.changeRecordStatus(obj);break;
            case 'markdeleterecord': this.props.changeRecordStatus(obj);break;
            case 'countelem': this.props.getDataChilds(obj);break;
            case 'commelem': this.props.getDataChilds(obj);break;
            case 'propelem': this.props.getDataChilds(obj);break;
            case 'redactarticle': this.props.redactArticle(obj);break;
            case 'erroraction': this.props.sendErrorToBlock(obj.name);break;
            case 'options': this.props.getDataChilds(obj);break;
            case 'iconvisual': this.props.getDataChilds(obj);break;
            default: console.log('no any action for it',obj); return;
        }
    };
    createButtons=()=> {
        const buttForView = buttonsForAction(['edit', 'markdelete']);
        const buttForViewCancel = buttonsForAction(['edit', 'delete', 'cancel']);
        const buttForActions = buttonsForAction(['markdelete', 'cancel', 'save']);
        const buttForActionsCancel = buttonsForAction(['save', 'delete', 'cancel']);
        return {buttForView,buttForViewCancel,buttForActions,buttForActionsCancel}
}

    sorterAllTable=async (type,tbl)=>{
        await this.props.admSortTable(type,tbl);
        await this.props.admSortTable(type,'article');
        await this.props.admSortTable(type,'element');
    };

    async componentDidMount() {
      //  console.log('Repeat',this.props.location.pathname);
        this.props.admSortTable('initorder','rasdel');
        this.props.admSortTable('initorder','article');
        this.props.admSortTable('initorder','element');
        await this.props.findCurrentPath(this.props.location.pathname,'rasdel');
        if(!this.props.locationerror.length) {
           // this.props.getAdmRasdelState(this.props.page,'rasdel',this.props.rasdel.sort);
           // this.props.getAdmRasdelState(this.props.page,'article',this.props.article.sort);
          //  this.props.getAdmRasdelState(this.props.page,'element',this.props.element.sort);
        } else{
        }
    }

    async componentDidUpdate(prevProps) { // https://ru.reactjs.org/docs/react-component.html
      //  console.log('prevProps',prevProps.page,this.props.location.pathname)
        if (this.props.location.pathname !== prevProps.location.pathname ) {
       // if (this.props.page.nom !== prevProps.page.nom) {
            await this.props.findCurrentPath(this.props.location.pathname,'rasdel');
          //  console.log('prevProps2',prevProps.page,this.props.location.pathname,this.props.page.nom)
            if(!this.props.locationerror.length) {
             //   this.props.getAdmRasdelState(this.props.page,'rasdel',this.props.rasdel.sort);
             //   this.props.getAdmRasdelState(this.props.page,'article',this.props.article.sort);
             //   this.props.getAdmRasdelState(this.props.page,'element',this.props.element.sort);
            }
        }
    }


    render() {
        const scrollrasm=!!this.props.rasdel.data.length+!!this.props.article.data.length+!!this.props.element.data.length;
        const vhSize=scrollrasm===3?'23vh':scrollrasm===2?'38vh':'80vh';
        // console.log('page',this.props.page)
        const buttonsRow=this.createButtons();
        const classRasdel=[classes.scrolrasdel].join(' ');
        const classArticle=[classes.scrolrasdel, classes.article].join(' ');
        const classElement=[classes.scrolrasdel, classes.element].join(' ');
        const headArticle=this.props.article.data.length? (<div className={classes.articlename}>СТАТЬИ</div>):null;
        const headElement=this.props.element.data.length? (<div className={classes.articlename}>ФАЙЛЫ</div>):null;
        return (
                this.props.loading
                    ? <Loader/>
                    : !this.props.locationerror.length
                        ? <React.Fragment>
                        <Helmet>
                            <title>{this.props.meta.title}</title>
                            <meta name="description" content={this.props.meta.title} />
                        </Helmet>
                            <Menup action={this.props.addNewRecordRasdelTable} table={'rasdel'} actionSort={this.sorterAllTable}
                                   headnames={{rasdel:this.props.rasdel.headNames,article:this.props.article.headNames,
                                       element:this.props.element.headNames}}
                                   partslocation={this.props.partslocation} sorter={this.props.rasdel.sort}
                                   head={{rasdel:'Новый раздел...',article:'Новая статья...',element:'Новый файл...'}}/>
                            <div className={classRasdel}>
                                {//rasdel---------
                                 }
                                <SimpleBar style={{ maxHeight: vhSize }}>
                                <table className={classes.rasdel}>
                                <Header fields={this.props.rasdel.headNames} classhead={'stinky'} lengthRecords={this.props.rasdel.data.length}/>
                                    <tbody>
                                <Rasdelrows fields={this.props.rasdel.data} heads={this.props.rasdel.headNames}
                                checkerHandler={this.localCheckHandler} buttonsRow={buttonsRow}
                                tbl='rasdel' fullpath={this.props.page.fullpath}
                                statuspicture={{state:this.props.selectpicturestate,selectpicture:this.props.selectpicture,
                                    selectype:this.props.selectpicturetype}}
                                admBlocker={this.props.admBlocker}
                                />
                                </tbody></table>
                                </SimpleBar>
                            </div>
                        {headArticle}
                        <div className={classArticle}>
                            {//article---------
                            }
                            <SimpleBar style={{ maxHeight: vhSize }}>
                            <table className={classes.rasdel}>
                                <Header fields={this.props.article.headNames}  lengthRecords={this.props.article.data.length}
                                        classhead={'article'}/>
                                <tbody>
                                <Rasdelrows fields={this.props.article.data} heads={this.props.article.headNames}
                                            checkerHandler={this.localCheckHandler} buttonsRow={buttonsRow}
                                            tbl='article' fullpath={this.props.page.fullpath}
                                            statuspicture={{state:this.props.selectpicturestate,selectpicture:this.props.selectpicture,
                                                selectype:this.props.selectpicturetype}}
                                            admBlocker={this.props.admBlocker}
                                />
                                </tbody></table>
                            </SimpleBar>
                        </div>
                        {headElement}
                        <div className={classElement}>
                        {//element---------
                        }
                            <SimpleBar style={{ maxHeight: vhSize }}>
                            <table className={classes.rasdel}>
                                <Header fields={this.props.element.headNames} lengthRecords={this.props.element.data.length}
                                        classhead={'element'}/>
                                <tbody>
                                <Rasdelrows fields={this.props.element.data} heads={this.props.element.headNames}
                                            checkerHandler={this.localCheckHandler} buttonsRow={buttonsRow}
                                            tbl='element' fullpath={this.props.page.fullpath}
                                            statuspicture={{state:this.props.selectpicturestate,selectpicture:this.props.selectpicture,
                                            selectype:this.props.selectpicturetype}}
                                            admBlocker={this.props.admBlocker}
                                />
                                </tbody></table>
                            </SimpleBar>
                        </div>
                        </React.Fragment>
                        : null
            )
    }

}
function mapStateToProps(state){
    return {
        page:state.adminrasdel.page,
        rasdel:state.adminrasdel.rasdel,
        article:state.adminrasdel.article,
        element:state.adminrasdel.element,
        loading:state.adminrasdel.loading,
        locationerror:state.adminrasdel.locationerror,
        partslocation:state.adminrasdel.partslocation,
        meta:state.adminrasdel.meta,
        selectpicture:state.adminrasdel.sidehead,
        selectpicturestate:state.adminrasdel.sidestate,
        selectpicturetype:state.adminrasdel.sidetype,
        admBlocker:state.adminrasdel.admBlocker,
    }
}
function mapDispatchToProps(dispatch){
    return {
        getAdmRasdelState: (page,table,sort)=>dispatch(getAdmRasdelState(page,table,sort)),
        addNewRecordRasdelTable: (tbl,value)=>dispatch(addNewRecordRasdelTable(tbl,value)),
        checkerHandlerField: (valueObj)=>dispatch(checkerHandlerField(valueObj)),
        changeRecordStatus: (valueObj)=>dispatch(changeRecordStatus(valueObj)),
        sendErrorToBlock: (valueObj)=>dispatch(sendErrorToBlock(valueObj)),
        findCurrentPath: (id,tbl)=>dispatch(findCurrentPath(id,tbl)),
        admSortTable: (type,tbl)=>dispatch(admSortTable(type,tbl)),
        getDataChilds: (valueObj)=>dispatch(admGetDataChilds(valueObj)),
        redactArticle: (valueObj)=>dispatch(admRedactArticle(valueObj)),
       // getSortTable: (tbl)=>dispatch(getSortTable(tbl)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Rasdel)