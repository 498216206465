import React  from "react";
import classes from './ToolTipPush.module.css';

export const ToolTipPush=(props)=>{
    const opacity=props.options.opacity;
    const target=props.options.target;
    const top= target? target.offsetTop:0;
    const left= target? target.offsetLeft:0;
    const element=props.element?props.element():null;
    //console.log(props.element);
    const style={
        width:props.options.width,
        zIndex: opacity ? 1000: -1000,
        opacity: +opacity,
        top:(top ||0)+30,
        left: (left ||0)-130,
    };
    return ( <div className={classes.tipblock} style={style}>{element}</div>
                  )
};

/*
class ToolTipPush extends Component{
    state ={element:{},parametr:{}};

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('props.valueTip','props.valueTip');
        if(prevProps.valueTip!==this.props.valueTip){
            this.setState({element:this.props.elementForTip,parametr:this.props.valueTip})
        }
    }

    componentDidMount() {
         console.log('props.valueTip','props.valueTip');
        this.setState({element:this.props.elementForTip,parametr:this.props.valueTip})
    }

     render(){
         const opacity=this.props.options.opacity;
         const target=this.props.options.target;
         const top= target? target.offsetTop:0;
         const left= target? target.offsetLeft:0;
         const style={
             zIndex: opacity ? 1000: -1000,
             opacity: +opacity,
             top:(top ||0)+30,
             left: (left ||0)-130,
         };
         return (<div className={classes.tipblock} style={style}>{this.state.element()}</div>)
     }

}
export default ToolTipPush ;*/
