import React from "react";
import Setting from "../Settings/Setting";
import { useLocation } from "react-router-dom";


const HocSetting=(props)=>{
    let path=useLocation();
    return <Setting tableput={'setting'} put={'setting'} location={path}/>
};
const HocTag=(props)=>{
    let path=useLocation();
    return <Setting tableput={'tag'} put={'tag'} location={path}/>
};
const HocUser=(props)=>{
    let path=useLocation();
    return <Setting tableput={'user'} put={'user'} location={path}/>
};
const HocIcon=(props)=>{
    let path=useLocation();
    return <Setting tableput={'icon'} put={'icon'} location={path}/>
};
const HocCommUsers=(props)=>{
    let path=useLocation();
    return <Setting tableput={'commusers'} put={'commusers'} location={path}/>
};

export  {HocSetting, HocTag, HocUser,HocIcon,HocCommUsers};