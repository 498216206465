
const common={
    path:'/api',
    namesmenu:  {rasdel:'Разделы', user:"Пользователи", setting:'Установки',tag:'Тэги', icon:'Иконки', commusers:'Комментаторы'},
    adm:'adm',
    namesite:'ColorScript',
    description:'Сайт посвящен допечатной подготовке в полиграфии. Здесь вы найдете советы по цветоделению, работе с цветом,' +
        ' автоматизации процессов через скрипты. Так же немного по web-программированию.',
    keywords:['color', 'цветоделение', 'скрипты для дизайнеров', 'допечтная подготовка'],
    imagemeta:'/logotip.jpg'
};
/*const devs={...common,
    remsite:"http://localhost:3001", //для picture 3000
    cursite:"http://localhost:3000", //2
    pictsite:"http://localhost:3000", //для picture 3000
}
const prod={...common,
    remsite:"https://api.colorscript.ru", //для picture 3000
    cursite:"https://colorscript.ru", //2
    pictsite:"https://api.colorscript.ru", //для picture 3000   #/*https://api.colorscript.ru*
}*/

const status = process.env.REACT_APP_API_STATUS;
const ret={
    ...common,
    remsite:status==='development'?"http://localhost:3001":"https://api.colorscript.ru",
    pictsite:status==='development'?"http://localhost:3001":"https://api.colorscript.ru",
    cursite:status==='development'?"http://localhost:3000":"https://colorscript.ru",
}
//export const CONST_PREF=status==='development'?"":"https://api.colorscript.ru";//http://localhost:3001";
export const CONST_PREF=status==='development'?"":"";//http://localhost:3001";

ret.remsite=status==='development'?"http://localhost:3001":"";
ret.pictsite=status==='development'?"http://localhost:3001":"";
ret.cursite=status==='development'?"http://localhost:3001":"";


export default ret;


