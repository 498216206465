import React, {useState,useRef,useEffect} from "react";
import classes from './Search.module.css';
import { createPortal } from 'react-dom';
import SearchPanel from "./SearchPanel";

const Search=()=>{
    const [showPanel,setShowPanel]=useState(false);// false
    const togglePanel=()=>{setShowPanel(!showPanel)};
    const ref = useRef();
    const [mount, setMount] = useState(false);
    const selector="place_search";

    useEffect(() => {
        ref.current = document.querySelector(selector);
        setMount(true);
    }, [selector]);


    return <div className={classes.search}>
        <button className={classes.buttonpanel} title={'Поиск в блоге'}  onClick={togglePanel}>{null}</button>
        {mount ? createPortal(<SearchPanel switch={showPanel} togglePanel={togglePanel}/>,
            document.getElementById("place_search")):null}
        {/*<SearchPanel switch={showPanel} togglePanel={togglePanel}/>*/}

    </div>
};
export default Search;

//document.body)