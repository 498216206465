import React from "react";
import classes from './Buttonpict.module.css';

const Buttonpict= props =>{
    const cls=[
        classes.buttonpict,
        classes[props.cls]
    ];
    if(props.size) cls.push(props.size); // change size = get outer class
    return(
        <button
            onClick={props.onClick}
            className={cls.join(' ')}
            disabled={props.disabled}
            title={props.title}
        >
            {props.children}
        </button>
    )
};

export default Buttonpict;