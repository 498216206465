import React from "react";
import classes from './LayoutFront.module.css';
import {Outlet} from "react-router-dom";
import Overlay from "../../components/UI/Overlay/Overlay";
import Aside from "../../components/front/Aside/Aside";
//import AsideDown from "../../components/front/Asidedown/Asidedown";
// import Sets from '../../components/adm/Sets/sets';  //remsite:"http://localhost:3001",
import Header from "../../components/front/Header/Header";
import Footer from "../../components/front/Footer/Footer";
//import { useSelector} from "react-redux";
const LayoutFront=()=>{

    /*header*/
    return <>
        <div id={'const-scroll'} className={classes.scrollfix}></div>
        <div className={classes.container} >
            <Header />
            <div id={'place_search'} className={classes.placesearch}></div>
            <div className={classes.mainpart} id={'mainscroll'}>
                <main className={classes.main}>

             <Outlet/>
            </main>

                        <Aside change={'...props'}/>


            </div>
            <Footer />
        </div>
        <Overlay />
        </>
};

export default LayoutFront;



