import React  from "react";
import Button from '../../Buttonpict/Buttonpict';
import classes from '../Drafteditor.module.css';
import BlockStyleList from './blockStyleList';
import ButtonGroup  from '../../ButtonGroup/ButtonGroup';
import converterExport from './exportData';



const Editorbuttons=props=>{
    const exportButton=(type,editorState,handlerExport)=>{
        const markup = type==='clear'?'':converterExport(editorState.getCurrentContent()); // <-- [1]
                handlerExport({markup,id:props.exportImport,type:type});
                props.closerEditMode();

       // console.log('markup ==> ', markup); // <-- [2]
    };

    const delitel=<span key={'divider'} className={classes.divider}/>;
        const styleButton=props.styleButton.map((item)=>{
            return <Button onClick={props.styleHandler} cls={item} key={item} title={item.toUpperCase()}/>});
    styleButton.push(delitel);
        const blockStyle=props.blockStyleList?
             <BlockStyleList editorState={props.editState} onToggle={props.blockStyleHandler}  delitel={delitel}  />
            :null;
        const alignBlock=props.alignHandler?
                ['left','center','right','justify'].map((item)=>{
                return (<Button onClick={()=>{props.alignHandler(item.toUpperCase())}} key={item} cls={'align-'+item} title={'align-'+item}/>)})
            :null;
        if (alignBlock) {alignBlock.push(delitel)}
        const addLink=props.addLink?
            [<Button onClick={props.linkHandler} cls={'addlink'} key={'linker'} title={'add link'}/>,delitel]
            :null;
        const buttonColor=props.buttonGroup?
            [<ButtonGroup current={props.buttonGroupCurrent} key={'buttongroup'} colorListSwitch={props.colorListSwitch} closer={props.closerTip}
                          listButtons={props.ButtonGroupList} handler={props.buttonGroupHandler} listButtonSize={[20,14,25,20]} offsetList={30}
                          editorState={props.editState} getInlineStyle={props.getInlineStyle} reactStyleMap={props.colorStyleMap}/>,delitel]
            :null;
    const replaceQuotes=props.replaceQuotes?
        [<Button onClick={props.replaceQuotesHandler} cls={'replaceQuotes'} key={'replaceQuotes'} title={'replace quotes'}/>,delitel]
        :null;
    const undoRedo=props.undoRedo?
        [<Button onClick={()=>props.undoRedoHandler(1)} cls={'undo'} key={'undo'} title={'undo'}/>,
            <Button onClick={()=>props.undoRedoHandler(0)} cls={'redo'} key={'redo'} title={'redo'}/>,delitel]
        :null;
    const exportImport=props.exportImport==='no'?
        [<Button onClick={()=>exportButton('text',props.editState,props.handlerExport)} cls={'export'} key={'export'} title={'new add'}/>,
            delitel]
        :[<Button onClick={()=>exportButton('text',props.editState,props.handlerExport)} cls={'save'} key={'save'} title={'save'}/>,
            <Button onClick={()=>props.closerEditMode()} cls={'close'} key={'close'} title={'cancel'}/>,delitel];
    //<Button onClick={()=>exportButton('clear',props.editState,props.handlerExport)} cls={'close'} key={'clear'} title={'clear'}/>,
    const buttonHr=props.buttonHr?
        [<Button onClick={()=>props.handlerHr()} cls={'hrline'} key={'hrline'} title={'hr line'}/>,delitel]
        :null;
    //const addTemp=(<Button onClick={()=>props.tester(props.editState)} cls={'addlink'} key={'linker'} title={'add link'}/>);

    return (
        <div className={props.cls}>
            {styleButton}
            {blockStyle}
    {buttonHr}
            {alignBlock}
            {addLink}
            {buttonColor}
            {replaceQuotes}
            {undoRedo}
            {exportImport}


        </div>
    );
};

export default Editorbuttons;

