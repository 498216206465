import React, {Component} from "react";
import classes from './Article.module.css';
import classcom from '../../components/UI/Styles/Stylecommon.module.css';
import {connect} from "react-redux";
import Loader from '../../components/UI/Loader/Loader';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {
    admCloseSideArticle, admAddContentBlock, admEditContentBlock, admStateEditor, savePictureArticle,admErrorToBlock,
    savePictureNote, admCloseEditBlock, getCurrentSetsIUploader, admWorkInnerDir, admEditTag, admHiddenMainPanel
} from "../../store/actions/adminrasdel";
import Buttonpict from "../../components/UI/Buttonpict/Buttonpict";
import Drafteditor from '../../components/UI/DraftEditor/Drafteditor';
import MyIframe from '../../components/UI/MyFrame/MyFrame';
import {SwitcherTabs} from "../../components/UI/SwitcherTabs/switcherTabs";
import AddImage from "../../components/UI/AddImage/AddImage";
import Sets from "../../components/adm/Sets/sets";
import AddInnerLink from "../../components/UI/AddInnerLink/AddInnerLink";
import {makeTime, createVideoFrame, converEmoji} from '../../components/adm/Sets/utils';
import Tags from '../../components/UI/Tags/Tags';

const createMarkup=(html)=> {
    return {__html: html}};


class Article extends Component{
    state ={WidthPicture:[1280,600], videoLink:'',winWidth: undefined, winHeight: undefined};

editBlock=(status,index)=>{
    console.log("cb",status)
    switch(status){
        case 'delete':
        case 'markdelete':
        case 'canceldelete':
        case 'edit':
        case 'moveup':
        case 'editnote':
        case 'change align':
        case 'change crop':
        case 'deleteimage':
        case 'movedown':  this.props.editContentBlock(status,index); break;
        default:break;
    }
    //console.log("status",index)
};

hocButton=(cb)=>{
    return (props)=>{
        return <Buttonpict cls={props.cls} key={props.index+props.cls} onClick={()=>cb(props.param,props.index)} title={props.title}/>}
};
selectButtons=(type,mark,index)=>{
    const hocButt=this.hocButton(this.editBlock);
    const sorter=[hocButt( {cls:'uparrow',title:'move Up',param:'moveup',index:index}),
        hocButt( {cls:'downarrow',title:'move Down',param:'movedown',index:index})];
    if(type==='text'){
        const arr=!mark?[hocButt( {cls:'import',title:'Edit',param:'edit',index:index}),
                hocButt( {cls:'markdelete',title:'mark to delete',index:index,param:'markdelete'})]
                :[hocButt( {cls:'close',title:'delete',param:'delete',index:index}),
                hocButt( {cls:'cancel',title:'cancel to delete',param:'canceldelete',index:index})] ;
        return [...arr,...sorter];
    }
    if(type==='image'){
        const arr=!mark?[hocButt( {cls:'note',title:'Edit note',param:'editnote',index:index}),
                hocButt( {cls:'align-justify',title:'Change align',param:'change align',index:index}),
                hocButt( {cls:'markdelete',title:'mark to delete',index:index,param:'markdelete'})]
            :[hocButt( {cls:'close',title:'delete',param:'deleteimage',index:index}),
                hocButt( {cls:'cancel',title:'cancel to delete',param:'canceldelete',index:index})] ;
        return [...arr,<br key={'brkey'}/>,...sorter];
    }
    if(type==='element'){
        const arr=!mark?[hocButt( {cls:'markdelete',title:'mark to delete',index:index,param:'markdelete'})]
            :[hocButt( {cls:'close',title:'delete',param:'delete',index:index}),
                hocButt( {cls:'cancel',title:'cancel to delete',param:'canceldelete',index:index})];
        return [...arr,...sorter];
    }
    if(type==='youtube'){
        const arr=!mark?[hocButt( {cls:'cropper',title:'change proportions',index:index,param:'change crop'}),
            hocButt( {cls:'markdelete',title:'mark to delete',index:index,param:'markdelete'})]
            :[hocButt( {cls:'cropper',title:'change proportions',index:index,param:'change crop'}),
                hocButt( {cls:'cancel',title:'cancel to delete',param:'canceldelete',index:index}),
                hocButt( {cls:'close',title:'delete',param:'delete',index:index})];
        return [...arr,...sorter];
    }
};
    createHtmlElement=(obj)=>{
        let txt='';
        const newObj=JSON.parse(obj);
        const site=Sets.remsite+'/images/'; // check for storonii resurs
        let reg=/^http/; // google drive
        const url=reg.test(newObj.url)?newObj.url:site+newObj.url;
        const note=newObj.note || `Скачать ${url}`;
        const size=newObj.parametres.size?  "("+Math.round(parseInt(newObj.parametres.size)/1024/10.24)/100+'Mb)' : null;
        const time=newObj.parametres.time?  "("+makeTime(newObj.parametres.time)+')' : null;
        switch(newObj.type){
            case 'Архив': txt=<div className={classcom.archive}><span className={classcom.icon}>{null}</span>
                <a className={classcom.link} href={url} target="_blank" title={note}
                   rel="noopener noreferrer">{newObj.name} {size}</a>
                </div>;
                break;
            case 'PDF': txt=<div className={classcom.pdf}><span className={classcom.icon}>{null}</span>
                <a className={classcom.link} href={url} target="_blank" title={note}
                   rel="noopener noreferrer">{newObj.name} {size}</a>
            </div>;
                break;
            case 'MP3': const nameSong=newObj.parametres.artist && newObj.parametres.song?  newObj.parametres.artist +' - '+ newObj.parametres.song:
                newObj.name;
                txt=<div className={classcom.mp3}><span className={classcom.icon}>{null}</span>
                <a className={classcom.link} href={url} target="_blank" title={note+' '+size}
                   rel="noopener noreferrer">{nameSong} {time} </a>
            </div>;
                break;
            case 'frame':
                let arr=newObj.url.split('/'); arr=arr[arr.length-1].split('.');
                txt=<MyIframe id={arr[0]} src={url}/>;
                break;
            default: break;
        }
        return txt;
    };

    handleResize=()=> {
        let iframes = document.getElementsByTagName('iframe');
        this.setState({ winWidth: window.innerWidth,winHeight: window.innerHeight});
        for(let i=0;i<iframes.length;i++){
            iframes[i].contentWindow.postMessage('hello', '*');
        }
    };
    checkSender = (e) => {
        //console.log ('e.data.msg',e.data);
        if(e.data.location){
            this.setIframeHeight(e.data.height, this.parserStr(e.data.location))
        }
        if (e.data === 'hello') {
            console.log ('e.data.msg',e.data);
        }

    };
    parserStr=(str)=>{  let arr=str.split('/'); arr=arr[arr.length-1].split('.'); return arr[0]; };
    setIframeHeight = (val,id) => {
        try {
            let iframe = document.getElementById(id);
            if(iframe)  {iframe.style.transition=`filter 2s ease-in-out 1s`;
            //iframe.add class
                iframe.style.height = val+20+'px'; //important
                iframe.style.filter = 'none';}
        } catch (e) {
            console.error('Resizing method call', e)
        }
    };

    renderBlocks=()=>{
        const blocks=this.props.sidearticle.text;
        const site=Sets.remsite+'/';
        const objAlign={left:'start',right:'end',center:'center'};
        return blocks.map((elem,index)=>{
            const markdelete=elem && elem.delete?1:0;
            const align=elem.align ?{alignSelf: objAlign[elem.align]}:null;
            const colorblock=markdelete?[classes.mainblock,classes.markdelete].join(' '):classes.mainblock;
            const note=elem.note && elem.note.length? <div className={classcom.noteimage}>{elem.note}</div>
                :<div className={classcom.emptynote}></div>;
            const link=site+elem.link;
            const srcsetImage=elem.type==='image' && elem.arrNames?elem.arrNames[1] || elem.arrNames[0]:null;
            const srcImage=elem.type==='image' && elem.arrNames? elem.arrNames[0]:null;
            const videoCrop=elem.option==='16'?classcom.videoWrapper16:classcom.videoWrapper4;
            const videoPolbloka=elem.option==='16'?classcom.polbloka16:classcom.polbloka4;
           return (
               <div id={'block_'+index} key={'block_'+index} className={classes.blockflex}>
                   {    elem.type==='text'
                        ? <React.Fragment>
                           <div className={colorblock} dangerouslySetInnerHTML={createMarkup(converEmoji(elem.text,'from'))}/>
                           <div className={classes.sideblockedit}>  {this.selectButtons(elem.type,markdelete,index)  } </div>
                       </React.Fragment>
                        : elem.type==='image' /*&& elem.arrNames*/
                            ? <React.Fragment>
                                   <div className={colorblock} >
                                       <div className={classcom.imagemain} style={align} > {/*<img src={link} alt={''}/>*/}
                                       <picture>
                                           <source srcSet={link+srcsetImage} media="(max-width: 800px)"/>
                                               <img src={link+srcImage} alt=""/>
                                       </picture>
                                       {note}
                                       </div>
                                   </div>
                                   <div className={classes.sideblockedit}>  {this.selectButtons(elem.type,markdelete,index)  } </div>

                               </React.Fragment>
                               :elem.type==='element'
                                ? <React.Fragment>
                                   <div className={colorblock}>{this.createHtmlElement(elem.text)}</div>
                                   <div className={classes.sideblockedit}>  {this.selectButtons(elem.type,markdelete,index)  } </div>
                               </React.Fragment>
                               :elem.type==='youtube'
                                ?<React.Fragment>
                                       <div className={colorblock}>
                                           <div className={videoPolbloka}>
                                           <div className={videoCrop}>
                                               {createVideoFrame(elem)}</div></div>
                                       </div>
                                       <div className={classes.sideblockedit}>  {this.selectButtons(elem.type,markdelete,index)  } </div>
                                 </React.Fragment>
                                   :null

                   }

               </div>
           );
        });
    };
    handler=(status,data)=>{ //console.log("checker",data)
        if(status==='save') this.props.savePictureArticle(data);
        if(status==='savenote') this.props.savePictureNote(data);
        if(status==='closenote') {this.props.closeEditBlock()}
        if(status==='getDir') {this.props.workInnerDir(status,data)}
        if(status==='refreshDir') {this.props.workInnerDir(status,data)}
        if(status==='workDir') {this.props.workInnerDir(status,data)}
        if(status==='upDir') {this.props.workInnerDir(status,data)}
        if(status==='selectElement') {this.props.workInnerDir(status,data)}
        if(status==='youtubeLinkChange') {this.changeStater(status,data, 'videoLink')}
        if(status==='youtubeLinkInsert') { this.toVideoFrame(status,this.state.videoLink)}
        if(status==='tags') { this.props.editTags(status,data)}
    };
    changeStater=(status,data, type)=>{
        let val='';
        if (type==='videoLink')  val=data.target.value;
        this.setState({ videoLink: val});
    };
    toVideoFrame=(status,data)=>{
        try {
            const url = new URL(data);
            const v=url.searchParams.get('v');
            if(v){
                const result= {type:'youtube',markup:v, id:'no',option:'16'};
                this.props.addContentBlock(result);
                this.changeStater(status,'', 'clear')
            } else{this.props.errorBlock('incorrect url or change youtube code?')}
        } catch (e) {
            console.log('incorrect url', data)
            this.props.errorBlock('incorrect url')
        }
    };

    async componentDidMount() {
        if (window.addEventListener) {
            window.addEventListener('message', this.checkSender);
            window.addEventListener("resize", this.handleResize);
        } else if (window.attachEvent) {
            window.attachEvent('message', this.checkSender);
            window.attachEvent("resize", this.handleResize);
        }
        const sets=await getCurrentSetsIUploader('addimagearticle');
        this.setState({ WidthPicture:[sets.parametr[0]||1280, sets.parametr[1]||600] });
    }
    componentWillUnmount() {
        window.removeEventListener('message', this.checkSender);
        window.removeEventListener("resize", this.handleResize);
    }
    render() {

        const parametr=this.state;
        const innerBlock=this.renderBlocks();
        const headerSize=null;//{width: this.props.cssstyle.articlewidth+'vw'};
        const modeArray=[['text','текстовый режим'],['image','добавить картинку'],['file','ссылка внутри']];
        const activeElement=[0,1,2].map(elem=>elem===this.props.sidearticlestate.menu?'':'hidden');
        const editblock=this.props.sidearticle.editblock;
        const buttonForAddImage=editblock && editblock.type==='image'?['closenote']:['save','cancel','align-left','align-center','align-right'];
        const noteEdit=editblock && editblock.type==='image'?editblock.note:null;
        const addImageBlock=<AddImage cls={activeElement[1]} buttons={buttonForAddImage} folder={'postimg'}
                          sidearticle={this.props.sidearticle} noteEdit={noteEdit}
              action={this.handler} settings={[parametr.WidthPicture,0,0,1]}/>; //[sizeImageMax,aspect previewW,aspect previewH,changeMainPictur]
        const addInnerBlock=<AddInnerLink cls={activeElement[2]} buttons={['refresh', 'youtube']} action={this.handler} videoLink={this.state.videoLink}
                                          table={'element'} localParam={'RasdelInnerLink'} dirstate={this.props.dirstate}/>;
        const centerArticle=this.props.sidearticlestate.viewarticle?1:0;
        return (
            this.props.loading
                ? <Loader/>
                : <React.Fragment>
                  <div className={classes.head} style={headerSize}>
                    <Buttonpict cls={'close'} onClick={this.props.closeSideArticle} title={'Закрыть панель'}/>
                    <Buttonpict cls={centerArticle?'checked':'nochecked'} onClick={()=>this.props.admHiddenMainPanel(!this.props.sidearticlestate.viewarticle)}
                                title={centerArticle?'Показать каталог':'Только статья'}/>
                    <div >Статья: {this.props.sidearticle.name}</div>
                  </div>
                  <div id='bodycomplete' className={classes.body}>
                      <SimpleBar style={{ maxHeight: '60vh' }}>
                      {innerBlock}
                      </SimpleBar>
                  </div>
                    <div id='bodytags' className={classes.tags}>
                        <Tags tags={this.props.sidearticle.tags} handler={this.handler} tagslist={this.props.tagslist}/>
                    </div>
                    <div id='bodyredact' className={classes.bodyredact}>
                        <Drafteditor addHandler={this.props.addContentBlock} htmlEditBlock={this.props.sidearticle.editblock}
                        cls={activeElement[0]} />
                        {addImageBlock}
                        {addInnerBlock}
                        <SwitcherTabs data={modeArray}
                                      active={this.props.sidearticlestate.menu}  handler={this.props.changeStateEditor}/>
                    </div>
                </React.Fragment>
        )
    }
}

function mapStateToProps(state){
    return {
        sidearticle:state.adminrasdel.sidearticle,
        sidearticlestate:state.adminrasdel.sidearticlestate,
        loading:state.adminrasdel.loading,
        cssstyle:state.adminrasdel.cssstyle,
        dirstate:state.adminrasdel.dirstate,
        tagslist:state.adminrasdel.tagslist,

    }
}
function mapDispatchToProps(dispatch){
    return {
        closeSideArticle: ()=>dispatch(admCloseSideArticle(0)),
        admHiddenMainPanel: (status)=>dispatch(admHiddenMainPanel(status)),
        addContentBlock: (htmlkod)=>dispatch(admAddContentBlock(htmlkod)),
        editContentBlock: (status,id)=>dispatch(admEditContentBlock(status,id)),
        changeStateEditor: (status)=>dispatch(admStateEditor(status)),
        savePictureArticle: (data)=>dispatch(savePictureArticle(data)),
        savePictureNote: (data)=>dispatch(savePictureNote(data)),
        closeEditBlock: ()=>dispatch(admCloseEditBlock()),
        workInnerDir: (status,data)=>dispatch(admWorkInnerDir(status,data)),
        editTags: (status,data)=>dispatch(admEditTag(status,data)),
        errorBlock: (data)=>dispatch(admErrorToBlock(data)),
                // getSortTable: (tbl)=>dispatch(getSortTable(tbl)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Article)