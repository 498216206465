import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import classes from "./Overlay.module.css";
import {setOverlay, setOverlayImg} from "../../../store/reducers/frontslice";
import {ReactComponent as ReactClose} from '../../../images/closeimage.svg';

const Overlay=()=>{
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.frontreducer.overlay);
    const image = useSelector((state) => state.frontreducer.overlayImg);
    const [style,setStyle]=useState("none");

    const changeOver=()=>{dispatch(setOverlay({overlay:0}));dispatch(setOverlayImg({overlayImg:{}}));};
    const showImage=(image)=> {
        if(image && image.link) {
            const srcSet = image.srcSet.length ? image.srcSet.map((elem,index)=><source key={`srcSet-${index}`} srcSet={elem[0]} media={elem[1]}/>) : null;
            const note=null;/*image.note && image.note.length?<div className={classes.overnote}>{image.note}</div>:null;*/
            const closeImage=<ReactClose key={'closeimage'} className={classes.overclose} onClick={changeOver}/>;
            return [<picture key={'pictunic'}>{[srcSet]}<img src={image.link} alt=""/></picture>,note,closeImage];
        } else {return null}
    };

    const imageFull=showImage(image);
    useEffect(() => {
            if (selector){
                setStyle('flex');
                document.body.style.paddingRight = getComputedStyle(document.querySelector('#const-scroll')).width;
                document.body.classList.add(classes.modalshow);
            } else{setStyle('none');
                document.body.classList.remove(classes.modalshow);
                document.body.style.paddingRight = '0px';
            }
    },[selector]);



        return <>
            <div  className={classes.overlay} style={{display:style}} onClick={changeOver}>{null}</div>
            <div  id = {'light'} className = {classes.lightcontent}  style={{display:style}} onClick = {changeOver}>
                <div  id = 'overpicture' className = {classes.overpicture} onClick = {cancelAction}>{imageFull}</div>
            </div>
            </>
};
export default Overlay;


const cancelAction=(event)=>{  event.stopPropagation();};