import {ADM_USER_START,ADM_USER_SUCCESS,ADM_STATE_ERROR,ADM_USER_ERROR,ADM_STATE_ERROR_LOGIN} from "../actions/actionTypes";

const initialState={
    headers:{page:'',user:''},
    users: [],
    isAuth:false,
    errorLogin:{status:0,msg:""},
    mainMenu:[],
    loading:false,
    error:null,
    token:''
};

export default function adminUserReducer(state=initialState,action){
    //console.log('act',action.type)
    switch(action.type){
        case ADM_USER_START:
            return{
                ...state,loading:true
            };
        case ADM_USER_SUCCESS:

            return{
                ...state, loading: false, mainMenu: action.obj.menu,isAuth: action.obj.auth,headers:action.obj.header,
                token:action.obj.token
            };
        case ADM_USER_ERROR: // disconnect adminka
            return{
                ...state, loading: false, mainMenu: [],isAuth: false,headers:action.header,token:''
            };
        case ADM_STATE_ERROR:
            return{
                ...state, loading: false, error:action.error
            };
        case ADM_STATE_ERROR_LOGIN:
            return{
                ...state, loading: false, errorLogin:action.error
            };

        default:
            return state;
    }
}