import React, {useEffect, useState} from "react";
import classes from './Search.module.css';
import {useDispatch, useSelector} from "react-redux";
import {getArrayForSearch} from "../../../store/actions/frontActions";
import Sets from "../../adm/Sets/sets";
import {useNavigate} from "react-router-dom";
import {setIndexCount, setListArticles, setUrlSearch} from "../../../store/reducers/frontslice";

const SearchPanel=(props)=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchData = useSelector((state) => state.frontreducer.searchpanel);
    const [rangeVal,setRangeVal]=useState(2);// false
    const [dataOption,setDataOption]=useState('word');
    const [rangeTxt,setRangeTxt]=useState(['t','word','i']);// false
    const [tag,setTag]=useState([]);
    const [icon,setIcon]=useState([]);
    const [word,setWord]=useState([]);
    const [blinker,setBlinker]=useState(2);
    const [scrollUp,setScrollUp]=useState(window.scrollY);
   // const [urlTxt,setUrl]=useState('');
    const site=Sets.remsite+'/';

    const cls=props.switch?[classes.panel,classes.panelon]:[classes.panel];
   // console.log('checkAndAdd zero',tag,icon, word)
    const ref = React.createRef();

    useEffect( ()=>{
        console.log('props.switch',props.switch)
        if(props.switch) dispatch(getArrayForSearch())
    },[props.switch,dispatch]);

    useEffect(() => {
        const onScroll = () => {
            setScrollUp(window.scrollY);
        }
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    },[]);

    const addToSearch=(obj)=>{
        let arr=[]; let result;
        if(obj.option==='word'){ const text=(ref.current && ref.current.innerText)|| null;
            if(text) {setWord([...word,text]);ref.current.innerText='';}
        }
        else{
            switch (obj.option) {
                case 'tag':
                    arr = [...tag]; result = arr.find(el => obj.elem.id === el.id);
                    if (!result) setTag([...tag, obj.elem]); break;
                case 'icon':
                    arr = [...icon]; result = arr.find(el => obj.elem.id === el.id);
                    if (!result) setIcon([...icon, obj.elem]); break;
                default: return;
            }
        }
    };

    const clickerState=(e)=>{
        setRangeVal(e.target.value);
        switch(e.target.value){
            case '1':  setRangeTxt(['tag','w','i']);setDataOption('tag'); setBlinker (1);break;
            case '3':  setRangeTxt(['t','w','icon']);setDataOption('icon');setBlinker (3);break;
            default:setRangeTxt(['t','word','i']);setDataOption('word');setBlinker (2);break;
        }

    };

    const clearClickerState=(param)=>{
        if(param){setTag([]);setIcon([]);setWord([]); }
        else{ switch(dataOption){
            case 'word':setWord([]);break;
            case 'tag':setTag([]);break;
            case 'icon':setIcon([]);break; default:break;
        }}
    };

       const finder=(e)=>{ const wordArray=word;
            const tagArray=tag.map(elem=>elem.id);const iconArray=icon.map(elem=>elem.id);
            if(!tagArray.length && !iconArray.length && !wordArray.length) return false;
            const url=makeSearchURL({tagArray,iconArray,wordArray});
          /* let current=window.location.origin;
          const url = new URL(current+'/search');
           url.searchParams.append('tags', JSON.stringify(tagArray));
           url.searchParams.append('icons', JSON.stringify(iconArray));
           url.searchParams.append('word', JSON.stringify(wordArray));*/
           //setUrl(url.href)
           dispatch(setIndexCount({indexCount:[]}));
           dispatch(setUrlSearch({urlSearch:1}));
           dispatch(setListArticles({articles: []}))
           navigate(url.pathname+url.search);
        //   console.log('current url',url, url.href)
           props.togglePanel();
           //navigate('/search/tags:'+tagArray);
       };


        const clicker= <div className={classes.range}>
        <input type={"range"} id="RangeFilter" name="points" onChange={(e)=>clickerState(e)} min="1" max="3" value={rangeVal}/>
        <div className={classes.rangetxt}>
            <div className={classes.rangename}>{rangeTxt[0]}</div><div className={classes.rangename}>—</div>
            <div className={classes.rangename}>{rangeTxt[1]}</div><div className={classes.rangename}>—</div>
            <div className={classes.rangename}>{rangeTxt[2]}</div>
        </div>
    </div>;
    const reset=<div className={classes.reset}>
        <button className={classes.buttoncalc}  onClick={()=>clearClickerState(0)} title={'Сброс параметра'}>CE</button>
        <button className={classes.buttoncalc}  onClick={()=>clearClickerState(1)} title={'Полный сброс'}>C</button>
    </div>;

    const searcher=<div className={classes.endsearch}>
        <button className={classes.buttonpanel}  onClick={finder}>{null}</button>
        <button className={[classes.buttonpanel,classes.off].join(" ")}  onClick={props.togglePanel}>OFF</button>
    </div>;

    const showIcons=icon.length?
        <div className={classes.displayicons}>{icon.map(elem=>  <div key={elem.id+elem.nameicon} title={elem.nameicon}>
            <img alt={elem.nameicon} src={site+elem.iconurl}/></div>)}</div>
        :null;
    const showTags=tag.length?
        <div className={classes.displaytags}>{tag.map(elem=>  <div key={elem.id+elem.nametag}>{elem.nametag}</div>)}</div>
        :null;
    const showText=<div className={classes.perenos}>{word.map((elem,index)=>  <div key={index}>{elem}</div>)}</div>;

    const txtarea=<div className={classes.inputblog}>
        <div className={classes.zagtxt}><div className={blinker===1?[classes.aligntxt,classes.blinker].join(' '):classes.aligntxt}>tags: </div>{showTags}</div>
        <div className={classes.zagtxt}><div className={blinker===2?[classes.aligntxt,classes.blinker].join(' '):classes.aligntxt}>words: </div>{showText}</div>
        <div className={classes.zagtxt}><div className={blinker===3?[classes.aligntxt,classes.blinker].join(' '):classes.aligntxt}>icons: </div>{showIcons}</div>
    </div>;
//todo: hide icon for search
    return  <div className={cls.join(" ")} style={{marginTop:scrollUp+'px'}}>
        <div className={classes.calcscreen}>{txtarea}</div>
       <div className={classes.blockbuttons}>
          <div className={classes.switchblock}>
              {clicker} {reset}
          </div>
           <div className={classes.selectarea}>
               <FormatSearchDisplay data={searchData} option={dataOption} action={addToSearch} ref={ref}/>
           </div>
           {searcher}
       </div>
    </div>
};

export default SearchPanel;

const FormatSearchDisplay=React.forwardRef(({data,option,action},ref)=>{
    const site=Sets.remsite+'/';
    switch(option){
        case 'tag':
            const tags=data && data.tags?
                data.tags.map(elem=>  <div key={elem.id+elem.nametag} className={classes.tag}
                                      onClick={()=>action({option:option,elem})}><span>{elem.nametag}</span></div>)
                : <div>Nothing</div>;
            return <div className={classes.displaykey}>
                {tags}
            </div>;

        case 'icon':
            const icons=data && data.icons?
                data.icons.map(elem=>  <div key={elem.id+elem.nameicon} className={classes.icon} title={elem.nameicon}
                                onClick={()=>action({option:option,elem})}><img alt={elem.nameicon} src={site+elem.iconurl}/></div>)
                : <div>Nothing</div>;
            return <div className={classes.displaykey}>
                {icons}
            </div>;
        default: return <div className={classes.displayword}>
                <div className={classes.txtarea} ref={ref} contentEditable={true}>{null}</div>
                <div className={classes.titletxt} >Слова сильны & над остальным, но между собой ||.</div>
            <div className={classes.addtext}>
                <button className={classes.buttonaddtext}
                        onClick={()=>action({option:option,id:'myRef'})} title={'Добавить в поиск'}>{null}</button>
            </div>
        </div>
    }
});

export const makeSearchURL=({tagArray,iconArray,wordArray})=>{
    let current=window.location.origin;
    const url = new URL(current+'/search');
    url.searchParams.append('tags', JSON.stringify(tagArray));
    url.searchParams.append('icons', JSON.stringify(iconArray));
    url.searchParams.append('word', JSON.stringify(wordArray));
    return url;
}
//https://www.jquery-az.com/css/demo.php?ex=143.0_1
//https://dev.to/catlogic/styling-the-catlogic-calculator-in-css-d7i