import React from "react";
export function getPropertiesFortName(name,blocker){
    const arrName={
        empty:{type:'checkboxmain',redact:false,closed:false},
        sort:{type:'text',redact:false,closed:true},
        color:{type:'textcolor',redact:false,closed:true},
        name: {type:'url',redact:blocker===2 || blocker===1,closed:true},
        email: {type:"text",redact:blocker===1,closed:true},
        elementname: {type:'text',redact:blocker===2 || blocker===1,closed:true},
        nametag: {type:'text',redact:true,closed:true},
        nameuser: {type:'text',redact:true,closed:true},
        ip: {type:'text',redact:true,closed:true},
        stopdevice: {type:'checkbox',redact:true,closed:true},
        stopip:{type:'checkbox',redact:true,closed:true},
        nameicon: {type:'text',redact:true,closed:true},
        elementurl: {type:'urlelem',redact:false,closed:true},
        iconurl: {type:'iconelem',redact:false,closed:true},
        icon: {type:'iconvisual',redact:false,closed:true},
        options: {type:'options',redact:false,closed:true},
        password: {type:'password',redact:false,closed:true},
        namearticle: {type:'urlarticle',redact:blocker===2 || blocker===1,closed:true},
        nameurl: {type:'text',redact:blocker===2 || blocker===1,closed:true},
        parametr: {type:'textlock',redact:blocker===1,closed:true},
        value: {type:'text',redact:true,closed:true},
        note: {type:'text',redact:blocker===2 || blocker===1,closed:true},
        rol:{type:'select',redact:blocker===2 || blocker===1,closed:true,list:{0:'Статьи', 1:'Галереи', 2:'Пункты'}},
        rolarticle:{type:'select',redact:blocker===2 || blocker===1,closed:true,list:{0:'Статья', 1:'Галерея', 2:'Список'}},
        rolelement:{type:'select',redact:blocker===2 || blocker===1,closed:true,list:{0:'Архив', 1:'PDF', 2:'MP3',3:'frame'}},
        roluser:{type:'select',redact:blocker===1,closed:true,list:{0:'Админ', 1:'Редактор', 2:'Юзер'}},
        redirect:{type:'checkbox',redact:blocker===2 || blocker===1,closed:true},
        vyvod:{type:'checkbox',redact:true,closed:true},
        picture:{type:'countelem',redact:false,closed:true},
        comment: {type:"commelem",redact:false,closed:true},
        button:{type:'button',redact:false,closed:true},
        leftpanel:{type:'none',redact:false,closed:true},
        anons:{type:'none',redact:false,closed:true},
        rightpanel:{type:'none',redact:false,closed:true},
        default:{type:'text',redact:true,closed:true}
    };
    return arrName[name] || arrName.default;

}

export function getFormParams(name){
    const arrName={
        name:{ value:'', type:"text", errorMessage:'Введите корректное название',
            valid:false, touched: false, locked:false,
            validation:{  required:true, minLength:1}
            },
        namearticle:{ value:'', type:"text", errorMessage:'Введите корректное название',
            valid:false, touched: false, locked:false,
            validation:{  required:true, minLength:1}
        },
        nametag:{ value:'', type:"text", errorMessage:'Введите корректное название',
            valid:false, touched: false, locked:false,
            validation:{  required:true, minLength:1}
        },
        nameuser:{ value:'', type:"text", valid:false, touched: false, locked:false },
        ip:{ value:'', type:"text", valid:false, touched: false, locked:false },

        elementname:{ value:'', type:"text", errorMessage:'Введите корректное название',
            valid:false, touched: false, locked:false,
            validation:{  required:true, minLength:1}
        },
        elementurl:{ value:'', type:"urlelem",locked:true,valid:true, touched: true,
        },
        iconurl:{ value:'', type:"iconelem",locked:true,valid:true, touched: true,
        },
        icon:{ value:'', type:"iconvisual",locked:true,valid:true, touched: true,
        },
        options:{ value:'', type:"options",locked:true,valid:true, touched: true,
        },
        nameurl:{ value:'', type:"text", errorMessage:'Введите only english symbols',
            valid:false, touched: false, locked:false,
            validation:{  required:true, texturl:true}
        },
        email:{value:'', type:"text", errorMessage:'Введите correct email',
            valid:false, touched: false, locked:false,
            validation:{  required:true, email:true}
            },
        parametr:{ value:'', type:"textlock", errorMessage:'Введите only english symbols',
            valid:false, touched: false, locked:false,
            validation:{  required:true, paramurl:true}
        },
        value:{ value:'', type:"text", errorMessage:'Введите корректное значение',
            valid:false, touched: false, locked:false,
            validation:{  required:true, minLength:1}
        },
        note:{ value:'', type:"text", valid:true, touched: true, locked:false,       },
        rol:{value:'', type:"select",locked:false,valid:true, touched: true,            },
        rolarticle:{value:'', type:"select",locked:false,valid:true, touched: true,            },
        rolelement:{value:'', type:"select",locked:false,valid:true, touched: true,            },
        roluser:{value:'', type:"select",locked:false,valid:true, touched: true,            },
        vyvod:{value:'', type:"checkbox",locked:false,valid:true, touched: true,            },
        stopip:{value:'', type:"checkbox",locked:false,valid:true, touched: true,            },
        stopdevice:{value:'', type:"checkbox",locked:false,valid:true, touched: true,            },
        id:{value:'', type:"text",locked:true,valid:true, touched: true,            },
        color:{value:'', type:"textcolor",locked:true,valid:true, touched: true,            },
        empty:{value:'', type:"checkbox",locked:true,valid:true, touched: true,            },
        sort:{value:'', type:"text",locked:true,valid:true, touched: true,           },
        redirect:{value:'', type:"checkbox",locked:true,valid:true, touched: true,            },
        picture:{value:'', type:"countelem",locked:true,valid:true, touched: true,            },
        comment:{ value:'', type:"commelem", valid:true, touched: true, locked:true,       },
    };
    return arrName[name] || arrName.sort;
}

export function getHeadersForm(name){
    const arrName={ empty:"", sort:'№', name: 'Название',namearticle: 'Название', nametag: 'Название',nameurl:'Url', rol:'Роль', redirect:'RD', vyvod:'Show',
        rolarticle:'Роль', rolelement:'Роль', picture:'Picture', button:"",parametr:"Параметр", value:"Значение",note:"Примечание",elementname:'Название',
        elementurl:'Ссылка', options:'Опции', anons:'none', leftpanel:'none',rightpanel:'none',email:'Логин/почта',roluser:'Роль',password:'Пароль',
        nameicon:'Название',iconurl:'Url', color:'Цвет (svg)', icon:'Icon',comment:'Comment',nameuser:'User',ip:'IP',stopip:'LockIP',stopdevice:'LockUser'};
    return arrName[name] || "";
}
export function getDeleteFields(){    return ['empty','button','isValid','updatedAt','password','icon','comment'];}

export function buttonsForAction(name){
    const arrButton={
        save:{title:'Сохранить',type:'saverecord'},
        edit:{title:'Редактировать',type:'editrecord'},
        markdelete:{title:'Отметить на удаление',type:'markdeleterecord'},
        delete:{title:'Удалить',type:'deleterecord'},
        cancel:{title:'Отмена',type:'canceledit'},
        switchon:{title:'Режим добавления',type:'switchon'},
        switchoff:{title:'Режим просмотра',type:'switchoff'},
        sortright:{title:'Переместить вправо',type:'sortright'},
        sortleft:{title:'Переместить влево',type:'sortleft'},
    };
    let retArray=[];
    if(Array.isArray(name)){
        name.forEach((item, i)=> { retArray.push(arrButton[item]); });
    } else{retArray.push(arrButton[name]);}
return retArray;
}

export function abbrevation(tbl,fields){ // for options article and....
    const selectFields={ article:['leftpanel','rightpanel','anons'],user:['options']};
    const current=selectFields[tbl] || [];
   // console.log('article',current,fields)
    const result=current.map(elem=>{
        switch (elem){
            case 'leftpanel': return parseInt(fields[elem])?fields[elem]:'-';
            case 'rightpanel': return parseInt(fields[elem])?fields[elem]:'-';
            case 'anons': return fields && fields[elem] && fields[elem].length?'A':'';
            case 'options': return 'options';
            default: return '';
        }
    });
    const ret=result.join('')==='--'?'':result.join('');
    return ret;
};

export function createQuestion(msg){
    const question=['Что-то пошло не так: '];   if (msg) question.push(msg);
    return question;
}

export function orderSortTable(table) {
    switch (table) {
        case 'article':
            return 'orderSortArticleReactor';
        case 'rasdel':
            return 'orderSortRasdelReactor';
        case 'setting':
            return 'orderSortSettingReactor';
            case 'element':
            return 'orderSortelementReactor';
            case 'tag':
            return 'orderSortTagReactor';
        default:
            return false;
    }
}
export function sideBarHeader(table,action) {
    //console.log(action)
    const arrAction={
        picture:`Добавить картинку в ${table}`,
        comment:`Править  комментарии ${table}`,
        elementurl:`Добавить файл `,
        options:`параметры ${table}`,
        password:`параметры ${table}`,
        iconurl:`параметры ${table}`,
        icon:`параметры иконок ${table}`
    };
    return  arrAction[action];
}

export function pictureInElementArray(elem,put) {
    return  ({id:elem.id,
        pathbig: elem.name? put+'/'+elem.name:'',
        pathsmall: elem.namesmall? put+'/'+elem.namesmall+"?" + new Date().getTime():'',
        note:elem.note, sort:elem.sort, rasdel:elem.rasdel});
}

export function changeDatestampEnd(sidebody,record) {
    return sidebody.map(elem=>{
        let tmp=elem.pathsmall.split('?')[0];
        elem.pathsmall=tmp+"?" + new Date().getTime();
        elem.note=parseInt(record.id)===parseInt(elem.id)?record.note:elem.note;
         return elem; });
}

export function changeStyles(status,cssstyle){
    cssstyle.leftwidth= status? 43:65;
    cssstyle.headerwidth=status?25:40;
    cssstyle.headererror=status?15:20;
    return cssstyle;
}

export function changePlaceInArray(id,arr,direct) {
    switch (direct){
        case 'up': arr=id<=0?arr:swap(arr,id-1,id); break;
        case 'down': arr=id>=arr.length-1?arr:swap(arr,id,id+1); break;
        default:break;
    }
    return arr;

}

function swap(mass,first,second){ mass[first] = [mass[second], mass[second] = mass[first]][0]; return mass;}

export function checkIdRedactArticle(currId,newId,arrLength,editId){
    if(newId<0 || newId>=arrLength) return editId;
    return currId===editId?newId:
        (newId===editId?currId:editId);
}

// sideelement parametres

export function selectFieldsForFiles(name){
    const obj={
        element:{url:'elementurl',size:'size',name:'elementname'},
        article:{size:'size',name:'namearticle'},
        user:{ name:'email'},
        icon:{name:'nameicon',url:'iconurl'},
        rasdel:{name:'name'}
    };

    return obj[name];
}
export function selectTypeElement(tbl,id){
    let name='rolelement';
    const type=getPropertiesFortName(name);
    return type.list[id];
}

export function makeTime(value){
    const sec=parseInt(value) || 0;
    const min=(sec/60)>0?Math.floor(sec/60):0;
    const second=sec-min*60;
    return sec?(min>9?min:'0'+min) + ':'+ (second>9?second:'0'+second) :null;
}

export function createVideoFrame(obj){
    if(obj.type==='youtube') {
        const url="https://www.youtube.com/embed/"+obj.text;
        return <iframe width="80%" height="auto" src={url}
    title="YouTube video player" frameBorder="0"
    allowFullScreen/>
    }
}

const hexToRgb = hex =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        , (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))
;
function rgb2hsv(r,g,b) {
    let v=Math.max(r,g,b), c=v-Math.min(r,g,b);
    let h= c && ((v===r) ? (g-b)/c : ((v===g) ? 2+(b-r)/c : 4+(r-g)/c));
    return [60*(h<0?h+6:h), v&&c/v, v];
}
export function complementColor(hex){
    const arRgb=hexToRgb(hex);
    const comCol=arRgb.map(elem=>{ return elem>127?0:255; });
    return `rgb(${comCol.join(',')})`; //,0.7 for rgba
}
export function createColorPalitre(hex){
    const arRgb=hexToRgb(hex);
    const arHsv=rgb2hsv(arRgb[0],arRgb[1],arRgb[2]);
    return { hex: hex,
        hsv:{h:arHsv[0],s:arHsv[1],v:arHsv[2]},
        rgb:{r:arRgb[0],g:arRgb[1],b:arRgb[2]}
    }
}

export function newColorSvg(text,color) {
    const div = document.createElement('div');
    const arrType=['g','path','polygon','circle','ellipse','rect','symbol', 'line','polyline'];
    div.innerHTML = text;
    if (div.hasChildNodes()) {
        const children = div.childNodes[0].childNodes;
        for(let i=0; i<children.length; i++) {
            if (children[i].tagName && arrType.includes(children[i].tagName)) {
                checkerColor(children[i], color);
                let little = children[i].childNodes;
                if (little.length > 0) {
                    for (let j = 0; j < little.length; j++) {
                        if (little[j].tagName && arrType.includes(little[j].tagName)) {
                            checkerColor(little[j], color);
                        }

                    }
                }
            }
        } return {error:0,html:div.innerHTML}
    } else{return {error:1,msg:'error parsing svg'}}
}

const checkerColor=(elem,color)=>{
    if(elem.hasAttribute('fill') && elem.getAttribute('fill')!=='none')
    {elem.setAttribute('fill',color); console.log('tagName',elem)}
    if(elem.hasAttribute('stroke') && elem.getAttribute('stroke')!=='none')
    {elem.setAttribute('stroke',color);console.log('tagName',elem)}
};

export function escapeMY(string) {
    var htmlEscapes = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
    };
    return string.replace(/[&<>"']/g, function(match) {
        return htmlEscapes[match];
    });
};

export function converEmoji(str,where){
    if(where==='to'){
        return str.replace(
            /\p{Emoji_Presentation}/gu,
            char => `\\u{${char.codePointAt(0).toString(16)}}`
        );
    } else{
        const regex=/(\\u{)(\w*)(})/gm;
         return str.replace(
             regex,function(match,p1, p2){return String.fromCodePoint(parseInt(p2, 16))}

        );
    }
};

/*
return str.replace(
    /\\u\{\w*\}/gu,
    char => {

        '${String.fromCodePoint(char)}'

    }
);*/
