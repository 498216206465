import React, {Component} from "react";
import classes from './Sidebar.module.css';
import {connect} from "react-redux";
import Buttonpict from "../../UI/Buttonpict/Buttonpict";
import {admCloseSideBar,savePicturePreview,deletePicturePreview,changeNumCurrentPict,sortPicturePost } from "../../../store/actions/adminrasdel";
import {sideBarHeader,buttonsForAction} from '../Sets/utils';
import ImgUploader from "../../UI/Imguploader/Imguploader";
import CommentAdmin from "../../UI/CommentAdmin/CommentAdmin";


class SidebarSelect extends Component{
    state={regim:0};
    buttFor={
    add:buttonsForAction(['switchoff','save']),
    edit:buttonsForAction(['edit','sortleft','switchon','sortright','markdelete']),
    delete:buttonsForAction(['edit','delete','cancel'])
    };
    localCheckHandler=(obj)=>{
        switch(obj.status) {
            case 'savepicture':  return this.props.savePicturePreview(obj.data);// break;
            case 'sortleft': this.props.sortPicturePost(obj); break;
            case 'sortright': this.props.sortPicturePost(obj); break;
            case 'deleterecord': this.props.deletePicturePreview(obj);break;
            default: console.log('no any action for it',obj); return;
        }
    };
     switchRegim(param){ this.setState({regim:param});  }
    /*async componentDidMount() {
        await this.props.getCurrentSetsIUploader(this.props.sidehead.table);
    }*/

    render() {
        const header=sideBarHeader(this.props.sidehead.table,this.props.sidehead.namefield);
        const siderSel=this.props.sidetype==='countelem'?<ImgUploader arrBody={this.props.sidebody} sidehead={this.props.sidehead}
                                                                      perehod={this.props.changeNumCurrentPict}
                                                                      buttFor={this.buttFor} handler={this.localCheckHandler}
                                                                      setting={this.props.settings}/>
                                                      :<CommentAdmin/>;
        const width=this.props.sidetype==='countelem'?'20vw':'30vw';
        return(
            <div className={classes.sidebar} style={{width:width}}>
                <div className={classes.header}>
                        <Buttonpict cls={'close'} onClick={this.props.closeSideBar} title={'Закрыть панель'}/>
                        { header
                            ? <div className={classes.headerdiv}>{header}: {this.props.sidehead.namerasdel} </div>
                            : null
                        }
                </div>
                <div className={classes.body}>

                    {siderSel}
                </div>

            </div>
        )
    }


}
function mapStateToProps(state) {
    return {
        sidehead:state.adminrasdel.sidehead,
        sidebody:state.adminrasdel.sidebody,
        settings:state.adminrasdel.sidesets,
        sidetype:state.adminrasdel.sidetype,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        closeSideBar: ()=>dispatch(admCloseSideBar()),
        savePicturePreview: (formdata)=>dispatch(savePicturePreview(formdata)),
        changeNumCurrentPict: (direct)=>dispatch(changeNumCurrentPict(direct)),
        sortPicturePost:  (obj)=>dispatch(sortPicturePost(obj)),
        deletePicturePreview: (obj)=>dispatch(deletePicturePreview(obj)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SidebarSelect)