import React, { useEffect, useState} from "react";
import ComLog from "./ComLog";
import classes from './Comment.module.css'
import AddComment from "./AddComment";
import axios from  'axios';
import {useDispatch, useSelector} from "react-redux";
import {converEmoji, escapeMY} from "../../adm/Sets/utils"
import {getCommentList} from "../../../store/actions/frontActions";
const timeAnswer=5000;

const Comment=(props)=>{
    const dispatch = useDispatch();
    const [name,setName]=useState('');
    const idArticle = useSelector((state) => state.frontreducer.idcurrentarticle);
    const [password]=useState('');
    const [addCom,setAddCom]=useState(props.def);
    const [timeStamp,setTimeStamp]=useState(props.timestamp);
    const [showError,setShowError]=useState(false);
    const [textError,setTextError]=useState('');
    const [second,setSecond]=useState(0);
    const [hash,setHash]=useState('');

    const answer=<button className={classes.showanswer} onClick={()=>{setAddCom(!addCom);setTimeStamp(new Date().getTime())}}
                         title={'Добавить комментарий'}>{null}</button>;

    const setActionFirst=async (textblock,chick,switcher)=>{
        if(timeStamp===0 || (new Date().getTime()-timeStamp)<timeAnswer)
                {setTextError('еще пару секунд подождите'); setShowError(true);return false;}  //switch on!!!!
        console.log("result second", textblock,JSON.stringify(textblock))
        if (switcher && !second) {
            if(!name.length) {setTextError('Неправильное имя');setShowError(true); return; }
            const result = await axios.post('/front/comment/',{name,password,hash:'',text:'',checker:'first',idart:idArticle});
            if(!result.data.error) {
                console.log("result ", result)
                setSecond(chick);setHash(result.data.device);
            }else{setTextError('комментарий не опубликован'); setShowError(true);}
        }
        if (switcher && second) {
            let newtxt=textblock.replace(/<a\b[^>]*>/gm, '').replace(/<\/a>/gm, '');
            newtxt=escapeMY(newtxt);
            newtxt=converEmoji(newtxt,'to')
            const result = await axios.post('/front/comment/',
                {name,password,hash,text:newtxt,checker:'second',idart:idArticle,master:props.master});
            console.log("result second", result)
            if(!result.data.error) {
                setSecond(0);setHash('');setAddCom(false);
                if(props.button)props.button();  dispatch(getCommentList(idArticle));

            }else{setTextError('комментарий не опубликован'); setShowError(true);}
            console.log('second',result)
        }
        if (!switcher) {setSecond(chick);setHash('');}
    };

    useEffect(()=>{
        if(showError) setTimeout(()=>setShowError(false),1000);
    },[showError] );

return <div className={classes.commblock}>
    {addCom?<><ComLog getName={setName} blocker={second}/><AddComment action={setActionFirst} changer={second}/></>:answer}
   {/*{addCom?<AddComment action={setActionFirst} />:answer}*/}
    {showError?<div className={classes.comerror}>{textError}</div>:null}
{/*
    <button className={classes.buttonlog} onClick={()=>console.log(name)} title={'Запомнить в куки'}>Checker</button>
*/}
</div>
};
export default Comment;

