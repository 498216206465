import React, {useEffect, useState} from "react";
import classes from './Sideselect.module.css';
import { useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Skeleton from "../../UI/Skeleton/Skeleton";

const SideSelect=(props)=>{
    const sider = useSelector((state) => state.frontreducer[props.side]);
    const [arrSide,setArrSide]=useState([]);

    useEffect(() => {
      //  console.log('sider',sider);
        const arr= sider.map(elem=>{
            return makeAdvert(elem);
        });
        setArrSide(arr)
    },[sider,props.side]);

        return (
          <>  {props.resultView && sider && sider.length?
                    <div className={classes.sflex}>
                        {arrSide}
                    </div>
                    : <Skeleton params={[{radius:'10px',count:2,fill:true,height:'4rem',width:'94%',margin:'10px'}]} cls={classes.sflex} />
            }
          </>
        );
};

export default SideSelect;

const makeAdvert=(elem)=>{
    const url=`/${elem.urlRasdel}/a/${elem.nameurl}`;
    const text=elem.text?elem.text.replace(/(<([^>]+)>)/gi, ''):'';
    const resText=elem.anons || text;
    const cls=elem.advert?[classes.sblock,classes.advert]:[classes.sblock];
    return <div className={cls.join(' ')} key={elem.id+'-'+elem.nameurl}>
                <div className={classes.sheader} ><Link to={url} alt={resText}>{elem.namearticle}</Link></div>
        <div className={classes.stext} title={resText}>{resText}</div>
           </div>
};

