import React from 'react';
import { convertToHTML } from 'draft-convert';
import classcom from '../../Styles/Stylecommon.module.css';
import {ALIGNMENTS} from './ExtendedRichUtils'

export const styleToHTML = (style) => { // <-- [1]
    //console.log('style ==> ', style);
    switch (style) {
        case 'ITALIC':
            return <em />;
        case 'BOLD':
            return <strong />;
        case 'UNDERLINE':
            return <span className={classcom.underline} />;
        case 'STRIKETHROUGH':
            return <span className={classcom.strikethrough} />;
        case 'CODE':
            return <span className={classcom.code} />;
        case 'REDFONT':
            return <span className={classcom.redfont} />;
        case 'GREENFONT':
            return <span className={classcom.greenfont} />;
        case 'BLUEFONT':
            return <span className={classcom.bluefont} />;
        case 'BLUEBACK':
            return <span className={classcom.blueback} />;
        case 'YELLOWBACK':
            return <span className={classcom.yellowback} />;
        case 'REDBACK':
            return <span className={classcom.redback} />;
        default:
            return null;
    }
};
const selectAlignStyle=(param)=>{ return param===undefined? {}:  {textAlign: ALIGNMENTS[param]} };

export const blockToHTML = (block) => { // <-- [2]
    const blockType = block.type;
    //const nodeClass=selectorAlignClass(block.data.textAlignment);
    const cls=selectAlignStyle(block.data.textAlignment);
    switch (blockType) {
        case 'header-one':             // eslint-disable-next-line
            return (<h1 style={cls}/>);
        case 'header-two':            // eslint-disable-next-line
            return <h2 style={cls}/>;
        case 'header-three':             // eslint-disable-next-line
            return <h3 style={cls}/>;
        case 'blockquote':             // eslint-disable-next-line
            return <blockquote style={cls}/>;
        case 'code-block':             // eslint-disable-next-line
            return <pre style={cls}/>;
        case 'OtstupBlock':             // eslint-disable-next-line
            return <div  className={classcom.postotstup} style={cls}/>;
        // return {
        // start: `<div class="slider js-slider" data-slides="${ JSON.stringify(slides).replace(/"/g, "'")}"><div>`,
        //end: `</div></div>`
        //}
        // }

        default: return <div style={cls} />;
    }
};

export const entityToHTML = (entity, text) => { // <-- [3]
    if (entity.type === 'LINK') {
        return (
            <a
                className={classcom.link}
                href={entity.data.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                {text}
            </a>
        );
    }
    if (entity.type === 'LINKINN') {
        return (
            <a
                className={classcom.linkinn}
                href={entity.data.url}
            >
                {text}
            </a>
        );
    }
    if (entity.type === "HORIZONTAL_RULE") {
        return (
            <div className={classcom.posthr}><hr/></div>
        );
    }
    return text;
};

export const options = { // <-- [1]
    styleToHTML,
    blockToHTML,
    entityToHTML,
};

const converterFunction = convertToHTML(options); // <-- [2]

const func=contentState => converterFunction(contentState);
export default func;


