import React, { Component } from 'react';
import Layout from '../../hoc/Layout/Layout';
import Loader from '../../components/UI/Loader/Loader';
import RoutesAdm from '../../Routes/routesadm';
import AuthForm from '../Authform/Authform';
import MainMenuList from "../../components/adm/Mainmenu/Mainmenu";
import {connect} from "react-redux";
import {getAdmUserState} from '../../store/actions/adminuser';

class Mainadmin extends Component {

     componentDidMount() {
         this.props.getAdmUserState();
    };


    render() {
        let msg="";
        const shirina={width: this.props.cssstyle.leftwidth+'vw'};
        if(!this.props.isAuth){
            msg=(<AuthForm />);
        } else{
            msg=(<div style={shirina}>
                    <MainMenuList menulist={this.props.mainMenu}/>
                    <RoutesAdm />
                </div>
            )}
        return (
            <Layout >
                {
                    this.props.loading
                        ? <Loader/>
                        : msg
                }
            </Layout>
        )
    }
}
function mapStateToProps(state){
    return {
        isAuth:state.adminuser.isAuth,
        mainMenu:state.adminuser.mainMenu,
        loading:state.adminuser.loading,
        cssstyle:state.adminrasdel.cssstyle,
    }
}
function mapDispatchToProps(dispatch){
    return {
        getAdmUserState: ()=>dispatch(getAdmUserState()),
       // userConnectAll:()=>dispatch(userConnectAll()),

    }
}
export default (connect(mapStateToProps,mapDispatchToProps)(Mainadmin));