import React from 'react';
import classes from './switcherTabs.module.css';
import {Checkbox} from "../Inputable/Inputable";

export const SwitcherTabs = (props) => {
    const switchers=props.data.map((elem,index)=>(<div className={classes.divswitcher} key={index+'tabs'} title={elem[1]}>
                       <span> <Checkbox  field={props.active===index}
                        handler={()=>props.handler(index,elem[0])}/></span>
                        <span className={classes.spancaption}>{elem[0]}</span></div>));

    return  (<div className={classes.switcher}>
        {switchers}
        </div>)


};