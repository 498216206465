import axios from   'axios';
import {ADM_USER_START,ADM_USER_SUCCESS,ADM_STATE_ERROR,ADM_USER_ERROR,ADM_STATE_ERROR_LOGIN} from "./actionTypes";
import {admSwitchSideBarBody, sendErrorToBlock} from "./adminrasdel";
import Sets from '../../components/adm/Sets/sets';
import {CONST_PREF} from '../../components/adm/Sets/sets';
const pref=CONST_PREF;

/*export function setTokenAxios(){
    return (getState)=> {
        const {token}={...getState().adminuser};
        const curToken=token || sessionStorage.getItem("apicolorscript");
        if(!axios.defaults.headers.common.authorization || axios.defaults.headers.common.authorization !== `${curToken}`)
            axios.defaults.headers.common.authorization = `${curToken}`;
    }
}*/
export function getAdmUserState() {
    return async (dispatch,getState)=> {
        try {
            dispatch(getAdmUserStart());
            const {token}={...getState().adminuser};
            const curToken=token || sessionStorage.getItem("apicolorscript");
            axios.defaults.headers.common.authorization = `${curToken}`;

                const res = await axios.get(pref+'/api/user');
            console.log(res)
            if (res.data.isLoad) {
                const menu = res.data.mainmenu;
                const header={};
                header.page = 'Административная часть';header.user=res.data.email;
                dispatch(getAdmUserSuccess({menu, auth: true, header,token:res.data.token}));
            } else {
                const header={};
                header.page = 'Введите логин и пароль';header.user='';
                dispatch(getAdmUserError(header));
            }

        }catch(e){
            dispatch(getStateError(e));
        }
    }
}

export function getAdmUserStart() {
    return{
        type: ADM_USER_START

    }
}
export function getAdmUserSuccess(obj) {
    return{
        type: ADM_USER_SUCCESS,
        obj
    }
}
export function getAdmUserError(header){
    return{
        type: ADM_USER_ERROR,
        header
    }
}

export function getStateError(e) {
    return{
        type: ADM_STATE_ERROR,
        error:e
    }
}

export function getAdmUserAuth(authData) {
    return async dispatch=> {
        dispatch(getAdmUserStart());
        dispatch( getStateErrorLogin(''));
          try{
                const responce=await axios.post(pref+'/api/login',authData);
                const header={user:responce.data.email,page:'Административная часть'};
                if(responce.data.isLoad) {
                    dispatch(getAdmUserSuccess({menu: responce.data.mainmenu, auth: true, header,token:authData.hash}));
                    sessionStorage.setItem("apicolorscript", authData.hash);
                }
                let errorLogin={status:0,msg:''};
                if(responce.data.error){ errorLogin={status:1,msg:responce.data.msg} }
              dispatch( getStateErrorLogin(errorLogin));
            }
            catch(e){dispatch(getStateError(e));}
    }
}
export function updateAdmUserError(authData) {
    return  dispatch=> {
        dispatch( getStateErrorLogin(authData));
    }
}
export function getStateErrorLogin(errorLogin) {
    return{
        type: ADM_STATE_ERROR_LOGIN,
        error:errorLogin
    }
}

export function logoutAdmUser() {
    return async dispatch=> {
        dispatch(getAdmUserStart());
        try{
            const res=await axios.get(pref+'/api/logout');
            if(!res.data.isLoad){
                const header={page:'Введите логин и пароль.',user:''};
                dispatch(getAdmUserSuccess({menu: [], auth: false, header,token:''}));
                sessionStorage.removeItem("apicolorscript");
            } else{
                dispatch(getStateError('WF?'));
            }

        }catch(e){dispatch(getStateError(e));}
    }
}

// work with user
export function admWorkUsers(type,action) {
    return  async(dispatch,getState)=> {
        const sidebody={...getState().adminrasdel.sidebody};
        const head={...getState().adminrasdel.sidehead};
        dispatch(sendErrorToBlock([]));
        switch(action){
            case 'saveNewPass':
                if(sidebody.parametres && sidebody.parametres.newpassword && sidebody.parametres.repeatpassword &&
                    sidebody.parametres.newpassword===sidebody.parametres.repeatpassword){
                    const data={id:sidebody.id,email:sidebody.email,password:sidebody.parametres.newpassword,type:'cpass'};
                     const res=await axios.post(pref+'/api/userchange',data);
                    if(res.data.isLoad){
                        if(res.data.error){dispatch(sendErrorToBlock(res.data.msg)); return false;}
                        sidebody.parametres={};
                        dispatch(admSwitchSideBarBody({head,body:sidebody}));
                        dispatch(sendErrorToBlock('Пароль изменен'));return false;
                    }
                }
                else   {dispatch(sendErrorToBlock('Новый пароль и повтор не совпадают')); return false;}

                break;
            case 'sendLinkForPass':
                const data={id:sidebody.id,email:sidebody.email,type:'semail', site:Sets.cursite};
                const res=await axios.post(pref+'/api/userchange',data);
                if(res.data.isLoad){
                    if(res.data.error){
                        dispatch(sendErrorToBlock(res.data.msg.response||res.data.msg)); return false;
                    }
                    dispatch(sendErrorToBlock(res.data.msg)); return false;
                }
                break;
            default:break;
        }
        console.log('arrEND')
    }
}

export const changePasswordLink= async (obj)=> {
        const data = {hash: obj.hash, email: obj.email, password: obj.password, type: 'linkpass'};
        const res = await axios.post(pref+'/api/userchangenoc', data);
    return res.data;
};