import React, {useRef, useState} from "react";
import classes from './Restore.module.css';
import {changePasswordLink} from "../../store/actions/adminuser";
import {useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";

function Restore(){
    const [state, setState] = useState({newPassword:'',repPassword:''});
    const [result, resultState] = useState('');
    let classRepeat=state.newPassword===state.repPassword?classes.noerror:classes.error;
    let classWarning=!result? state.newPassword===state.repPassword ?'':'Пароли не совпадают':result;
    const hashRef=useRef(null);
    let emailSend='';
    let history = useNavigate();

    const getLocation=()=>{
       const path=window.location;
       const params = new URLSearchParams(path.search);
        const email = params.get('email');
        const hash = params.get('link');
        if(email && hash) {emailSend=email; return {err:0,email,hash};}
        return {err:1,txt:'incorrect string'}
    };
    const handleSubmit=async (event)=>{
        event.preventDefault();
        if (classWarning) return ;
        const res=await changePasswordLink({password:state.newPassword, hash:hashRef.current.value,email:emailSend});
        if (res.error===1) {
            classWarning=res.msg;
            resultState(res.msg);
        } else{
            history("/adm");
        }
    };
    const handleEmailChange=e=>{
        const target=e.target;
        setState({...state,[target.id]:target.value});
        resultState('');

    };
    const {err,email,hash}=getLocation();

       return <div className={classes.main}>
           <Helmet>
               <title>Восстановление доступа</title>
               <meta name="description" content="Восстановление доступа" />
           </Helmet>
           {!err?
             <div className={classes.header}>Восстановление пароля для: {email}.
                 <div className={classes.warning}>{classWarning}</div>
               <form className={classes.form} onSubmit= {(e)=>handleSubmit(e)} >

                   < label className={classes.label} htmlFor="newPassword">Пароль:</label>
                   <input className={classes.password} id="newPassword" type="password"  value={state.newPassword} name="password" onChange={(e)=>handleEmailChange(e)}/>
                   < label className={classes.label} htmlFor="repPassword">Еще раз пароль:</label>
                   <input className={classRepeat} id="repPassword" type="password" value={state.repPassword} name="reppassword" onChange={(e)=>handleEmailChange(e)}/>
                   <input ref={hashRef} type="hidden" value={hash}/>
                   { state.newPassword?
                       <input className={classes.submit} type="submit" value="Save"/>
                       :null
                   }
               </form>
                 </div>
               : <div className={classes.header}>Произошла ошибка. Запросите пароль заново. </div>
           }
            </div>
}


    export default Restore;
