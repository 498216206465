import React, {Component} from "react";
import classes from './Sideelement.module.css';
import {connect} from "react-redux";
import {sideBarHeader} from "../Sets/utils";
import Buttonpict from "../../UI/Buttonpict/Buttonpict";
import {admCloseSideBar, changeElement, workFS, parserLink,checkerImageIcon,deleteFileToPut, changeIconTag} from "../../../store/actions/adminrasdel";
import {admWorkUsers} from "../../../store/actions/adminuser";
import SideElemForm from "../../UI/SideElemForm/SideelemForm";
import Urlselector from "../../UI/Urlselector/Urlselector";
import Fileselector from "../../UI/Fileselector/Fileselector";

class SideElement extends Component{
        state={panel:0, filestamp:null,areaValue:'',color:''};

 sideAction=(e,type,namefield)=>{  console.log('type',type,namefield,this.props.sidehead.table)
     const  sel  = e || false;
     switch(type){
         case 'change': this.props.changeElement(type,sel.target.value,namefield);break;
         case 'changeparam': this.props.changeElement(type,sel.target.value,namefield);break;
         case 'colorchange': this.props.changeElement('change',e,namefield);
            // console.log('type e',e);
         break;
         case 'addfields': this.props.changeElement(type,'',namefield);break;
         case 'getUrl': this.createPanel(type);break;
         case 'getFile': this.createPanel(type);break;
         case 'workFolder': this.props.workFS(e,type,namefield);break;
         case 'getInfoFile': this.props.changeElement(type,'',namefield);break;
         case 'saveInfo':this.props.changeElement(type,'',namefield); break;
         case 'changeColorIcon':this.props.changeElement(type,'',namefield); break;
         case 'changeareavalue':this.setState({areaValue:namefield}); break;
         case 'google':this.props.parserLink(type,this.state.areaValue);this.setState({areaValue:''}); break;
         case 'password': if(this.props.admBlocker.visual===1) this.props.WorkUsers(type,namefield); break;
         case 'getfileicon': this.props.checkerImageIcon(e,this.props.sidehead.table);break;
         case 'deleteicon': this.props.deleteFileToPut(namefield);break;
         case 'addicontag':
         case 'delicontag': this.props.changeIconTag(type,namefield);break;
        // case 'geticon': this.props.getIcon();break;
         default: console.log('type',type);return ;
     }

    //console.log(target)
    };


createPanel=(type)=>{ let tmp;
    switch(type){
        case 'getUrl': tmp=1; break;
        case 'getFile': tmp=2; break;
        default:tmp=0; break;
    };
    this.setState({panel:tmp,filestamp:Date.now()})
};
selectButtons=(type)=>{
    switch(type){
        case 'propelem': return ['info','mp3'];
       // case 'options': return ['options'];
        case 'password': return ['save','email'];
        case 'iconelem': return [];
        default:return [];
    }
};

    render() { console.log('this.props.sidehead',this.props.sidehead)
        const panel=this.state.panel===1?<Urlselector action={this.sideAction} arrbutton={['google','cancel']} areavalue={this.state.areaValue}/>
                :this.state.panel===2?<Fileselector action={this.sideAction} filestate={this.props.filestate}
                                                    filestamp={this.state.filestamp}/>:null;
        const buttons=this.selectButtons(this.props.sidetype);
        const header = sideBarHeader(this.props.sidehead.table, this.props.sidehead.namefield);
        return(
            <div className={classes.sidebar}>
                <div className={classes.header}>
                    <Buttonpict cls={'close'} onClick={this.props.closeSideBar} title={'Закрыть панель'}/>
                    { header
                        ? <div className={classes.headerdiv}>{header}: {this.props.sidehead.namerasdel} </div>
                        : null
                    }
                </div>
                <div className={classes.body}>
                    <SideElemForm type={this.props.sidetype} body={this.props.sidebody} action={this.sideAction}
                    buttons={buttons}/>
                </div>
                <div className={classes.body}>{panel} </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        sidehead:state.adminrasdel.sidehead,
        sidebody:state.adminrasdel.sidebody,
        sidetype:state.adminrasdel.sidetype,
        filestate:state.adminrasdel.filestate,
        admBlocker:state.adminrasdel.admBlocker,
    }
    }
function mapDispatchToProps(dispatch) {
    return {
        closeSideBar: ()=>dispatch(admCloseSideBar()),
        changeElement:(type,value,name)=>dispatch(changeElement(type,value,name)),
        workFS:(e,type,arr)=>dispatch(workFS(e,type,arr)),
        parserLink:(type,value)=>dispatch(parserLink(type,value)),
        WorkUsers:(type,value)=>dispatch(admWorkUsers(type,value)),
        checkerImageIcon:(event,table)=>dispatch(checkerImageIcon(event,table)),
        deleteFileToPut:(put)=>dispatch(deleteFileToPut(put)),
        changeIconTag:(type,param)=>dispatch(changeIconTag(type,param)),

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SideElement)