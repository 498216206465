import React from "react";
import classes from './Asideup.module.css';
import { useSelector} from "react-redux";
import SideSelect from "../Sideselect/Sideselect";
const Asideup=(props)=>{
    const view = useSelector((state) => state.frontreducer.mainlist);
    const arrClasses=view?[classes[props.cls], classes.mainlist]:[classes[props.cls]];
   // console.log('Asideup',data)
    return <div className={arrClasses.join(" ")}>
    <SideSelect  side={props.side} resultView={props.result}/>
    </div>;
};
export default Asideup;