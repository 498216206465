import React, {Component} from "react";
import classes from './RAsdelrow.module.css';
import {getPropertiesFortName, getFormParams,getHeadersForm, abbrevation} from '../../Sets/utils'
import Loader from '../../../../components/UI/Loader/Loader';
import {
    InputTbl,
    DivDef,
    DivCheckbox,
    Checkbox,
    SelectList,
    DivCheckboxDisable,
    DivCount,
    DivProperties,
    DivOptions,
    DivSelect, DivIconOne, DivColor, DivIcons
} from '../../../UI/Inputable/Inputable';
import ButtonsTd from "../../../UI/Buttonrow/Buttonrow";

class rasdelRowEdit extends Component{
//this.props.checkerHandler
    state={
        isFormValid:false, data:{}, isLoading: true, errors:[]
    };

    componentDidMount() {
        const fields=this.props.fields;
        const check=String(fields.id).indexOf('noid') < 0;
        const heads=this.props.heads;
        const newState={id:fields.id};
        heads.map((obj,index)=>{
            newState[obj]=getFormParams(obj);
            newState[obj].value=fields[obj];
            if(newState[obj].valid!==undefined && check){
                newState[obj].valid=true;
                newState[obj].touched=true;
            }
            return true;
        });
        //console.log('didmount',newState);
         this.setState({data:newState,isLoading:false,isFormValid:check});
    }

    makeElements=()=>{
        const heads=this.props.heads;
        return  heads.map((obj,index)=>{
            return this.designElementTD(obj,this.state.data[obj].value,index)
        });
    };
    designElementTD=(head,field,key)=>{
        let blocker=this.props.admBlocker.visual;
        const type=getPropertiesFortName(head,blocker);
        const note=this.madeDesign(type,field,head);
        const cls=classes[head];
        const result=type.type!=='none'?(<td className={cls} name={head} key={key} >{note}</td>):null;
        return result;
    };
    madeDesign=(type,field,head)=>{
      //  console.log('type',type)
        const sp=this.props.statuspicture;
       // let blocker=this.props.admBlocker.visual;
        let out=''; let clsActive='', options; let cls;
        switch(type.type){
            case 'textlock':
                if(type.redact){out=(<InputTbl handler={event=>this.onChangeHandler(event,head)} data={this.state.data[head]}/>);}
                    else{out=(<DivDef field={field}/>);}
                 break;
            case 'textcolor': out=(<DivColor field={field}/>); break;
            case 'text':
            case 'urlarticle':
            case 'url':
                if(type.redact){out=(<InputTbl handler={event=>this.onChangeHandler(event,head)} data={this.state.data[head]}/>);}
                    else{out=(<DivDef field={field}/>);} break;
            case 'checkboxmain':
                out=(<DivCheckboxDisable field={field}/>);break;
            case 'checkbox':
                     if(type.redact){
                        out=(<Checkbox field={field} handler={event=>this.onChangeHandler(event,head)}/>);}
                        else{ out=(<DivCheckbox field={field}/>);}
                 break;
            case 'select':
                if(type.redact){
                    out = (<SelectList list={type.list} handler={event => this.onChangeHandler(event, head)} field={field}/>);
                }else{out=<DivSelect field={field} value={type.list[field]}/>;}
                break;
            case 'button':
                const butAction=(<ButtonsTd buttons={this.props.button} handler={this.props.checkerHandler}
                                            tbl={this.props.tbl} objdata={this.getOnlyValue()}
                                            errors={this.getErrors} />);
                out=butAction; break;
            case 'countelem':
                 cls=type.type;
                if(sp.state && parseInt(sp.selectpicture.id)===this.props.fields.id && sp.selectpicture.table===this.props.tbl
                    && sp.selectype==='countelem') clsActive='active';
                out=<DivCount field={field} cls={cls} clsActive={clsActive} handler={(e)=>this.checkHandler(e,this.props,'countelem')}/>;break;
            case 'commelem':
                 cls=type.type;
                if(sp.state && parseInt(sp.selectpicture.id)===this.props.fields.id && sp.selectpicture.table===this.props.tbl
                    && sp.selectype==='commelem') clsActive='active';
                const commField=field.length>1 && field[1]>0? field[1]+' / '+field[0]:field[0];
                out=<DivCount field={commField} cls={cls} clsActive={clsActive} handler={(e)=>this.checkHandler(e,this.props,'commelem')}/>;break;

            case 'password':
            case 'options':
            { let cls=type.type;  clsActive='';
                options=abbrevation(this.props.tbl,this.props.fields);
                if(sp.state && parseInt(sp.selectpicture.id)===this.props.fields.id && sp.selectpicture.table===this.props.tbl
                    && (sp.selectype==='options' || sp.selectype==='password')) clsActive='active';
                out=<DivOptions field={options} cls={cls} clsActive={clsActive} handler={(e)=>this.checkHandler(e,this.props,type.type)}/>;}break;
            case 'urlelem':
                  clsActive='';
                if(sp.state && parseInt(sp.selectpicture.id)===this.props.fields.id && sp.selectpicture.table===this.props.tbl) clsActive='active';
                out=(<DivProperties field={field} cls={type.type} clsActive={clsActive} handler={(e)=>this.checkHandler(e,this.props,'propelem')}/>);break;
            case 'iconelem':
                  clsActive='';
                if(sp.state && parseInt(sp.selectpicture.id)===this.props.fields.id && sp.selectpicture.table===this.props.tbl) clsActive='active';
                out=(<DivIcons field={field} cls={type.type} clsActive={clsActive} handler={(e)=>this.checkHandler(e,this.props,'iconelem')}/>);break;
            case 'iconvisual':
                 clsActive='';
                if(sp.state && parseInt(sp.selectpicture.id)===this.props.fields.id && sp.selectpicture.table===this.props.tbl &&
                    sp.selectpicture.namefield==='icon') clsActive='active';
                out=(<DivIconOne field={field} cls={type.type} clsActive={clsActive} handler={(e)=>this.checkHandler(e,this.props,'iconvisual')}/>);break;
            case 'none':out=null;break;
            default:out=(<DivDef field={`${field}-ERR`}/>); break;
        }
        return out;
    };

    checkHandler=(e,props,status)=>{
        const { target } = e;
        const id=target.closest('tr').getAttribute('id');
        let name='';
        if(target.closest('td')){name=target.closest('td').getAttribute('name')};
        //console.log({table:this.props.tbl,id:id,name:name});
        this.props.checkerHandler({table:props.tbl,id:id,name:name,status});
    }
    getOnlyValue=()=>{
        const data={};
        Object.keys(this.state.data).forEach(name=>{
            data[name]=this.state.data[name].value;
        });
        data.isValid=this.state.isFormValid;
        return data;
    };
    getErrors=()=>{
        const errors=[];
        const formControls={...this.state.data};
        Object.keys(formControls).forEach((name,index)=>{
            const obj=formControls[name];
            const idx=`${name}-${index}`;
            const objvalid=this.validateControl(obj.value, obj.validation);
            if(obj.valid!==undefined && !objvalid.isValid){
                errors.push(<div key={idx}> {getHeadersForm(name)}: {obj.errorMessage} </div>)
            }
        });
        //console.log(errors);
        return errors;
    };

    onChangeHandler=(event,controlName)=>{
        const errors=[];
        const formControls={...this.state.data};
        const control={...formControls[controlName]};
        if(control.type==='checkbox'){control.value=!control.value;}
        else{ control.value=event.target.value;}
            control.touched=true;
            const objvalid=this.validateControl(control.value, control.validation);
            control.valid=objvalid.isValid;
            control.value=objvalid.value;
        formControls[controlName]=control;
        let isFormValid=true;
        Object.keys(formControls).forEach(name=>{
            if(formControls[name].valid!==undefined) {
                isFormValid = formControls[name].valid && isFormValid
            }
        });
        this.setState((prevState, props)=>{
            return {data: formControls,isFormValid,errors}
        });
    };

    validateControl(value, validation){
        let isValid=true;
        if (!validation) return {isValid,value};
        if(validation.required){isValid=value.trim()!=='' && isValid}
        if(validation.texturl){value=value.toLowerCase(); value=value.replace(/\s|_|\+|=/g, '-');
            isValid=!(/[а-яё]/i.test(value)) && isValid;
        }
        if(validation.paramurl){value=value.toLowerCase(); value=value.replace(/\s|-|\+|=/g, '_');
            isValid=!(/[а-яё]/i.test(value)) && isValid;
        }
        if(validation.minLength){isValid=value.length>=validation.minLength  && isValid} //[а-яА-ЯёЁa-zA-Z0-9-\s_\.,!—]+
        if(validation.email){value=value.toLowerCase();
            isValid=( /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(value)) && isValid;
        }
        return {isValid,value}
        }



    render(){
            const td=this.state.isLoading ? null : this.makeElements();
        //console.log('td',td)
        return(
            this.state.isLoading
                ? <td><Loader/></td>
            :  td
        )
    }

}

export default rasdelRowEdit;




